import { Amount, Identifiers, PhoneNumber, StreetAddress } from "../index";

import { CodeDescription, DateModel } from "../..";
import { ValueOf } from "type-fest";

interface ShareCapital {
  date: DateModel;
  amount: Amount;
}

export interface Capital {
  shareCapital: ShareCapital;
}

interface Name {
  date: DateModel;
  name: string;
}

interface DescriptiveName extends Name {
  description: string;
}

export interface CompanyName {
  secondaryNames: DescriptiveName[];
  alternativeNames: DescriptiveName[];
  historicalNames: Name[];
  registeredName: Name;
  popularName: Name;
}

interface ElectronicContactPoints {
  emailAddresses: string[];
  faxNumbers: PhoneNumber[];
  phoneNumbers: PhoneNumber[];
  webAddresses: string[];
}

export interface PostalOfficeBox {
  countryCode: string;
  postalOffice: string;
  boxNumber: string;
  town: string;
  postalCode: string;
}

export interface Address {
  streetAddress: StreetAddress;
  postalOfficeBox: PostalOfficeBox;
}

interface HistoricalPostalOfficeBox {
  countryCode: string;
  postalOffice: string;
  boxNumber: string;
  town: string;
  postalCode: string;
  validToDate: DateModel;
  validFromDate: DateModel;
}

interface HistoricalStreetAddress extends HistoricalPostalOfficeBox {
  street: string;
}

interface HistoricalAddress {
  streetAddresses: HistoricalStreetAddress[];
  postalOfficeBoxes: HistoricalPostalOfficeBox[];
}

export interface ContactPoints {
  electronicContactPoints: ElectronicContactPoints;
  postalAddress: Address;
  registeredAddress: Address;
  visitingAddress: Address;
  historicalPostalAddress: HistoricalAddress;
  historicalRegisteredAddress: HistoricalAddress;
  historicalVisitingAddress: HistoricalAddress;
}

interface Sni2007IndustryCodes {
  otherIndustryCodes: CodeDescription[];
  primaryIndustryCode: CodeDescription;
}

interface NaceCodes {
  primaryNaceCode: CodeDescription;
  otherNaceCodes: CodeDescription[];
}

export interface IndustryCodeSet {
  sni2007IndustryCodes: Sni2007IndustryCodes;
  naceCodes: NaceCodes;
  sicCodes: CodeDescription[];
}

export const CompanyCode = {
  LIMITED_COMPANY: "LIMITED_COMPANY",
  SOLE_PROPRIETORSHIP: "SOLE_PROPRIETORSHIP",
  PARTNERSHIP: "PARTNERSHIP",
  TRADING_LIMITED_PARTNERSHIP: "TRADING_LIMITED_PARTNERSHIP",
  NORWAY_DIVISION_FOREIGN_ENTERPRISE: "NORWAY_DIVISION_FOREIGN_ENTERPRISE",
  TRADING_PARTNERSHIP: "TRADING_PARTNERSHIP",
  LIMITED_PARTNERSHIP: "LIMITED_PARTNERSHIP",
  OTHER: "OTHER"
} as const;
export type CompanyCodeType = ValueOf<typeof CompanyCode>;

export const PublicSectorEntityCodes = {
  SA: "SA", // Government
  SF: "SF", // State-owned enterprise
  OL: "OL", // OrganizationalChain
  FY: "FY", // Country Council
  FK: "FK", // Country Municipal Enterprise
  KO: "KO", // Municipality
  KF: "KF", // Municipal enterprise
  IK: "IK", // Inter-municipal company
  KI: "KI", // Parish/Joint Council of Churches
  SR: "SR" // Other enterprises in accordance with special law
};
export const SwedishPublicSectorEntityCodes = {
  81: "81", // Statliga enheter
  82: "82", // Primärkommuner
  83: "83", // Kommunalförbund
  84: "84", // Landsting
  87: "87", // Offentliga korporationer och anstalter
  89: "89" // Statliga regionala myndigheter
};

export const SwedishLocalCode = {
  1: "1", // PROPRIETORSHIP
  2: "2", // LIMITED PARTNERSHIP
  3: "3", // LIMITED COMPANY
  4: "4", // CO-OPERATIVE SOCIETY
  6: "6", // ASSOCIATION / FOUNDATION
  9: "9", // ENTREPRENEUR
  20: "20", // HOUSING CO-OPERATIVE SOCIETY
  21: "21", // HOUSING COMPANY
  22: "22", // GENERAL PARTNERSHIP
  23: "23", // VOLUNTARY ASSOCIATION
  24: "24", // MORTGAGE ASSOCIATION
  25: "25", // MUTUAL INDEMNITY INSURANCE AS.
  26: "26", // LIMITED PARTNERSHIP, Note: Duplicate
  27: "27", // CO-OPERATIVE SOCIETY, Note: Duplicate
  28: "28", // CO-OPERATIVE BANK
  29: "29", // LIMITED COMPANY, Note: Duplicate
  30: "30", // FOUNDATION
  31: "31", // OFFICE OF FOREIGN COMPANY
  32: "32", // SAVINGS BANK
  33: "33", // ECONOMIC ASSOCIATION
  34: "34", // INSURANCE LIMITED COMPANY
  35: "35", // INSURANCE ASSOCIATION
  36: "36", // PROPRIETORSHIP, Note: Duplicate
  37: "37", // GOVERNMENT AUTHORITY
  38: "38", // OTHER LEGAL FORM
  40: "40", // GROUP
  41: "41", // RIGHT OF RESIDENCE ASSOCIATION
  42: "42", // PUBLIC LIMITED COMPANY
  43: "43", // PUBLIC MUTUAL INSURANCE CO.
  44: "44", // PUBLIC INSURANCE LTD
  45: "45", // EEIG
  46: "46", // REAL ESTATE LTD
  47: "47", // STATE ENTERPRISE
  48: "48", // SOCIETAS EUROPAEA
  49: "49" // MUTUAL EUROPE INSURANCE COMP.
};

export type SwedishLegalFormLocalCode = ValueOf<typeof SwedishLocalCode>;

export interface LegalForm {
  code: CompanyCodeType;
  description: string;
  localCode: SwedishLegalFormLocalCode;
}

export interface Status {
  value: "ACTIVE" | "INACTIVE" | "NOTSTARTED_OR_DORMANT";
  date: DateModel;
}

export interface RegistrationInformation {
  articlesOfAssociationDate: DateModel;
  foundationDate: DateModel;
}

export interface BankAddress {
  postalCode: string;
  street: string;
  town: string;
}

export interface Branch {
  name: string;
  headQuarter: boolean;
  address: BankAddress;
  officeNumber: string;
  identifiers: Identifiers;
}

export interface CompanyInformation {
  businessDescription: string;
  countryCode: string;
}
