import { SecondaryButton } from "@stacc/flow-ui-components";
import { useFlowContext } from "../../../../../../hooks/useFlowContext";
import { EntityMatch } from "../../../../../../types/creditProcess/opensanctions/response";
import { Header } from "../../../../../Headers";
import { NoRemarks } from "../../../../../NoRemarks";
import {
  Table,
  TableBody,
  TableCell,
  TableCellTitle,
  TableRow
} from "../../../../../table";
import { isStringArray } from "./SanctionsCompany";
import { SanctionsDisplayTopics } from "./SanctionsDisplayTopics";

export function SanctionsApplicant({
  data
}: {
  data: EntityMatch | undefined;
}) {
  const { t } = useFlowContext();
  if (!data) {
    return <NoRemarks label={t("no-applicant-sanctions")} />;
  }
  const type = data?.type ?? "";
  const { match, entity } = data ?? {};
  const { topics } = entity?.properties ?? {};
  const { id } = match ?? {};
  const name = match?.caption ?? "";

  const applicantType = type.split("-")[0] ?? "";

  return (
    <div className="flex flex-col gap-5">
      <div className="border-b text-xl">
        <Header>{t(applicantType)}</Header>
      </div>
      {topics && isStringArray(topics) && (
        <SanctionsDisplayTopics topics={topics} />
      )}
      <Table>
        <TableBody>
          <TableRow className="border-0 flex justify-between ">
            <TableCellTitle>{t("name")}</TableCellTitle>
            <TableCell>{name ?? "N/A"}</TableCell>
          </TableRow>
          <TableRow className="border-0 flex justify-between ">
            <TableCellTitle>{t("birthDate")}</TableCellTitle>
            <TableCell>{match?.properties?.birthDate?.[0] ?? "N/A"}</TableCell>
          </TableRow>
          <TableRow className="border-0 flex justify-between ">
            <TableCellTitle>{t("keywords")}</TableCellTitle>
            <TableCell>{match?.properties?.keywords?.[0] ?? "N/A"}</TableCell>
          </TableRow>
          <TableRow className="border-0 flex justify-between ">
            <TableCellTitle>{t("firstSeen")}</TableCellTitle>
            <TableCell>
              {(match?.first_seen as string).split("T")?.[0] ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow className="border-0 flex justify-between ">
            <TableCellTitle>{t("lastChanged")}</TableCellTitle>
            <TableCell>
              {(match?.last_change as string).split("T")?.[0] ?? "N/A"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {id && (
        <div className="w-32">
          <a
            href={`https://www.opensanctions.org/entities/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="More info"
          >
            <SecondaryButton className="w-full">
              {t("more-info")}
            </SecondaryButton>
          </a>
        </div>
      )}
    </div>
  );
}
