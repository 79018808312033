export type IconProps = {
  width?: number;
  height?: number;
  className?: string;
};

export function StaccLogo({ className, width = 48, height = 48 }: IconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7342 4L40.802 14.0166V33.9834L23.7342 44L6.66663 33.9834V14.0166L23.7342 4ZM9.4829 32.388V26.4504L22.3259 33.9839V39.9253L9.4829 32.388ZM22.3259 30.7359L9.4829 23.2025V17.2647L22.3259 24.7982V30.7359ZM25.1422 39.9255V24.7988L30.1583 21.8565V36.9817L25.1422 39.9255ZM32.9745 35.3289L37.9857 32.388V17.265L32.9745 20.2045V35.3289ZM28.7993 10.2208L23.7342 7.24826L10.8423 14.8143L15.9053 17.7842L28.7993 10.2208ZM31.567 11.8451L18.6739 19.4081L23.7346 22.3766L36.6266 14.8144L31.567 11.8451Z"
        fill="#BD5E55"
      />
    </svg>
  );
}

export function XmlIcon({ className, width = 24, height = 24 }: IconProps) {
  return (
    <svg className={className} width={width} height={height}>
      <path
        d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2
                                        0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"
      />
    </svg>
  );
}
