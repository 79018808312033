import { Reason } from "../../../../types/creditProcess/state";
import { classNames } from "../../../../utils/classNames";

export function DisplayRejectionReasons({
  color,
  reasons
}: {
  color: string;
  reasons: Reason[] | undefined;
}) {
  if (!reasons || reasons.length === 0) {
    return null;
  }
  return (
    <div className="flex gap-2">
      {reasons.map((reason, index) => {
        return (
          <span
            key={`${reason}-${index}`}
            className={classNames(
              `flex flex-col rounded-lg border-2 px-4 py-1`,
              color === "yellow"
                ? "border-yellow-500 bg-yellow-100"
                : "border-red-500 bg-red-100"
            )}
          >
            <span
              className={classNames(
                color === "yellow" ? "text-yellow-700" : "text-red-700"
              )}
            >
              {reason?.reason}
            </span>
            <span
              className={classNames(
                "text-xs",
                color === "yellow" ? "text-yellow-500" : "text-red-500"
              )}
            >
              {reason?.reasonCode}
            </span>
          </span>
        );
      })}
    </div>
  );
}
