import { Country, COUNTRY, COUNTRY_CODE } from "../types/creditProcess/enums";
import { CreditProcessState } from "../types/creditProcess/state";
import { B2BResponseModel } from "../types/d&b/b2b";

export function getChartDataHelper(
  data: CreditProcessState,
  country: Country | undefined
) {
  switch (country) {
    case COUNTRY.SWEDEN:
      return getChartDataSweden(data);
    case COUNTRY.NORWAY:
      return getChartDataNorway(data);
    case COUNTRY.DENMARK:
      return getChartDataDenmark(data);
    case COUNTRY.FINLAND:
      return getChartDataFinland(data);
    default:
      return undefined;
  }
}

function getChartDataSweden(data: CreditProcessState) {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.SWEDEN
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};
  return (
    financialStatements?.map((itm) => {
      const { incomeStatement } = itm ?? {};

      return {
        year: itm?.closingPeriod?.year ?? "",
        operatingProfitAfterDepreciation:
          incomeStatement.operatingProfitAfterDepreciation ?? "",
        resultBeforeTax: incomeStatement?.profitLossBeforeTax ?? ""
      };
    }) ?? {}
  );
}

function getChartDataNorway(data: CreditProcessState) {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.NORWAY
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};

  return (
    financialStatements?.map((itm) => {
      const { incomeStatement } = itm ?? {};
      return {
        year: itm?.closingPeriod?.year ?? "",
        resultBeforeAllocations: incomeStatement?.resultBeforeAllocations,
        resultBeforeTax: parseInt(incomeStatement?.profitLossBeforeTax)
      };
    }) ?? {}
  );
}

function getChartDataDenmark(data: CreditProcessState) {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.DENMARK
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};

  return (
    financialStatements?.map((itm) => {
      const { incomeStatement } = itm ?? {};

      return {
        year: itm?.closingPeriod?.year ?? "",
        operatingProfitAfterDepreciation:
          incomeStatement.operatingProfitAfterDepreciation ?? "",
        resultBeforeTax: incomeStatement?.profitLossBeforeTax ?? ""
      };
    }) ?? {}
  );
}

function getChartDataFinland(data: CreditProcessState) {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.FINLAND
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};

  return (
    financialStatements?.map((itm) => {
      const { incomeStatement } = itm ?? {};

      return {
        year: itm?.closingPeriod?.year ?? "",
        operatingResult: incomeStatement.operatingResult ?? "",
        resultBeforeTax: incomeStatement?.operatingResult ?? ""
      };
    }) ?? {}
  );
}
