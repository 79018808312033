import { OctagonAlert } from "lucide-react";
import { useState } from "react";
import { useFlowContext } from "../../../../hooks/useFlowContext";
import { TaskProps } from "../../../../types/taskProps";
import { getPaymentRemarksHelper } from "../../../../utils/paymentRemarksHistory";
import { COLOR, Card } from "../../../Card";
import { NoRemarks } from "../../../NoRemarks";
import {
  Table,
  TableBody,
  TableCell,
  TableCellComment,
  TableCellTitle,
  TableHead,
  TableHeader,
  TableRow
} from "../../../table";

export function PaymentRemarks(flow: TaskProps["flow"]) {
  const { t } = useFlowContext();
  const { data } = flow;
  const { country } = data?.applicant;
  const [visibleCount, setVisibleCount] = useState(10);

  if (!country) {
    return (
      <NoRemarks
        label={t("could-not-fetch-data-without-country")}
        isSuccess={false}
      />
    );
  }

  const paymentRemarks = getPaymentRemarksHelper(data, country);

  const hasPaymentRemarks = paymentRemarks && paymentRemarks?.length > 0;

  if (!hasPaymentRemarks) {
    return <NoRemarks label={t("noPaymentRemarks")} isSuccess={false} />;
  }

  const toggleVisibleCount = () => {
    setVisibleCount(visibleCount === 10 ? paymentRemarks.length : 10);
  };

  return (
    <div>
      {paymentRemarks && (
        <Card>
          <Card.Header color={COLOR.RED}>
            <Card.HeaderTitle>
              <OctagonAlert />
              {t("paymentRemarks")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content horizontalPadding={false} className="px-5">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>{t("date")}</TableHead>
                  <TableHead className="px-2">{t("amount")}</TableHead>
                  <TableHead className="pl-2">{t("description")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {paymentRemarks.slice(0, visibleCount).map((event, count) => (
                  <TableRow
                    key={`${event?.formattedDate}-${count}`}
                    className="border-0"
                  >
                    <TableCellTitle>
                      {event?.formattedDate ?? t("N/A")}
                    </TableCellTitle>
                    <TableCell className="px-2">
                      {event?.amount ?? t("N/A")}
                    </TableCell>
                    <TableCellComment>
                      {event?.description ?? t("N/A")}
                    </TableCellComment>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {paymentRemarks.length > 10 && (
              <div className="mt-4">
                <button
                  className="cursor-pointer text-sm text-blue-500 underline"
                  onClick={toggleVisibleCount}
                >
                  {visibleCount > 10 ? "Show Less" : "Show More"}
                </button>
              </div>
            )}
          </Card.Content>
        </Card>
      )}
    </div>
  );
}
