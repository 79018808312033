import { Email as FlowEmail } from "@stacc/flow-ui-components";
import { useFormConnect } from "../../hooks/useFormConnect";

export function Email({ id, label }: { id: string; label: string }) {
  const { ref, error, ...props } = useFormConnect(id);
  return (
    <div>
      <FlowEmail label={label} {...props} />
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}
