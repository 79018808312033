import { Colors, Icons } from "@stacc/flow-ui-components";
import { useState } from "react";
import { standarizeString } from "../../utils/helpers";
import { TestRunData } from "../../utils/modifications";
import { CircleIconWithTooltip } from "../CircleIcon";
import { DecisionTestingCard } from "./DecisionTestingCard";

export function DecisionTestingDefaultCollapsible({
  title,
  data,
  flowId
}: {
  title: string;
  data: TestRunData;
  flowId: string;
}) {
  const { dryRun, existing, live, totals } = data?.totalOutcome ?? {};
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b-2">
      <button
        className="w-full h-10 flex flex-row items-center justify-between "
        onClick={toggleCollapse}
      >
        <div className="flex flex-row items-center gap-2 ">
          {title}
          <CircleIconWithTooltip
            count={totals?.numberOfApplications}
            label="Total number of applications used for this test run."
          />
        </div>
        {isOpen ? (
          <Icons.ChevronUp color={Colors.Sea} />
        ) : (
          <Icons.ChevronDown color={Colors.Sea} />
        )}
      </button>
      {isOpen ? (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-5">
            <div className="text-sea-sea">Flow Id:</div>
            {flowId}
          </div>
          <div className="flex flex-row gap-5">
            <div className="text-sea-sea">Country:</div>
            {data?.query?.country ? standarizeString(data?.query?.country) : "N/A"}
          </div>
          <div>
            {(totals?.numberOfApplications ?? 0) > 0 && (
              <div className="grid lg:grid-cols-3 md:grid-cols-2 shrink gap-5">
                <DecisionTestingCard
                  title={"Existing"}
                  data={existing}
                  isExisting={true}
                />
                <DecisionTestingCard title={"Live"} data={live} />
                <DecisionTestingCard title={"Dry Run"} data={dryRun} />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
