import { TextArea } from "@stacc/flow-ui-components";
import { useFormConnect } from "../../hooks/useFormConnect";

export function Note({ id }: { id: string }) {
  const { ref, error, value, ...props } = useFormConnect(id);
  return (
    <div>
      {value ? (
        <TextArea
          className="max-w-xs"
          {...props}
          defaultValue={value}
          size="extraLarge"
        />
      ) : (
        <TextArea
          className="max-w-xs"
          {...props}
          placeholder="enter note..."
          size="extraLarge"
        />
      )}

      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}
