import { withLDProvider } from "launchdarkly-react-client-sdk";
import { getSessionStorage } from "./sessionStorage";

export function getLaunchDarklyConfig() {
  const profile = getSessionStorage()?.profile || {};
  return withLDProvider({
    // @ts-ignore
    clientSideID: window.stacc_env.featureToggles[0]!,
    user: {
      key: profile.username,
      name: profile.name,
      email: profile.username,
      custom: {
        service: "flow-portal",
        roles: profile.roles
      }
    }
  });
}
