export function Filter({
  height = 32,
  width = 32
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      height={height}
      width={width}
    >
      <path
        fill="#FFFFFF"
        d="M28.825 6.19125C28.6711 5.83564 28.416 5.53312 28.0915 5.32132C27.767 5.10952 27.3875 4.99778 27 5H4.99997C4.61288 5.00076 4.23433 5.11384 3.91025 5.32551C3.58616 5.53718 3.33047 5.83835 3.17418 6.19248C3.01789 6.54661 2.96772 6.93849 3.02977 7.32057C3.09181 7.70265 3.2634 8.05852 3.52372 8.345L3.53372 8.35625L12 17.3963V27C11.9999 27.362 12.098 27.7172 12.284 28.0278C12.4699 28.3383 12.7366 28.5926 13.0557 28.7635C13.3748 28.9344 13.7343 29.0155 14.0958 28.9982C14.4574 28.9808 14.8075 28.8657 15.1087 28.665L19.1087 25.9975C19.3829 25.8149 19.6078 25.5673 19.7632 25.2768C19.9187 24.9864 20 24.662 20 24.3325V17.3963L28.4675 8.35625L28.4775 8.345C28.7405 8.05983 28.9138 7.70349 28.9756 7.32046C29.0374 6.93744 28.985 6.54469 28.825 6.19125ZM18.5425 16.035C18.1951 16.4032 18.0011 16.89 18 17.3963V24.3325L14 27V17.3963C14.0011 16.8882 13.8079 16.3989 13.46 16.0288L4.99997 7H27L18.5425 16.035Z"
      />
    </svg>
  );
}
