import get from "lodash.get";
import { PROCESSES } from "../types/processes";
import { TaskProps } from "../types/taskProps";

export function getStatusCreditPayment(data: any) {
  if (data?.errors?.length > 0) {
    return "Error";
  }
  if (data?.canceled) {
    return "Canceled";
  }
  return (
    get(data, "init-santanderse.status") ||
    get(data, "init-resursfi.status", "InProgress")
  );
}

export function getStatus(flow: TaskProps["flow"]) {
  const { data, _meta } = flow;
  const processDefinitionId = _meta?.processDefinitionId;
  switch (processDefinitionId) {
    case PROCESSES.CREDIT_PAYMENT:
      return getStatusCreditPayment(data);
    case PROCESSES.CREDIT_PROCESS:
      return data?.businessStatus;
    default:
      return flow?.status?.charAt(0).toUpperCase() + flow?.status?.slice(1);
  }
}
