import { ReactNode } from "react";
import { StaccLogo } from "./StaccLogo";
import { classNames } from "../../utils/classNames";
import { DmnView } from "./DMNViewer";

export interface ToggleOption {
  id: string;
  node: ReactNode;
}

interface ToggleGroupProps {
  options: ToggleOption[];
  onChange: (id: string) => void;
  active?: DmnView;
  className?: string;
}

export function ToggleGroup({
  active,
  onChange,
  options,
  className
}: ToggleGroupProps) {
  return (
    <div
      className={classNames(
        "h-fit absolute left-5 bg-prism-surface-secondary bottom-5 p-1 rounded-prism-radius-100 flex gap-4",
        className
      )}
    >
      <StaccLogo className="my-auto ml-2" width={24} height={24} />
      {options.map((option) => (
        <button
          key={option.id}
          type="button"
          className={classNames(
            active?.id === option.id &&
              "bg-prism-surface fill-current text-prism-content shadow-sm",
            "hover:cursor-pointer flex gap-2 font-bold text-sm my-auto rounded-prism-radius-100 px-2 py-1 transition-all duration-200 hover:shadow-sm hover:bg-prism-surface-default"
          )}
          onClick={() => onChange(option.id)}
        >
          {option.node}
        </button>
      ))}
    </div>
  );
}
