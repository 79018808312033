import { FlowContext } from "../../../hooks/useFlowContext";
import { TaskProps } from "../../../types/taskProps";
import { CreditProcessCaseSummary } from "../../CaseSummary/CreditProcessCaseSummary";
import { DecisionPanel } from "../components/DecisionPanel";
import { Layout } from "../components/Layout";
import { CreditDecision } from "./CreditDecision";

export function ManualHandling(props: TaskProps) {
  const { flow } = props;
  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <CreditProcessCaseSummary flow={flow} className="px-5" />
        <div className="text-sm">
          <DecisionPanel>
            <CreditDecision props={props} />
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
