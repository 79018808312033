import { CaseSummary } from "../components/CaseSummary";
import { QueueItem } from "../components/QueueItem";
import { EvaluatePOADocument } from "../components/tasks/EvaluatePOADocument";
import { ManualHandling } from "../components/tasks/ManualHandling";
import { SanctionsHandling } from "../components/tasks/SanctionsHandling";
import { getStatus } from "../functions/getStatus";
import { FlowContext } from "../hooks/useFlowContext";
import { CustomComponents } from "../types/case-manager/custom-components";
import { TypeRole } from "../types/role";
import { TaskProps } from "../types/taskProps";
import { menuStructure, queueDefinition } from "./queues";
import { tabs } from "./tabs";

export function customComponents(
  role: TypeRole
): CustomComponents<TaskProps["flow"], TaskProps> {
  return {
    case: {
      caseSummary: {
        component: (props) => {
          return (
            <FlowContext.Provider value={props}>
              <CaseSummary {...props} />
            </FlowContext.Provider>
          );
        },
        caseHistoryTasksLimit: 10000
      },
      businessStatus: {
        map: (flow) => getStatus(flow)
      },
      tabs,
      tasks: {
        taskComponents: {
          "manual-handling": {
            component: ManualHandling,
            titleMap: () => "Manual Handling",
            size: "large"
          },
          "four-eyes-verification": {
            component: ManualHandling,
            titleMap: () => "Manual Handling Elevated",
            size: "large"
          },
          "compliance-check": {
            component: SanctionsHandling,
            titleMap: () => "Sanctions",
            size: "large"
          },
          "evaluate-power-of-attorney-document": {
            component: EvaluatePOADocument,
            titleMap: () => "Evaluate power of attorney document",
            size: "large"
          }
        }
      }
    },
    queue: {
      queueItem: QueueItem
    },
    menu: {
      menuStructure: menuStructure(role)
    },
    queueDefinition: queueDefinition(role)
  };
}
