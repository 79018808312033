import { useState } from "react";
import { classNames } from "../utils/classNames";
import { Skeleton } from "./DecisionTesting/Skeleton";

interface CircleIconProps {
  number: number | undefined;
  include0?: boolean;
}

export function CircleIcon({ number, include0 }: CircleIconProps) {
  if ((number === 0 && !include0) || !number) {
    return null;
  }
  return (
    <div className="relative inline-block">
      <div className=" min-w-5 w-auto h-5 px-1 bg-sea-light rounded-full flex items-center justify-center text-blue-600 font-normal cursor-pointer">
        {number}
      </div>
    </div>
  );
}

export function CircleIconWithTooltip({
  count,
  label,
  include0,
  bottom
}: {
  count: number | undefined;
  label: string;
  include0?: boolean;
  bottom?: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);
  if (count === 0 && !include0) {
    return null;
  }

  if (!count) {
    return (
      <div className="">
        <Skeleton className="w-5 h-5" />
      </div>
    );
  }

  return (
    <div
      className="relative transition transform hover:scale-125 "
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div>
        <CircleIcon number={count} include0={true} />
      </div>
      {isHovered && (
        <div
          className={classNames(
            "bg-white rounded-lg text-sm font-normal shadow absolute left-1/2 transform -translate-x-1/2 p-4 z-50",
            bottom ? "top-full" : "bottom-full"
          )}
        >
          <span className="text-blue-600 flex items-center justify-center">
            {label}
          </span>
        </div>
      )}
    </div>
  );
}
