import { Cell, Label, Pie, PieChart } from "recharts";

const creditRatingCodes: { [key: string]: number } = {
  AAA: 1.0,
  AA: 0.875,
  A: 0.75,
  BBB: 0.625,
  BB: 0.5,
  B: 0.375,
  CCC: 0.25,
  CC: 0.125,
  C: 0.0
};

type HalfMoonChartProps = {
  label?: string;
  color?: string;
  credit: string;
};

export function HalfMoonChart({ label, credit }: HalfMoonChartProps) {
  let creditScoreValue;
  const ratingCode = creditRatingCodes[credit];
  const toNumber = Number(credit);

  if (!isNaN(ratingCode)) {
    creditScoreValue = ratingCode;
  } else if (!isNaN(toNumber)) {
    creditScoreValue = toNumber / 100;
  }

  if (!creditScoreValue && creditScoreValue !== 0) {
    return null;
  }
  if (creditScoreValue === 0) {
    creditScoreValue = 0.02;
  }

  const calculateRiskColor = (
    value: number,
    fadeFactor: number = 1
  ): string => {
    const green = Math.max(0, Math.min(255, 255 - Math.round(value * 255)));
    const red = Math.max(0, Math.min(255, Math.round(value * 255)));
    return `rgba(${green},${red},0,${fadeFactor})`;
  };

  const mainColor = calculateRiskColor(creditScoreValue);
  const fadedColor = calculateRiskColor(creditScoreValue, 0.2);

  const chartData = [
    { name: "riskPercentage", value: creditScoreValue },
    { name: "remaining", value: 1 - creditScoreValue }
  ];

  return (
    <PieChart
      width={200}
      height={100}
      margin={{
        top: 60
      }}
    >
      <Pie
        data={chartData}
        cx="50%"
        cy="50%"
        startAngle={180}
        endAngle={0}
        innerRadius={60}
        outerRadius={80}
        dataKey="value"
      >
        <Cell fill={mainColor} />
        <Cell fill={fadedColor} />
        <Label
          value={label}
          position="center"
          fill="black"
          fontSize={20}
          fontWeight="bold"
        />
      </Pie>
    </PieChart>
  );
}
