import type { TypeNotification } from "../../types/notification";
import { Translation } from "../../types/taskProps";
import { CaseSummaryItem } from "./components/CaseSummaryItem";

type NotificationsCaseSummaryProps = {
  flowData: {
    notification: TypeNotification;
    creditPaymentFlowId: string;
  };
  fixedT: Translation;
};

export function NotificationsCaseSummary({
  flowData,
  fixedT
}: NotificationsCaseSummaryProps) {
  const notification: TypeNotification = flowData?.notification;
  const applicationId = flowData?.creditPaymentFlowId;
  return (
    <div
      className={
        "border-line grid gap-4 rounded-md border-solid border-primary-200 bg-primary-50 p-4 shadow items-center break-words text-gray-700 font-medium text-sm"
      }
    >
      <div className="pl-2 text-base text-blue-400 hover:underline">
        <a
          href={`${window.location.origin}/cases/case/credit-payment/${applicationId}`}
        >
          {`${fixedT("application")}: ${applicationId}`}
        </a>
      </div>
      {notification ? (
        <CaseSummaryItem
          items={[
            {
              label: fixedT("application-flow-id"),
              value: applicationId
            },
            {
              label: fixedT("event-type"),
              value: notification.EventType
            },
            {
              label: fixedT("psp-payment-id"),
              value: notification.PspPaymentId
            },
            {
              label: fixedT("merchant-transaction-reference"),
              value: notification.MerchantTransactionReference
            }
          ]}
        />
      ) : null}
    </div>
  );
}
