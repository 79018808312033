import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TaskProps } from "../../types/taskProps";
import { getModificationsByPaymentId } from "../../utils/modifications";
import { EventsCard } from "./EventsCard";
import { ModificationsCard } from "./ModificationsCard";
import { OverviewCard } from "./OverviewCard";
import { SearchForm } from "./SearchForm";

export function Storage(props: TaskProps) {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [flowId, setFlowId] = useState("");

  const pspPaymentId =
    new URLSearchParams(window.location.search).get("pspPaymentId") || "";

  useEffect(() => {
    setSearch(pspPaymentId);
    setFlowId(pspPaymentId);
  }, [pspPaymentId]);

  const { data: purchase } = useQuery({
    queryKey: ["purchase", flowId],
    queryFn: () => getModificationsByPaymentId(flowId),
    enabled: !!flowId,
    refetchInterval: 10000
  });

  const allModifications = [
    ...(purchase?.capture || []),
    ...(purchase?.refunds || []),
    ...(purchase?.cancel || [])
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setFlowId(search);
    history.replace(`/storage?pspPaymentId=${search}`);
  };

  function NoResults() {
    return (
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-2xl font-medium text-primary-900">
          No results found
        </h1>
      </div>
    );
  }

  return (
    <main className="flex flex-col w-full min-h-screen overflow-y-scroll flex-1 gap-4 p-12 md:gap-8">
      <div className="flex flex-row justify-center">
        <div className="flex flex-col flex-grow 2xl:max-w-7xl xl:px-56 lg:px-32 justify-center gap-10 p-8 bg-primary-searchBoxGray border border-gray-200">
          <div className="flex justify-center">
            <h1 className="font-medium text-3xl text-primary-ferrari">
              Storage
            </h1>
          </div>
          <div className="flex flex-row">
            <SearchForm
              onSearchChange={handleInputChange}
              onFormSubmit={handleFormSubmit}
              searchValue={search}
            />
          </div>
          {purchase ? (
            <>
              <OverviewCard overview={purchase} />
              {allModifications.length > 0 && (
                <ModificationsCard
                  modifications={allModifications}
                  overview={purchase}
                  props={props}
                />
              )}
              {purchase.events && purchase.events.length > 0 && (
                <EventsCard events={purchase.events} />
              )}
            </>
          ) : (
            <NoResults />
          )}
        </div>
      </div>
    </main>
  );
}
