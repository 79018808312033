import { EyeIcon, EyeOffIcon } from "lucide-react";
import { useState } from "react";
import { cn } from "../utils/cn";

type TextMaskerProps = {
  text?: string;
  maskCount?: number;
  direction?: "left" | "right";
  className?: string;
};

export function TextMasker({
  text,
  maskCount = 5,
  direction = "right",
  className
}: TextMaskerProps) {
  const [isMasked, setIsMasked] = useState(true);
  if (!text) return <span>N/A</span>;

  const toggleMask = () => {
    setIsMasked(!isMasked);
  };

  const getMaskedText = () => {
    if (isMasked) {
      if (direction === "left") {
        return "*".repeat(maskCount) + text.slice(maskCount);
      } else if (direction === "right") {
        return text.slice(0, -maskCount) + "*".repeat(maskCount);
      }
    }
    return text;
  };

  return (
    <div className={cn("flex items-center justify-end", className)}>
      <span>{getMaskedText()}</span>
      <button onClick={toggleMask} className="ml-1 text-primary-800">
        {isMasked ? <EyeIcon size={16} /> : <EyeOffIcon size={16} />}
      </button>
    </div>
  );
}
