import {
  FINANCIAL_PROVIDERS,
  FinancialProviders
} from "../types/financialProviders";

export function mapBankId(bankId: FinancialProviders) {
  switch (bankId) {
    case FINANCIAL_PROVIDERS.SANTANDERSE:
      return "Santander SE";
    case FINANCIAL_PROVIDERS.RESURSFI:
      return "Resurs Bank FI";
    default:
      return "--";
  }
}
