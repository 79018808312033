import { FlowContext } from "../../../hooks/useFlowContext";
import { TaskProps } from "../../../types/taskProps";
import { SigningRightsFile } from "../../CaseSummary/components/creditProcessCaseSummary/Files";
import { DecisionPanel } from "../components/DecisionPanel";

import { Layout } from "../components/Layout";
import { EvaluatePOADocumentDecision } from "./EvaluatePOADocumentDecision";

export function EvaluatePOADocument(props: TaskProps) {
  const { flow } = props;
  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <SigningRightsFile {...flow} />
        <div className="text-sm">
          <DecisionPanel>
            <EvaluatePOADocumentDecision props={props} />
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
