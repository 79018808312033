import { COUNTRY } from "../../../../../types/creditProcess/enums";
import { LegalForm } from "../../../../../types/d&b/b2b/companyInformation";
import { TaskProps } from "../../../../../types/taskProps";
import { FinancialStatements } from "./FinancialStatements";
import { Taxation } from "./Taxation";

export function Financial(flow: TaskProps["flow"]) {
  const { data } = flow;
  const { country } = data.applicant;
  const companyInformation =
    data?.raw?.dunAndBradstreet?.companyRiskAndCreditData?.companyInformation;
  const legalForm = companyInformation?.legalForm as LegalForm;
  const legalCode = legalForm?.code;
  const isNorway = country === COUNTRY.NORWAY;
  if (legalCode === "SOLE_PROPRIETORSHIP" && isNorway)
    return <Taxation {...flow} />;
  return <FinancialStatements {...flow} />;
}
