import { Icons } from "@stacc/flow-ui-components";
import { DecisionTesting } from "../components/DecisionTesting";
import { HomePage } from "../components/Home/HomePage";
import { Filter } from "../components/icons/Filter";
import { Reports } from "../components/reports";
import { Storage } from "../components/storage/Storage";
import {
  isAdmin,
  isAdminB2b,
  isAdminB2c,
  isCaseWorkerB2b,
  isCaseWorkerB2c,
  isCaseWorkerElevatedB2b,
  isCaseworkerReadOnly,
  isNordicCFOB2b,
  isNordicComplianceB2b,
  isNordicElevatedB2b
} from "../functions/isRole";
import { CustomRoute } from "../types/case-manager";
import { TypeRole } from "../types/role";

const homePageRoute: CustomRoute = {
  path: "",
  component: HomePage
};

export const storageRoute: CustomRoute = {
  path: "/storage",
  component: Storage,
  navigation: {
    title: "Storage",
    icon: Filter
  }
};

export const decisionTestingRoute: CustomRoute = {
  path: "/decisionTesting",
  component: DecisionTesting,
  navigation: {
    title: "decisionTesting",
    icon: Icons.Auditlog
  }
};

export const creditScoreRoute: CustomRoute = {
  path: "/reports",
  component: Reports,
  navigation: {
    title: "Reports",
    icon: Icons.FilePdf
  }
};

// NB: Gotcha on route order. The home page route needs to be LAST, else the custom route won't render.
export function getCustomRoutes(role: TypeRole): CustomRoute[] {
  if (isAdmin(role)) {
    return [
      decisionTestingRoute,
      storageRoute,
      creditScoreRoute,
      homePageRoute
    ];
  }
  if (isAdminB2b(role)) {
    return [decisionTestingRoute, creditScoreRoute, homePageRoute];
  }
  if (isAdminB2c(role)) {
    return [storageRoute, homePageRoute];
  }

  if (
    isNordicCFOB2b(role) ||
    isNordicElevatedB2b(role) ||
    isNordicComplianceB2b(role)
  ) {
    return [creditScoreRoute, homePageRoute];
  }

  if (isCaseWorkerB2b(role) || isCaseWorkerElevatedB2b(role)) {
    return [homePageRoute];
  }

  if (isCaseWorkerB2c(role) || isCaseworkerReadOnly(role)) {
    return [storageRoute, homePageRoute];
  }

  return [];
}
