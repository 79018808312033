import { Modal, SecondaryButton } from "@stacc/flow-ui-components";
import { useState } from "react";
import { standarizeDecisionHeaders } from "../../utils/standarizeDecisionHeaders";
import { Card } from "../Card";
import { CircleIconWithTooltip } from "../CircleIcon";
import { TableIcon } from "../icons/Table";
import { DMNViewer } from "./DMNViewer";

export function DMNCard({
  item,
  descriptions
}: {
  item: any;
  descriptions: Array<string | undefined>;
}) {
  const { tablename } = item?.decision?.metaData;
  const hits = item?.ruleIds.length;
  const [showModal, setShowModal] = useState(false);
  const tableHeader = standarizeDecisionHeaders(tablename);

  return (
    <div className="w-full ">
      <Card>
        <Card.Header className="bg-primary-100 text-primary-800 flex items-center">
          <TableIcon width={18} height={18} />
          <span>{tableHeader}</span>
          <CircleIconWithTooltip
            count={hits}
            label={"Number of hits in the table."}
            bottom={true}
          />
        </Card.Header>
        <Card.Content className="p-4 flex flex-col gap-5">
          {descriptions.map((description, index) => {
            return description ? (
              <div key={index} className="list-item ml-4">
                {description}
              </div>
            ) : (
              <div key={index} className="list-item ml-4 text-red-500">
                Could not find description using rule ID- Check table
              </div>
            );
          })}
          <div>
            <SecondaryButton onClick={() => setShowModal(true)} size="normal">
              Show table
            </SecondaryButton>

            {showModal && (
              <Modal
                title={tableHeader}
                onClose={() => {
                  setShowModal(false);
                }}
                size="large"
                withChildren="overflow"
              >
                <DMNViewer dmnXML={item?.xml} ruleIds={item?.ruleIds} />
              </Modal>
            )}
          </div>
        </Card.Content>
      </Card>
    </div>
  );
}
