import { OctagonAlert } from "lucide-react";
import { useFlowContext } from "../../../../hooks/useFlowContext";
import { Decisions, Reason } from "../../../../types/creditProcess/state";
import { COLOR, Card } from "../../../Card";
import { DisplayRejectionReasons } from "./DisplayRejectionReasons";

export function RejectionReasons({
  decisions
}: {
  decisions: Decisions | undefined;
}) {
  const { t } = useFlowContext();
  const redReasons: Reason[] = [];
  const yellowReasons: Reason[] = [];

  const { creditScore, sanctions } = decisions ?? {};
  const creditRed = creditScore?.rejectionReasons?.red;
  const creditYellow = creditScore?.rejectionReasons?.yellow;

  if (!creditScore && !sanctions) {
    return null;
  }

  if (creditRed) {
    creditRed.forEach((score) => {
      redReasons.push({
        reason: `Credit Score - ${score?.reason}`,
        reasonCode: score?.reasonCode ?? "Reason code missing"
      });
    });
  }
  if (creditYellow) {
    creditYellow.forEach((score) => {
      yellowReasons.push({
        reason: `Credit Score - ${score?.reason}`,
        reasonCode: score?.reasonCode ?? "Reason code missing"
      });
    });
  }

  if (sanctions) {
    sanctions.forEach((sanction) => {
      sanction?.rejectionReasons?.red?.forEach((reason) => {
        redReasons.push({
          reason: `Sanction - ${reason?.reason}`,
          reasonCode: reason?.reasonCode ?? "Reason code missing"
        });
      });

      sanction?.rejectionReasons?.yellow?.forEach((reason) => {
        yellowReasons.push({
          reason: `Sanction - ${reason?.reason}`,
          reasonCode: reason?.reasonCode ?? "Reason code missing"
        });
      });
    });
  }

  const hasRedReasons = redReasons && redReasons.length > 0;
  const hasYellowReasons = yellowReasons && yellowReasons.length > 0;
  const color = hasRedReasons ? COLOR.RED : COLOR.YELLOW;
  if (hasRedReasons || hasYellowReasons) {
    return (
      <div>
        <Card>
          <Card.Header color={color}>
            <Card.HeaderTitle>
              <OctagonAlert />
              {t("rejection-reasons")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content
            horizontalPadding={false}
            className="flex flex-col gap-4 px-5"
          >
            <DisplayRejectionReasons color={COLOR.RED} reasons={redReasons} />
            <DisplayRejectionReasons
              color={COLOR.YELLOW}
              reasons={yellowReasons}
            />
          </Card.Content>
        </Card>
      </div>
    );
  }

  return null;
}
