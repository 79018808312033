import { Link } from "react-router-dom";
import {
  creditScoreRoute,
  decisionTestingRoute,
  getCustomRoutes,
  storageRoute
} from "../../configuration/customRoutes";
import { isAdmin, isAdminB2b } from "../../functions/isRole";
import {
  displaySVG,
  findDateString,
  findTimeOfDay
} from "../../utils/findDateString";
import { getNamespaceAndEnv } from "../../utils/namespace";
import { WidgetCard } from "./WidgetCard";

function homeConfig(namespace: string): string {
  return namespace === "prod"
    ? "https://panel.control-panel.prod.elkjop-finance.stacc.live/"
    : "https://panel.control-panel-dev.elkjop-finance.stacc.dev/login";
}

export function HomePage(props: any) {
  const { role } = props?.user ?? {};
  const getNamespace = getNamespaceAndEnv();
  const { namespace, env } = getNamespace;
  const notProduction = namespace !== "prod";

  const decisionAdminUrl = homeConfig(namespace);

  const iconPath = process.env.PUBLIC_URL;
  const date = new Date();
  const dateString = findDateString(date);
  const timeOfDay = findTimeOfDay(date);
  const svgPath = displaySVG(timeOfDay);
  const getRoutes = getCustomRoutes(role);

  return (
    <div className="h-full w-full min-h-screen overflow-y-scroll bg-gray-100 p-12 flex flex-col gap-1">
      <div className="flex flex-col gap-12">
        <div>
          <p className="text-sm">{dateString}</p>
          <h1 className="text-2xl">
            {`Good ${timeOfDay}, ${props?.user?.name.split(" ")?.[0]}`}
          </h1>
        </div>
        <div className="space-y-5">
          <div className="flex flex-row gap-5">
            {(isAdmin(role) || isAdminB2b(role)) && (
              <a href={decisionAdminUrl} target="_blank" rel="noreferrer">
                <WidgetCard
                  imgSrc={`${iconPath}/decision-admin.webp`}
                  alt="Decision Admin picture"
                  text={"Decision Admin"}
                />
              </a>
            )}
            {notProduction && (
              <a
                href={`https://docs.${namespace}.elkjop-finance.stacc.${env}/`}
                target="_blank"
                rel="noreferrer"
              >
                <WidgetCard
                  imgSrc={`${iconPath}/digital-architecture.jpeg`}
                  alt="Documentation picture"
                  text={"Documentation"}
                />
              </a>
            )}
          </div>
          <div className="flex flex-row gap-5">
            {getRoutes.includes(decisionTestingRoute) && (
              <Link to="/decisionTesting">
                <WidgetCard
                  imgSrc={`${iconPath}/decision-testing.jpg`}
                  alt="Decision Testing picture"
                  text={"Decision Testing"}
                />
              </Link>
            )}
            {getRoutes.includes(storageRoute) && (
              <Link to="/storage">
                <WidgetCard
                  imgSrc={`${iconPath}/storage.jpg`}
                  alt="Storage picture"
                  text={"Storage"}
                />
              </Link>
            )}
            {getRoutes.includes(creditScoreRoute) && (
              <Link to="/reports">
                <WidgetCard
                  imgSrc={`${iconPath}/report.jpg`}
                  alt="Reports picture"
                  text={"Reports"}
                />
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="h-auto w-full flex flex-row justify-center">
        <div className="h-96 w-96 ">
          <img src={`${iconPath}/${svgPath}`} alt="time of day illustrated" />
        </div>
      </div>
    </div>
  );
}
