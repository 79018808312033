import { zodResolver } from "@hookform/resolvers/zod";
import {
  NumberField,
  PrimaryButton,
  SecondaryButton
} from "@stacc/flow-ui-components";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { useFormConnect } from "../../../hooks/useFormConnect";
import { TaskProps } from "../../../types/taskProps";
import { Card } from "../../Card";
import { Header } from "../../Headers";
import { Note } from "../../inputs/Note";
import { RadioButtons } from "../../inputs/RadioButtons";

interface DecisionForm {
  outcome: string;
  approvedAmount: number;
  note: string;
}

export function CreditDecision({ props }: { props: TaskProps }) {
  const { t, complete, save, task, flow } = props;
  const { approvedAmount: savedApproved, note: savedNote } = task?.data ?? {};

  const methods = useForm<DecisionForm>({
    defaultValues: {
      approvedAmount:
        savedApproved ?? task?.defaults?.defaultApprovedAmount ?? 0,
      note: savedNote ?? ""
    },
    resolver: zodResolver(
      z.object({
        approvedAmount: z.coerce
          .number()
          .min(1, "Approved amount must be greater than 0"),
        outcome: z.string(),
        note: z.string()
      })
    )
  });

  function onSubmit(data: DecisionForm) {
    complete({ ...data, outcome: data.outcome === "true" });
  }

  function onSave(data: DecisionForm) {
    save(data);
  }
  const appeal = flow?.data?.application?.appeal;
  const approvedIsDirty = methods.formState.dirtyFields.outcome;
  const noteOrLimitIsDirty =
    methods.formState.dirtyFields.note ||
    methods.formState.dirtyFields.approvedAmount;
  return (
    <FormProvider {...methods}>
      <form>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-2">
            <Header>{t("decision")}</Header>
            <RadioButtons id="outcome" />
          </div>

          <div className="flex flex-col gap-2">
            <Header>{t("credit-limit")}</Header>
            <ApprovedAmount id="approvedAmount" />
          </div>
          <div className="flex flex-col gap-2">
            <Header>{t("notes")}</Header>
            <Note id="note" />
            {appeal?.comment && (
              <Card>
                <Card.Header>
                  <Card.HeaderTitle>Comment from seller</Card.HeaderTitle>
                </Card.Header>
                <Card.Content>{appeal?.comment}</Card.Content>
              </Card>
            )}
          </div>
          <div className="flex gap-3">
            <PrimaryButton
              disabled={!approvedIsDirty}
              type="button"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("submit")}
            </PrimaryButton>
            <SecondaryButton
              disabled={!noteOrLimitIsDirty}
              type="button"
              onClick={methods.handleSubmit(onSave)}
            >
              {t("save")}
            </SecondaryButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

function ApprovedAmount({ id }: { id: string }) {
  const { ref, error, value, ...props } = useFormConnect(id);
  return (
    <div className="max-w-xs">
      <NumberField {...props} defaultValue={parseInt(value)} />
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}
