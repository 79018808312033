type CaseSummaryItemProps = {
  items: Array<{
    label: string;
    value: string | number | React.ReactNode;
  }>;
};

export function CaseSummaryItem({ items }: CaseSummaryItemProps) {
  return (
    <div className="grid w-full auto-rows-auto gap-1 bg-white px-2 py-1 rounded-md">
      <div className={`grid grid-cols-2 p-2 gap-4 `}>
        {items.map((item) => (
          <div className="flex items-center" key={item.label}>
            <span className="flex-shrink font-semibold justify-start text-left">
              {item.label}:
            </span>
            <span className="flex-grow justify-end text-right flex">
              {item.value || "--"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
