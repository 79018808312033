import { Country, COUNTRY } from "../../../../../types/creditProcess/enums";
import { DanishManagement } from "../../../../../types/d&b/b2b/management/dk";
import { FinnishManagement } from "../../../../../types/d&b/b2b/management/fi";
import { NorwegianManagement } from "../../../../../types/d&b/b2b/management/no";
import { SwedishManagement } from "../../../../../types/d&b/b2b/management/se";
import { dateToString } from "../../../../../utils/helpers";

type CommonManagementData =
  | NorwegianManagement
  | SwedishManagement
  | FinnishManagement;
type ManagementData = {
  country: Country;
  data: CommonManagementData | DanishManagement;
};

export function getManagementTableData({ country, data }: ManagementData) {
  switch (country) {
    case COUNTRY.NORWAY:
    case COUNTRY.SWEDEN:
    case COUNTRY.FINLAND:
      return getCommonManagementTableData(data as CommonManagementData);
    case COUNTRY.DENMARK:
      return getDenmarkManagementTableData(data as DanishManagement);
    default:
      return undefined;
  }
}

function getCommonManagementTableData(management: CommonManagementData) {
  const currentBoardMembers = management?.boardMembers?.currentBoardMembers;
  const historicalBoardMembers =
    management?.boardMembers?.historicalBoardMembers;
  return {
    currentBoardMembers: currentBoardMembers?.map((member) => ({
      name: member?.entity?.name,
      role: member?.description,
      birthDate: dateToString(
        member?.entity?.birthDate?.day,
        member?.entity?.birthDate?.month,
        member?.entity?.birthDate?.year
      )
    })),
    historicalBoardMembers: historicalBoardMembers?.map((member) => ({
      name: member?.entity?.name,
      role: member?.description,
      birthDate: dateToString(
        member?.entity?.birthDate?.day,
        member?.entity?.birthDate?.month,
        member?.entity?.birthDate?.year
      )
    }))
  };
}

function getDenmarkManagementTableData(management: DanishManagement) {
  const currentBoardMembers = management?.directors?.currentDirectors;
  const historicalBoardMembers = management?.directors?.historicalDirectors;
  return {
    currentBoardMembers: currentBoardMembers?.map((member) => ({
      name: member?.name,
      role: member?.responsibility,
      birthDate: ""
    })),
    historicalBoardMembers: historicalBoardMembers?.map((member) => ({
      name: member?.name,
      role: member?.responsibility,
      birthDate: ""
    }))
  };
}
