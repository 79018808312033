import { Icons } from "@stacc/flow-ui-components";
import { cn } from "../utils/cn";

export function NoRemarks({
  label,
  isSuccess = true,
  className
}: {
  label: string;
  isSuccess?: boolean;
  className?: string;
}) {
  return (
    <div
      className={cn(className, "flex items-center justify-center pt-5 text-xl")}
    >
      {label}
      {isSuccess && <Icons.SuccessCircle />}
    </div>
  );
}
