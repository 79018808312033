import { QueueItemProps } from "../types/case-manager/custom-components";
import { TaskProps } from "../types/taskProps";
import { CreditPaymentQueueCard } from "./CreditPaymentQueueCard";
import { CreditProcessQueueCard } from "./CreditProcessQueueCard";

export function QueueItem(props: QueueItemProps<TaskProps["flow"]>) {
  const { flowDefinitionId } = props.flow;
  const validFlowIds = [
    "credit-process",
    "credit-score-b2b-sweden",
    "credit-score-b2b-norway",
    "credit-score-b2b-denmark",
    "credit-score-b2b-finland",
    "credit-score-b2c-sweden",
    "credit-score-b2c-norway",
    "credit-score-b2c-denmark",
    "credit-score-b2c-finland"
  ];

  if (validFlowIds.includes(flowDefinitionId)) {
    return (
      <div>
        <CreditProcessQueueCard {...props} />
      </div>
    );
  }
  if (flowDefinitionId === "credit-payment") {
    return (
      <div>
        <CreditPaymentQueueCard {...props} />
      </div>
    );
  }

  return null;
}
