import { useFlowContext } from "../../../../../hooks/useFlowContext";
import { TaskProps } from "../../../../../types/taskProps";
import { NoRemarks } from "../../../../NoRemarks";
import { SigningRightsFilePreview } from "./SigningRightsFilePreview";

export function SigningRightsFile(flow: TaskProps["flow"]) {
  const { t } = useFlowContext();
  const { data, flowId } = flow ?? {};
  const { fileName: blobName, mimetype } = data?.signingRightsFile ?? {};
  if (!blobName || !mimetype) {
    return <NoRemarks label={t("no-file-to-display")} />;
  }
  return (
    <SigningRightsFilePreview
      mimeType={mimetype}
      flowId={flowId}
      blobName={blobName}
    />
  );
}
