import { CenteredSpinner } from "@stacc/flow-ui-components";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { FlowContext } from "../../hooks/useFlowContext";
import { TaskProps } from "../../types/taskProps";
import {
  fetchAttachmentByKey,
  fetchAttachmentsByFlowId
} from "../../utils/attachments";
import { DocumentsCard } from "./DocumentsCard";

export function Files(props: TaskProps) {
  const { flow, t } = props;
  const { flowId } = flow;
  const { data: files, isFetching } = useQuery({
    queryKey: ["files", flowId],
    queryFn: async () => {
      const { attachments } = await fetchAttachmentsByFlowId(flowId);
      const attachmentsWithVersions = await Promise.all(
        attachments.map(async (attachment: any) => {
          let data;
          try {
            data = await fetchAttachmentByKey(flowId, attachment.attachmentKey);
          } catch (err) {
            if (axios.isAxiosError(err)) {
              data = null;
            }
          }

          const version = data?.attachment;
          if (!version) {
            console.error("failed to fetch attachment:", attachment);
            return {
              attachmentKey: attachment.attachmentKey,
              owner: "",
              filename: attachment.attachmentKey,
              hasFailed: true
            };
          }
          if (version.previousVersions) {
            return [
              { attachmentKey: attachment.attachmentKey, ...version },

              ...Object.values(version.previousVersions).map((element: any) => {
                return { attachmentKey: attachment.attachmentKey, ...element };
              })
            ];
          } else {
            return { attachmentKey: attachment.attachmentKey, ...version };
          }
        })
      );
      return {
        flowId,
        attachments: attachmentsWithVersions
          .flat()
          .sort((a, b) => b.version - a.version)
      };
    }
  });
  const customerDocuments =
    files?.attachments?.filter((file: any) => file.owner === "mainApplicant") ??
    [];

  const signingRightsFile = flow?.data?.signingRightsFile;
  if (signingRightsFile) {
    signingRightsFile.isSigningRights = true;
    customerDocuments.push(signingRightsFile);
  }

  const caseWorkerDocuments = files?.attachments?.filter(
    (file: any) => file.owner === "caseWorker"
  );

  const remainingDocuments = files?.attachments?.filter(
    (file: any) =>
      file.owner !== "mainApplicant" &&
      file.owner !== "coApplicant" &&
      file.owner !== "caseWorker"
  );

  if (isFetching) return <CenteredSpinner />;
  return (
    <FlowContext.Provider value={props}>
      <div className="flex flex-col gap-5">
        <DocumentsCard
          title={t("customerUploadedDocuments")}
          data={customerDocuments ?? []}
          color="blue"
        />
        <DocumentsCard
          title={t("caseWorkerUploadedDocuments")}
          data={caseWorkerDocuments ?? []}
          color="yellow"
        />
        <DocumentsCard
          title={t("flowUploadedDocuments")}
          data={remainingDocuments ?? []}
          color="green"
        />
      </div>
    </FlowContext.Provider>
  );
}
