import { RadioButtons as FlowRadioButtons } from "@stacc/flow-ui-components";
import { useFormConnect } from "../../hooks/useFormConnect";

export function RadioButtons({ id }: { id: string }) {
  const { ref, error, value, ...props } = useFormConnect(id);
  return (
    <div className="flex flex-col gap-2">
      <FlowRadioButtons
        {...props}
        checkedValue={value}
        options={[
          { label: "Approve", value: true },
          { label: "Deny", value: false }
        ]}
      />
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}
