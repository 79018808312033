import { CircleAlert } from "lucide-react";
import { useFlowContext } from "../../../../../hooks/useFlowContext";
import { TaskProps } from "../../../../../types/taskProps";
import { cn } from "../../../../../utils/cn";
import { COLOR, Card } from "../../../../Card";
import { UBO } from "./UBO";
import { Sanctions } from "./sanctions";

export function Compliance({
  flow,
  className
}: {
  flow: TaskProps["flow"];
  className?: string;
}) {
  const { t } = useFlowContext();
  const matches = flow?.data?.sanctions?.matches ?? [];
  const foreignCompanyOwners =
    flow?.data?.raw?.dunAndBradstreet?.foreignCompanyOwners ?? [];
  return (
    <div className={cn(className, "flex flex-col gap-5")}>
      <Card>
        <Card.Header color={COLOR.YELLOW}>
          <Card.HeaderTitle>
            <CircleAlert />
            {t("sanctions")}
          </Card.HeaderTitle>
        </Card.Header>
        <Card.Content horizontalPadding={false} className="px-5">
          <Sanctions matches={matches} />
        </Card.Content>
      </Card>
      <Card>
        <Card.Header color={COLOR.YELLOW}>
          <Card.HeaderTitle>
            <CircleAlert />
            {t("foreign-company-owners")}
          </Card.HeaderTitle>
        </Card.Header>
        <Card.Content horizontalPadding={false} className="px-5">
          <UBO foreignCompanyOwners={foreignCompanyOwners} />
        </Card.Content>
      </Card>
    </div>
  );
}
