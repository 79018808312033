import { Coins } from "lucide-react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { useFlowContext } from "../../../../../hooks/useFlowContext";
import { B2BResponseModel } from "../../../../../types/d&b/b2b";
import { TaskProps } from "../../../../../types/taskProps";
import { classNames } from "../../../../../utils/classNames";
import { formatCurrency } from "../../../../../utils/helpers";
import { COLOR, Card } from "../../../../Card";
import { NoRemarks } from "../../../../NoRemarks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "../../../../table/index";

export function Taxation(flow: TaskProps["flow"]) {
  const { t } = useFlowContext();
  const { data } = flow;
  const companyRiskAndCreditData = data?.raw?.dunAndBradstreet
    ?.companyRiskAndCreditData as B2BResponseModel<"no">;
  const ownership =
    companyRiskAndCreditData?.ownership?.soleProprietorOwnership;
  const incomeTaxations = ownership?.incomeTaxations;
  if (!incomeTaxations || incomeTaxations?.length === 0) {
    return <NoRemarks label={t("no-financial-data")} isSuccess={false} />;
  }

  const tableData = incomeTaxations.map((taxation) => ({
    year: taxation.year,
    data: [
      {
        key: "assessedTax",
        value: formatCurrency(
          taxation.assessedTax.amount,
          taxation.assessedTax.currency
        )
      },
      { key: "taxClass", value: taxation.taxClass },
      {
        key: "calculatedGrossIncome",
        value: formatCurrency(
          taxation.calculatedGrossIncome.amount,
          taxation.calculatedGrossIncome.currency
        )
      },
      {
        key: "netWealth",
        value: formatCurrency(
          taxation.netWealth.amount,
          taxation.netWealth.currency
        )
      },
      {
        key: "netIncome",
        value: formatCurrency(
          taxation.netIncome.amount,
          taxation.netIncome.currency
        )
      },
      {
        key: "municipality",
        value: taxation.municipalityName
          ? `${taxation.municipalityName} (${taxation.municipalityNumber})`
          : "N/A"
      }
    ]
  }));

  const netIncomeChartData = incomeTaxations.map((taxation) => ({
    year: taxation.year,
    netIncome: Number(taxation.netIncome.amount)
  }));

  const netWealthChartData = incomeTaxations.map((taxation) => ({
    year: taxation.year,
    netWealth: Number(taxation.netWealth.amount)
  }));

  return (
    <div className="flex flex-col gap-10 pt-5 w-full h-full">
      <div className="grid h-60 grid-cols-2">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={netIncomeChartData}
            margin={{
              top: 5,
              right: 12,
              left: 35,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 4" vertical={false} />
            <XAxis
              dataKey={"year"}
              tickLine={false}
              axisLine={false}
              tickMargin={10}
            />
            <YAxis
              tickFormatter={(tick) => {
                return tick.toLocaleString();
              }}
              className="text-sm"
            />
            <Tooltip />
            <Legend />
            <ReferenceLine y={0} stroke="#696969" />
            <Bar
              dataKey={"netIncome"}
              name={t("netIncome")}
              fill="#2563eb"
              radius={5}
            />
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={netWealthChartData}
            margin={{
              top: 5,
              right: 12,
              left: 35,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey={"year"}
              tickLine={false}
              axisLine={false}
              tickMargin={10}
              padding={{ left: 15, right: 15 }}
            />
            <YAxis
              tickFormatter={(tick) => {
                return tick.toLocaleString();
              }}
              className="text-sm"
            />
            <Tooltip />
            <Legend />
            <Area
              type="monotone"
              dataKey={"netWealth"}
              name={t("netWealth")}
              stroke="#2563eb"
              fill="#4595f8"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div className={classNames("overflow-auto pb-10 w-full")}>
        <Card>
          <Card.Header color={COLOR.GREEN}>
            <Card.HeaderTitle>
              <Coins />
              {t("taxation")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content horizontalPadding={false} className="px-5">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="sticky left-0 z-10 bg-white"></TableHead>
                  {incomeTaxations.map((taxation) => (
                    <TableHead key={taxation.year} className="text-right">
                      {taxation.year}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {tableData[0].data.map((pair) => (
                  <TableRow key={pair.key} className="border-0">
                    <TableCell className="sticky left-0 z-10 whitespace-nowrap bg-white py-2 font-medium text-gray-700">
                      {t(pair.key)}
                    </TableCell>
                    {tableData.map((yearData) => {
                      const foundValue = yearData.data.find(
                        (item) => item.key === pair.key
                      )?.value;
                      return (
                        <TableCell
                          key={`${yearData.year}-${pair.key}`}
                          className="pl-10 text-right text-gray-600"
                        >
                          {foundValue ? foundValue : t("N/A")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card.Content>
        </Card>
      </div>
    </div>
  );
}
