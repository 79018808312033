import axios from "axios";
import type { TypeModifications } from "../types/modification";
import { getToken } from "./sessionStorage";

const client = axios.create();
client.interceptors.response.use((response) => {
  return response.data;
});

export async function getModifications(
  flowId: string
): Promise<TypeModifications> {
  const token = getToken();
  return await client.get(`/api/storage/${flowId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export async function startModification(
  payload: any
): Promise<TypeModifications> {
  const token = getToken();
  return await axios.post(`/api/credit-payment/modifications/retry`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export async function getModificationsByPaymentId(
  paymentId: string
): Promise<TypeModifications> {
  const token = getToken();
  return await client.get(`/api/storage/paymentId/${paymentId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export type TestRunStats = {
  percentApprovedApplications: number;
  percentFailedScoring: number;
  percentManualHandling: number;
  percentRejectedAppications: number;
  totalApprovedCredit: number;
};

export type TestRunData = {
  applications: any[];
  inputValues: any[];
  country?: string;
  query: {
    country: string;
    count: number;
    end: string;
    start: string;
  }
  totalOutcome: {
    totals: {
      numberOfApplications: number;
    };
    dryRun: TestRunStats;
    existing: TestRunStats;
    live: TestRunStats;
  };
};

export type TestRunState = {
  data: {
    data: TestRunData;
  };
};

export type DefaultState = {
  createdAt: string;
  data: TestRunData;
  flowId: string;
};

export async function getTestCaseByFlowId(
  flowId: string
): Promise<TestRunState> {
  return await axios.get(`/api/flow/flows/${flowId}?stripTasks=true`);
}

export const getDryRunCases = async (): Promise<DefaultState[]> => {
  const response = await axios.get(
    `/api/flow/flows?flowDefinitionId=dry-run-decision&limit=5&sort=createdAt&dir=-1&view=dryRunView&hasIncidents=false`
  );
  return response.data;
};
