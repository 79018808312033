import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import React, { Children, ReactNode } from "react";
import { cn } from "../utils/cn";

type Props = {
  children?: React.ReactNode;
};
type Slots = {
  content: ReactNode | null;
  title: ReactNode | null;
};

function Title({
  title,
  children,
  className,
  ...props
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div {...props} className={cn("flex items-center gap-3", className)}>
      {title && <span>{title}</span>}
      {children}
    </div>
  );
}

function Content({
  children,
  className,
  ...props
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div {...props} className={cn(className)}>
      {children}
    </div>
  );
}

export function Collapsible({
  className,
  children
}: {
  className?: string;
  children: React.ReactNode;
}) {
  const slots: Slots = {
    content: null,
    title: null
  };
  Children.forEach(children, (child) => {
    switch ((child as React.ReactElement<Props>).type) {
      case Content: {
        slots.content = child;
        break;
      }
      case Title: {
        slots.title = child;
        break;
      }
    }
  });

  return (
    <Disclosure as="div" className={className}>
      {({ open }) => (
        <>
          <Disclosure.Button className="group flex w-full items-center justify-between">
            {slots.title}
            {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Disclosure.Button>
          <Disclosure.Panel className="mt-2 text-sm/5 text-white/50">
            {slots.content}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

Collapsible.Title = Title;
Collapsible.Content = Content;
