import { useQuery } from "@tanstack/react-query";
import { getSigningRightsFile } from "../../../../../utils/attachments";
import { LoadingSpinner } from "../../../../LoadingSpinner";

export function SigningRightsFilePreview({
  mimeType,
  flowId,
  blobName
}: {
  mimeType: string;
  flowId: string;
  blobName: string;
}) {
  const { data: fileUrl, isFetching } = useQuery({
    queryKey: ["filePreview", blobName],
    queryFn: () => getSigningRightsFile(flowId, blobName, mimeType)
  });

  const isImage = mimeType.includes("image");
  const isPdf = mimeType.includes("pdf");

  if (isFetching) {
    return (
      <div>
        <LoadingSpinner width={24} height={24} className="my-5 w-full" />
      </div>
    );
  }

  return (
    <div className="grid place-items-center">
      {isImage && <img src={fileUrl} alt="file" className="w-full" />}
      {isPdf && (
        <iframe className={"h-screen w-full"} title="file" src={fileUrl} />
      )}
    </div>
  );
}
