import QRCode from "react-qr-code";
import { mapBankId } from "../../functions/mapBankId";
import type { TypeApplicantData } from "../../types/applicant";
import type { TypeApplication } from "../../types/application";
import { Error } from "../../types/errors";
import type { FinancialProviders } from "../../types/financialProviders";
import type { TypeSession } from "../../types/session";
import { Translation } from "../../types/taskProps";
import { Applicant } from "./components/Applicant";
import { Application } from "./components/Application";
import { Errors } from "./components/Errors";
import { Modifications } from "./components/Modifications";
import { Session } from "./components/Session";

type CreditPaymentCaseSummaryProps = {
  flowData: {
    applicant: TypeApplicantData;
    session: TypeSession;
    application: TypeApplication;
    bankId: FinancialProviders;
    errors: Error[];
    storage: {
      saveState: string;
    };
    referenceId: string;
    signicat: any;
  };
  flowId: string;
  fixedT: Translation;
};

export function CreditPaymentCaseSummary({
  flowData,
  flowId,
  fixedT
}: CreditPaymentCaseSummaryProps) {
  const applicant = flowData?.applicant?.applicants?.[0];
  const session = flowData?.session;
  const application = flowData?.application;
  const bankId = flowData.bankId;
  const errors = flowData?.errors;
  const isDevOrTestEnv = !window.location.origin.includes("prod");
  const qrData = flowData?.signicat?.session?.status?.idpData?.qrData;
  return (
    <div>
      {isDevOrTestEnv && qrData ? (
        <div>
          <h1 className="font-bold text-xl">Signicat BankID QR Code</h1>
          <QRCode value={qrData} />
        </div>
      ) : null}
      <div
        className={
          "border-line grid gap-4 rounded-md border-solid border-primary-200 bg-primary-50 p-4 shadow items-center break-words text-neutral-900 font-medium text-sm"
        }
      >
        <div className="font-medium text-2xl ">{mapBankId(bankId)}</div>
        {applicant ? <Applicant applicant={applicant} fixedT={fixedT} /> : null}
        {session ? <Session session={session} fixedT={fixedT} /> : null}
        {application ? (
          <Application application={application} fixedT={fixedT} />
        ) : null}
        <Modifications
          flowId={flowId}
          pspPaymentId={flowData?.referenceId}
          fixedT={fixedT}
        />
        {errors && errors?.length > 0 && (
          <Errors flowData={flowData} fixedT={fixedT} />
        )}
      </div>
    </div>
  );
}
