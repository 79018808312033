import { FlowContext } from "../../../hooks/useFlowContext";
import { TaskProps } from "../../../types/taskProps";
import { Compliance } from "../../CaseSummary/components/creditProcessCaseSummary/compliance";
import { DecisionPanel } from "../components/DecisionPanel";
import { Layout } from "../components/Layout";
import { SanctionsDecision } from "./SanctionsDecision";

export function SanctionsHandling(props: TaskProps) {
  const { flow } = props;
  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <Compliance flow={flow} className="p-5" />
        <DecisionPanel>
          <SanctionsDecision props={props} />
        </DecisionPanel>
      </Layout>
    </FlowContext.Provider>
  );
}
