import { zodResolver } from "@hookform/resolvers/zod";
import { Spinner } from "@stacc/flow-ui-components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { classNames } from "../../utils/classNames";
import { DefaultState, getDryRunCases, getTestCaseByFlowId } from "../../utils/modifications";
import { CircleIconWithTooltip } from "../CircleIcon";
import { Header } from "../Headers";
import { CountryDisplay } from "./CountryDisplay";
import { DecisionTestingCard } from "./DecisionTestingCard";
import { DecisionTestingAmountChart, DecisionTestingResultChart } from "./DecisionTestingChart";
import { DecisionTestingDefaultCollapsible } from "./DecisionTestingDefaultCollapsible";
import { DecisionTestingSearchForm } from "./DecisionTestingSearchForm";
import { DecisionTestingStartForm } from "./DecisionTestingStartForm";
import { ProgressBar } from "./ProgressBar";

export type TestCaseForm = {
  start: Date;
  end: Date;
  country: string;
  count: number;
};

export type SearchForm = {
  flowId: string;
};

export function DecisionTesting(props: any) {
  const queryClient = useQueryClient();
  const [flowId, setFlowId] = useState(
    new URLSearchParams(window.location.search).get("flowId") || ""
  );

  const {
    isSuccess: testCaseSuccess,
    data: testCase,
    isError: testCaseIsError
  } = useQuery({
    enabled: !!flowId,
    queryKey: ["testCase", flowId],
    queryFn: () => getTestCaseByFlowId(flowId),
    retry: (failureCount, error: AxiosError) => {
      if (error?.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    refetchInterval: (data) => {
      if (!data) return false;
      if (data.data?.data?.totalOutcome) {
        return false;
      }
      return 5000;
    }
  });
  const totals = testCase?.data?.data?.totalOutcome;
  const country = testCase?.data?.data?.query?.country;
  const inputValuesLength = testCase?.data?.data?.query?.count ?? 0;

  const { isLoading: defaultIsLoading, data: defaultTotals } = useQuery({
    queryKey: ["default"],
    queryFn: () => getDryRunCases()
  });

  const methods = useForm<TestCaseForm>({
    resolver: zodResolver(
      z.object({
        start: z.any().optional(),
        end: z.any().optional(),
        country: z.coerce.string(),
        count: z.coerce
          .number()
          .nonnegative("Count must be greater than 0")
          .optional()
      })
    )
  });

  const searchMethods = useForm<SearchForm>({
    defaultValues: {
      flowId
    },
    resolver: zodResolver(
      z.object({
        flowId: z.string().uuid("Flow Id must be a valid UUID")
      })
    )
  });

  const { mutateAsync } = useMutation({
    mutationFn: (data: TestCaseForm) => {
      return axios.post("/api/flow/flow-definitions/dry-run-decision", {
        ...(data.start && { start: data.start }),
        ...(data.end && { end: data.end }),
        ...(data.country && { country: data.country }),
        ...(data.count && { count: data.count })
      });
    },
    onMutate: async () => {
      queryClient.refetchQueries(["default"]);
    },
    onSuccess: async (data) => {
      setFlowId(data?.data?.flowId ?? "");
    }
  });

  function searchOnSubmit(data: SearchForm) {
    setFlowId(data.flowId);
  }

  return (
    <main className="flex flex-col w-full min-h-screen overflow-y-scroll flex-1 gap-4  p-12 md:gap-8">
      <div className="flex flex-row justify-center">
        <div
          className="flex flex-col flex-grow 2xl:max-w-7xl justify-center gap-20 p-8 bg-primary-searchBoxGray border border-gray-200">
          <div className="flex justify-center">
            <h1 className="font-medium text-3xl text-primary-ferrari">
              Decision Testing
            </h1>
          </div>
          <div className="flex flex-row md:flex-col xl:px-56 lg:px-32 md:px-10 gap-10">
            <DecisionTestingStartForm
              methods={methods}
              label={"Start a new decision test run"}
              onSubmit={mutateAsync}
            />
            <DecisionTestingSearchForm
              methods={searchMethods}
              onSubmit={searchOnSubmit}
            />
          </div>
          <div
            className={classNames(!!flowId ? "min-h-decision-lg" : "hidden")}
          >
            {testCaseSuccess && inputValuesLength > 0 ? (
              renderDryRun()
            ) : !!totals ? (
              error("NO APPLICATIONS FOUND DURING THIS PERIOD")
            ) : testCaseIsError ? (
              error("INVALID DRY-RUN ID")
            ) : (
              <div className="w-full flex justify-center items-center h-full">
                <Spinner size={50} />{" "}
              </div>
            )}
          </div>

          {defaultIsLoading ? (
            <div className="w-full flex justify-center">
              <Spinner size={50} />
            </div>
          ) : defaultTotals && defaultTotals.length > 0 ? (
            <div className="w-full grid gap-2">
              <div className="border-b-2 border-gray-400 text-sea-sea">
                <Header>{`Last ${defaultTotals.length} results`}</Header>
              </div>
              <div className="grid grid-cols-1 gap-1">
                {defaultTotals.map(
                  (defaultData: DefaultState, index: number) => (
                    <DecisionTestingDefaultCollapsible
                      key={index}
                      title={new Date(defaultData.createdAt).toLocaleString()}
                      data={defaultData.data}
                      flowId={defaultData.flowId}
                    />
                  )
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </main>
  );

  function renderDryRun() {
    return (
      <div className="flex flex-col gap-7">
        {!!totals ? null : <ProgressBar count={inputValuesLength} />}
        <div className="flex flex-row gap-3 items-center border-b-2  border-sea-sea">
          <div className="text-2xl">
            <CountryDisplay country={country} />
          </div>
          <div className="h-5 w-5">
            <CircleIconWithTooltip
              count={totals?.totals?.numberOfApplications}
              label="Total number of applications used for this test run."
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="grid gap-6 grid-rows-2 items-center">
            <div className="grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 lg:h-60 h-96 gap-5 lg:gap-10">
              <DecisionTestingResultChart
                totals={totals}
                headingTitle={"Application Results (%)"}
              />
              <DecisionTestingAmountChart
                totals={totals}
                headingTitle={"Total Approved Credit"}
              />
            </div>
            <div className="grid gap-6 lg:grid-cols-3 md:grid-cols-2 shrink">
              <DecisionTestingCard
                title={"Existing"}
                data={totals?.existing}
                isExisting={true}
              />
              <DecisionTestingCard title={"Live"} data={totals?.live} />
              <DecisionTestingCard title={"Dry Run"} data={totals?.dryRun} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function error(label: string) {
    return (
      <div className="h-full flex justify-center items-center text-red-500">
        {label}
      </div>
    );
  }
}
