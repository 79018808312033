import { useFlowContext } from "../../hooks/useFlowContext";
import { Card, Color, returnColors } from "../Card";
import { ClipboardIcon } from "../icons/Clipboard";
import { FileRow } from "./FileRow";

export function DocumentsCard({
  title,
  data,
  color
}: {
  title: string;
  data: any[];
  color: Color;
}) {
  const { t } = useFlowContext();
  const normalData = data.filter((data) => !data.result);
  return (
    <Card>
      <Card.Header className={`${returnColors(color)} justify-between`}>
        <div className="flex items-center gap-3">
          <ClipboardIcon width={18} height={18} />
          <span>{title}</span>
        </div>
        <span>{data.length}</span>
      </Card.Header>
      <Card.Content horizontalPadding={false}>
        {data?.length > 0 ? (
          <table className="w-full text-basic-ink">
            <thead>
              <tr className="w-full">
                <th className="pb-2 pl-5 text-left text-xs">
                  {t("type").toUpperCase()}
                </th>
                <th className="pb-2 text-left text-xs">
                  {t("uploadedBy").toUpperCase()}
                </th>
                <th className="pb-2 text-left text-xs">
                  {t("fileName").toUpperCase()}
                </th>
                <th className="pb-2 text-right text-xs">
                  {t("uploaded").toUpperCase()}
                </th>
              </tr>
            </thead>
            <tbody>
              {(normalData || []).map((row) => (
                <FileRow
                  key={row.attachmentId ?? row.attachmentKey ?? row.fileName}
                  data={row}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyState />
        )}
      </Card.Content>
    </Card>
  );
}

function EmptyState() {
  const { t } = useFlowContext();
  return (
    <div className="flex flex-col gap-5 pt-4 text-center font-bold text-black">
      <div className="flex justify-center">
        <ClipboardIcon />
      </div>
      <span>{t("noDocuments")}</span>
    </div>
  );
}
