import axios from "axios";
import { getToken } from "./sessionStorage";

export async function fetchAttachmentsByFlowId(flowId: string) {
  return axios
    .get(`/api/flow/flows/${flowId}/attachments`)
    .then((response) => response.data);
}

export async function fetchAttachmentByKey(
  flowId: string,
  attachmentKey: string
) {
  return axios
    .get(`/api/flow/flows/${flowId}/attachments/${attachmentKey}`)
    .then((response) => response.data);
}

export async function downloadAttachment(
  flowId: string,
  attachmentKey: string,
  mimeType: string
) {
  const { data } = await axios.get(
    `/api/flow/flows/${flowId}/attachments/${attachmentKey}/download`,
    {
      responseType: "blob"
    }
  );
  const file = new Blob([data], { type: mimeType });

  return URL.createObjectURL(file);
}

export async function getSigningRightsFile(
  flowId: string,
  blobName: string,
  mimeType: string
) {
  const token = getToken();
  const url = `/api/files/${flowId}?blobName=${blobName}`;
  const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    responseType: "blob"
  });
  const blob = new Blob([data], { type: mimeType });
  return URL.createObjectURL(blob);
}
