export const findDateString = (date: Date) => {
  const currentMonth = findMonth(date.getMonth() + 1);
  const dayName = findDayName(date.getDay());
  return `${dayName}, ${date.getDate()}. ${currentMonth}`;
};

export const findDayName = (day: number) => {
  switch (day) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return "Sunday";
  }
};
export const findTimeOfDay = (date: Date) => {
  const hour = date.getHours();
  if (hour < 12) {
    return "Morning";
  } else if (hour < 18) {
    return "Afternoon";
  } else {
    return "Evening";
  }
};

export const findMonth = (month: number) => {
  switch (month) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
    default:
      return "January";
  }
};

export const displaySVG = (timeOfDay: string) => {
  switch (timeOfDay) {
    case "Morning":
      return "undraw-morning.svg";
    case "Afternoon":
      return "undraw-afternoon.svg";
    case "Evening":
      return "undraw-evening.svg";
    default:
      return "undraw-evening.svg";
  }
};
