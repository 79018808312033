import { useFlowContext } from "../../../../../../hooks/useFlowContext";
import { Matches } from "../../../../../../types/creditProcess/opensanctions/response";
import { NoRemarks } from "../../../../../NoRemarks";
import { SanctionsApplicant } from "./SanctionsApplicant";
import { SanctionsCompany } from "./SanctionsCompany";

export function Sanctions({ matches }: { matches?: Matches }) {
  const { t } = useFlowContext();

  return (
    <div>
      {!matches || matches?.length === 0 ? (
        <NoRemarks label={t("no-sanctions")} />
      ) : (
        <div className="flex flex-col gap-10 max-w-4xl">
          {matches.map((match, index) => {
            if (match.type.toLowerCase().includes("company")) {
              return <SanctionsCompany key={index} data={match} />;
            } else if (
              match.type.toLowerCase().includes("person") ||
              match.type.toLowerCase().includes("applicant")
            ) {
              return <SanctionsApplicant key={index} data={match} />;
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
}
