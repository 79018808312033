export function MainLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0.5 4.6 577 207.8"
      width={160}
      height={50}
      className="py-1"
    >
      <linearGradient
        id="a"
        x1="468"
        x2="546"
        y1="197.3"
        y2="120.3"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".3" stopColor="#95c93d" />
        <stop offset=".3" stopColor="#91c53e" />
        <stop offset=".5" stopColor="#519846" />
        <stop offset=".7" stopColor="#0a7f48" />
        <stop offset=".9" stopColor="#007248" />
        <stop offset="1" stopColor="#006e47" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="471"
        x2="580"
        y1="23"
        y2="129"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#006e47" />
        <stop offset=".1" stopColor="#007047" />
        <stop offset=".2" stopColor="#007848" />
        <stop offset=".4" stopColor="#258548" />
        <stop offset=".5" stopColor="#559a46" />
        <stop offset=".7" stopColor="#85bb3f" />
        <stop offset=".7" stopColor="#95c93d" />
      </linearGradient>
      <path
        fill="#11175e"
        d="M466 96.1v-.2c0-16.8-11.9-28.4-31.5-28.4H410c-5 0-9 4-9 9v73.1h9.2c4.8 0 8.9-3.5 8.9-8V83.9h16.4c7.6.7 12.3 5 12.3 12.3v.2c0 6.5-4.5 11.6-12.3 12.4h-.2c-3.1 0-5.9 1.3-7.9 3.4-1.8 1.9-2.9 4.6-2.9 7.4v5.3h8.2l4.1-.2c16.4-1.3 29.2-11 29.2-28.6m-296.6 45.5c0 4.4-4 8-8.9 8h-9V76.4c0-4.9 3.8-8.8 8.6-8.9h9.3zm50.2-59.8l-25.9 26.7 25.9 26.7c3.5 3.5 3.5 8.9.2 12.5l-.3.2c-3.5 3.3-9 3.2-12.4-.2L170 108.6l37.1-39.2c3.5-3.4 8.9-3.5 12.4-.2l.3.2c3.3 3.5 3.2 9-.2 12.4m63.2 39.4V67.5h-9.3c-4.9.1-8.8 4.1-8.8 9v43.9c0 9.5-4.2 13.6-11.3 13.6-4.1 0-7.3-1.4-10.9-4.1-1.8-1.3-3.2-1.9-5.4-1.9-4.8 0-8.6 3.9-8.6 8.7 0 2.8 1.5 5.5 3.4 6.9 5.2 4 12.3 7.2 21.7 7.2 8.9 0 16.3-2.6 21.4-7.6 5-5 7.8-12.3 7.8-22M138.8 133.1H97.1V67.4h-9.3c-4.9.1-8.8 4.1-8.8 9v73.1h51.5c4.5 0 8.2-3.5 8.2-8zM18.5 133.1h45.3v8.4c0 4.5-3.6 8-8.1 8H.5V76.4c0-5 4-9 9-9h54.2v8.1c0 4.5-3.6 8-8.1 8H18.5v16.7h31.3c4.5 0 8.1 3.6 8.1 8.1s-3.6 8-8.1 8H18.5zM318.7 118.3c-1.3-3.2-1.9-6.6-1.9-10.1s.7-6.8 1.9-10c1.3-3.3 3.3-6.3 5.9-8.8 2.5-2.5 5.5-4.5 8.8-5.9 3.2-1.3 6.6-1.9 10-1.9 3.5 0 6.9.7 10 1.9 1 .4 2 .6 3 .6 2.3 0 4.3-.9 5.8-2.5l7.1-7c-3.2-2.6-6.2-4.4-9.1-5.8-.3-.1-.5-.3-.8-.4-5.1-2.1-10.6-3.1-16.1-3.1-5.6 0-11 1.1-16.1 3.1-5.3 2.2-10.1 5.3-14.2 9.4s-7.3 8.9-9.4 14.2c-2.1 5.1-3.1 10.6-3.1 16.1 0 5.6 1.1 11 3.1 16.1.2.4.6 1.2.8 1.8 1.5 2.6 3 4.9 5.3 8 0 0 4.7-4.6 6.4-6.4 1.1-.8 1.9-1.9 2.5-3.2.9-1.9.9-4.1.1-6.1M383 92c-1.1-2.4-2.4-4.8-4-7-.1-.2-2.1-2.8-2-2.9l-7 7.1c-1.6 1.5-2.5 3.6-2.5 5.8 0 1 .2 2.1.6 3 1.3 3.2 1.9 6.6 1.9 10 0 3.5-.7 6.9-1.9 10-1.3 3.3-3.3 6.3-5.9 8.8-2.5 2.5-5.5 4.5-8.8 5.9-3.2 1.3-6.6 1.9-10 1.9-3.5 0-6.9-.7-10.1-1.9-2-.8-4.2-.8-6.2 0-1.3.5-2.4 1.4-3.2 2.4-1.6 1.5-6.6 6.6-6.6 6.6 3.4 2.7 6.5 4.5 9.5 5.8 0 0 .3.1.4.2 5.1 2.1 10.6 3.1 16.1 3.1 5.6 0 11-1.1 16.1-3.1 5.3-2.2 10.1-5.3 14.2-9.4s7.3-8.9 9.4-14.2c2.1-5.1 3.1-10.6 3.1-16.1.2-5.6-.9-11-3.1-16"
      />
      <path
        fill="url(#a)"
        d="M524.1 122.2L500 147.8c-14.6 14.6-17.1 33.2-17.1 40.4-.1.7-.1 1.4-.1 2.2v.2c0 6.1 1.4 14 2.2 16.2l1.8 5.6 50.9-51.7c7.2-7.2 10.7-17.1 9.8-27.2-.8-7.8-4.2-15.2-9.8-20.7l-6.3-6.3-2.7-2.8c.1.3 2.6 9.8-4.6 18.5"
      />
      <path
        fill="url(#b)"
        d="M485.2 10c0 .2-4 15 1.2 33.4 4.2 14.8 16.3 28.5 16.3 28.5l50.2 50.4c8.5 8.5 6.1 19.2 5.9 19.5l2.2-2.5 6.5-6.3c5.6-5.6 9-12.9 9.8-20.7.1-1.1.2-2.1.2-3.1v-.3c0-8.9-3.6-17.4-9.9-23.8L486.4 4.6z"
      />
    </svg>
  );
}
