import { ContactRound, Users } from "lucide-react";
import { useFlowContext } from "../../../../../hooks/useFlowContext";
import { TaskProps } from "../../../../../types/taskProps";
import { COLOR, Card } from "../../../../Card";
import {
  Table,
  TableBody,
  TableCell,
  TableCellComment,
  TableCellTitle,
  TableHead,
  TableHeader,
  TableRow
} from "../../../../table/index";
import { getManagementTableData } from "./getManagementTableData";

export function Management(flow: TaskProps["flow"]) {
  const { t } = useFlowContext();
  const { data } = flow;
  const { country } = data?.applicant;
  const management =
    data?.raw?.dunAndBradstreet?.companyRiskAndCreditData?.management;
  if (!management || !country) {
    return null;
  }
  const { currentBoardMembers, historicalBoardMembers } =
    getManagementTableData({
      country,
      data: management
    }) ?? {};

  return (
    <div className="overflow-auto">
      <div className="flex flex-col gap-10">
        {currentBoardMembers && (
          <Card>
            <Card.Header color={COLOR.BLUE}>
              <Card.HeaderTitle>
                <Users />
                {t("currentBoard")}
              </Card.HeaderTitle>
            </Card.Header>
            <Card.Content horizontalPadding={false} className="px-5">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t("name")}</TableHead>
                    <TableHead className="px-2">{t("role")}</TableHead>
                    <TableHead className="pl-2">{t("birthDate")}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {currentBoardMembers.map((member) => (
                    <TableRow
                      key={`${member?.name}-${member?.birthDate}`}
                      className="border-0"
                    >
                      <TableCellTitle>
                        {member?.name ?? t("N/A")}
                      </TableCellTitle>
                      <TableCell className="px-2">
                        {member?.role ?? t("N/A")}
                      </TableCell>
                      <TableCellComment>
                        {member?.birthDate ?? t("N/A")}
                      </TableCellComment>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card.Content>
          </Card>
        )}
        {historicalBoardMembers && (
          <Card>
            <Card.Header color={COLOR.YELLOW}>
              <Card.HeaderTitle>
                <ContactRound />
                {t("historicalBoard")}
              </Card.HeaderTitle>
            </Card.Header>
            <Card.Content horizontalPadding={false} className="px-5">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t("name")}</TableHead>
                    <TableHead className="px-2">{t("role")}</TableHead>
                    <TableHead className="pl-2">{t("birthDate")}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {historicalBoardMembers.map((member) => (
                    <TableRow
                      key={`${member?.name}-${member?.birthDate}`}
                      className="border-0"
                    >
                      <TableCellTitle>
                        {member?.name ?? t("N/A")}
                      </TableCellTitle>
                      <TableCell className="px-2">
                        {member?.role ?? t("N/A")}
                      </TableCell>
                      <TableCellComment>
                        {member?.birthDate ?? t("N/A")}
                      </TableCellComment>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card.Content>
          </Card>
        )}
      </div>
    </div>
  );
}
