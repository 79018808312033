import { ChartNoAxesCombined, Coins, Landmark } from "lucide-react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { useFlowContext } from "../../../../../hooks/useFlowContext";
import { TaskProps } from "../../../../../types/taskProps";
import { getChartDataHelper } from "../../../../../utils/chartData";
import { classNames } from "../../../../../utils/classNames";
import { getFinancialStatementsDataHelper } from "../../../../../utils/financialHistoryData";
import { COLOR, Card } from "../../../../Card";
import { NoRemarks } from "../../../../NoRemarks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "../../../../table";

export function FinancialStatements(flow: TaskProps["flow"]) {
  const { t } = useFlowContext();
  const { data } = flow;
  const { country } = data.applicant;
  const { financialStatements } =
    data?.raw?.dunAndBradstreet?.companyRiskAndCreditData?.finance
      ?.financialStatements ?? {};
  if (!financialStatements || financialStatements?.length === 0) {
    return <NoRemarks label={t("no-financial-data")} isSuccess={false} />;
  }

  if (!country) {
    return (
      <NoRemarks
        label={t("could-not-fetch-data-without-country")}
        isSuccess={false}
      />
    );
  }

  const financialHistoryData = getFinancialStatementsDataHelper(
    financialStatements,
    country
  );

  if (!financialHistoryData || financialHistoryData.length === 0) {
    return <NoRemarks label={t("no-financial-data")} isSuccess={false} />;
  }
  const length = financialHistoryData.length;

  const chartData = getChartDataHelper(data, country);
  const chartNames = chartData && Object.keys(chartData[0]);

  return (
    <div className="flex h-full w-full flex-col gap-10">
      {chartNames && (
        <div className="grid h-60 grid-cols-2">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 5,
                right: 12,
                left: 35,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 4" vertical={false} />
              <XAxis
                dataKey={chartNames[0]}
                tickLine={false}
                axisLine={false}
                tickMargin={10}
              />
              <YAxis
                tickFormatter={(tick) => {
                  return tick.toLocaleString();
                }}
                className="text-sm"
              />
              <Tooltip />
              <Legend />
              <ReferenceLine y={0} stroke="#696969" />
              <Bar
                dataKey={chartNames[1]}
                name={t(chartNames[1])}
                fill="#2563eb"
                radius={5}
              />
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={400}
              data={chartData}
              margin={{
                top: 5,
                right: 12,
                left: 35,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey={chartNames[0]}
                tickLine={false}
                axisLine={false}
                tickMargin={10}
                padding={{ left: 15, right: 15 }}
              />
              <YAxis
                tickFormatter={(tick) => {
                  return tick.toLocaleString();
                }}
                className="text-sm"
              />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey={chartNames[2]}
                stroke="#2563eb"
                fill="#4595f8"
                name={t(chartNames[2])}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
      <div
        className={classNames(
          "flex flex-col gap-5 overflow-auto pb-10",
          length > 2 ? "w-full" : "max-w-2xl"
        )}
      >
        <Card>
          <Card.Header color={COLOR.GREEN}>
            <Card.HeaderTitle>
              <Coins />
              {t("income")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content horizontalPadding={false} className="px-5">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="sticky left-0 z-10 bg-white"></TableHead>
                  {financialHistoryData.map((yearData) => (
                    <TableHead key={yearData.year} className="text-right">
                      {yearData.year}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {financialHistoryData[0].data.income.map((pair) => (
                  <TableRow key={pair.key} className="border-0">
                    <TableCell className="sticky left-0 z-10 whitespace-nowrap bg-white py-2 font-medium text-gray-700">
                      {pair.key}
                    </TableCell>
                    {financialHistoryData.map((yearData) => {
                      const foundValue = yearData.data.income.find(
                        (item) => item.key === pair.key
                      )?.value;
                      return (
                        <TableCell
                          key={`${yearData.year}-${pair.key}`}
                          className="pl-10 text-right text-gray-600"
                        >
                          {foundValue ? foundValue : t("N/A")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card.Content>
        </Card>

        <Card>
          <Card.Header color={COLOR.BLUE}>
            <Card.HeaderTitle>
              <Landmark />
              {t("assets")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content
            horizontalPadding={false}
            className="flex flex-col gap-5 px-5"
          >
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="sticky left-0 z-10 bg-white">
                    {t("current")}
                  </TableHead>
                  {financialHistoryData.map((yearData) => (
                    <TableHead key={yearData.year} className="text-right">
                      {yearData.year}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {financialHistoryData[0].data.currentAssets.map((pair) => (
                  <TableRow key={pair.key} className="border-0">
                    <TableCell className="sticky left-0 z-10 whitespace-nowrap bg-white py-2 pr-2 font-medium text-gray-700">
                      {pair.key}
                    </TableCell>
                    {financialHistoryData.map((yearData) => {
                      const foundValue = yearData.data.currentAssets.find(
                        (item) => item.key === pair.key
                      )?.value;
                      return (
                        <TableCell
                          key={`${yearData.year}-${pair.key}`}
                          className="pl-10 text-right text-gray-600"
                        >
                          {foundValue ? foundValue : t("N/A")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
                <TableRow className="border-0">
                  <TableCell className="text-transparent">
                    Non current
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableHead className="sticky left-0 z-10 bg-white">
                    {t("nonCurrent")}
                  </TableHead>
                </TableRow>

                {financialHistoryData[0].data.nonCurrentAssets.map((pair) => (
                  <TableRow key={pair.key} className="border-0">
                    <TableCell className="sticky left-0 z-10 bg-white py-2 pr-2 font-medium text-gray-700">
                      {pair.key}
                    </TableCell>
                    {financialHistoryData.map((yearData) => {
                      const foundValue = yearData.data.nonCurrentAssets.find(
                        (item) => item.key === pair.key
                      )?.value;
                      return (
                        <TableCell
                          key={`${yearData.year}-${pair.key}`}
                          className="pl-2 text-right text-gray-600"
                        >
                          {foundValue ? foundValue : t("N/A")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
                <TableRow>
                  <TableHead />
                </TableRow>
                {financialHistoryData[0].data.totalAssets.map((pair) => (
                  <TableRow key={pair.key} className="border-0">
                    <TableHead className="sticky left-0 z-10 bg-white py-2 pr-2">
                      {pair.key}
                    </TableHead>
                    {financialHistoryData.map((yearData) => {
                      const foundValue = yearData.data.totalAssets.find(
                        (item) => item.key === pair.key
                      )?.value;
                      return (
                        <TableCell
                          key={`${yearData.year}-${pair.key}`}
                          className="pl-2 text-right text-gray-600"
                        >
                          {foundValue ? foundValue : t("N/A")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card.Content>
        </Card>

        <Card>
          <Card.Header color={COLOR.YELLOW}>
            <Card.HeaderTitle>
              <ChartNoAxesCombined />
              {t("equityAndLiabilities")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content
            horizontalPadding={false}
            className="flex flex-col gap-5 px-5"
          >
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="sticky left-0 z-10 bg-white">
                    {t("equity")}
                  </TableHead>
                  {financialHistoryData.map((yearData) => (
                    <TableHead key={yearData.year} className="text-right">
                      {yearData.year}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {financialHistoryData[0].data.shareholderEquity.map((pair) => (
                  <TableRow key={pair.key} className="border-0">
                    <TableCell className="sticky left-0 z-10 whitespace-nowrap bg-white py-2 pr-2 font-medium text-gray-700">
                      {pair.key}
                    </TableCell>
                    {financialHistoryData.map((yearData) => {
                      const foundValue = yearData.data.shareholderEquity.find(
                        (item) => item.key === pair.key
                      )?.value;
                      return (
                        <TableCell
                          key={`${yearData.year}-${pair.key}`}
                          className="pl-10 text-right text-gray-600"
                        >
                          {foundValue ? foundValue : t("N/A")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
                <TableRow className="border-0">
                  <TableCell className="text-transparent">
                    Non current Liabilities
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableHead className="sticky left-0 z-10 bg-white">
                    {t("liabilities")}
                  </TableHead>
                </TableRow>
                {financialHistoryData[0].data.currentLiabilities.map((pair) => (
                  <TableRow key={pair.key} className="border-0">
                    <TableCell className="sticky left-0 z-10 bg-white py-2 pr-2 font-medium text-gray-700">
                      {pair.key}
                    </TableCell>
                    {financialHistoryData.map((yearData) => {
                      const foundValue = yearData.data.currentLiabilities.find(
                        (item) => item.key === pair.key
                      )?.value;
                      return (
                        <TableCell
                          key={`${yearData.year}-${pair.key}`}
                          className="pl-2 text-right text-gray-600"
                        >
                          {foundValue ? foundValue : t("N/A")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
                {financialHistoryData[0].data.longTermLiabilities.map(
                  (pair) => (
                    <TableRow key={pair.key} className="border-0">
                      <TableCell className="sticky left-0 z-10 bg-white py-2 pr-2 font-medium text-gray-700">
                        {pair.key}
                      </TableCell>
                      {financialHistoryData.map((yearData) => {
                        const foundValue =
                          yearData.data.longTermLiabilities.find(
                            (item) => item.key === pair.key
                          )?.value;
                        return (
                          <TableCell
                            key={`${yearData.year}-${pair.key}`}
                            className="pl-2 text-right text-gray-600"
                          >
                            {foundValue ? foundValue : t("N/A")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )
                )}
                {financialHistoryData[0].data.totalLiabilities.map((pair) => (
                  <TableRow key={pair.key} className="border-0">
                    <TableCell className="sticky left-0 z-10 bg-white py-2 pr-2 font-medium text-gray-700">
                      {pair.key}
                    </TableCell>
                    {financialHistoryData.map((yearData) => {
                      const foundValue = yearData.data.totalLiabilities.find(
                        (item) => item.key === pair.key
                      )?.value;
                      return (
                        <TableCell
                          key={`${yearData.year}-${pair.key}`}
                          className="pl-2 text-right text-gray-600"
                        >
                          {foundValue ? foundValue : t("N/A")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
                <TableRow>
                  <TableHead />
                </TableRow>
                {financialHistoryData[0].data.totalEquityAndLiabilities.map(
                  (pair) => (
                    <TableRow key={pair.key} className="border-0">
                      <TableHead className="sticky left-0 z-10 bg-white py-2 pr-2">
                        {pair.key}
                      </TableHead>
                      {financialHistoryData.map((yearData) => {
                        const foundValue =
                          yearData.data.totalEquityAndLiabilities.find(
                            (item) => item.key === pair.key
                          )?.value;
                        return (
                          <TableCell
                            key={`${yearData.year}-${pair.key}`}
                            className="pl-2 text-right text-gray-600"
                          >
                            {foundValue ? foundValue : t("N/A")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </Card.Content>
        </Card>
      </div>
    </div>
  );
}
