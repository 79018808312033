import { Country, COUNTRY, COUNTRY_CODE } from "../types/creditProcess/enums";
import { CreditProcessState } from "../types/creditProcess/state";
import { B2BResponseModel } from "../types/d&b/b2b";

export function getManualHandlingTabsHelper(
  data: CreditProcessState,
  country: Country | undefined
) {
  switch (country) {
    case COUNTRY.NORWAY:
      return getNorwegianTabsCount(data);
    case COUNTRY.SWEDEN:
      return getSwedishTabsCount(data);
    case COUNTRY.FINLAND:
      return getFinnishTabsCount(data);
    case COUNTRY.DENMARK:
      return getDanishTabsCount(data);
    default:
      return undefined;
  }
}

function hasUploadedSigningRightsFile(data: CreditProcessState) {
  const { fileName } = data?.signingRightsFile ?? {};
  return !!fileName ? 1 : 0;
}

function getNorwegianTabsCount(data: CreditProcessState) {
  const { risk, finance, events } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.NORWAY
    >) ?? {};
  const length = finance?.financialStatements?.financialStatements?.length ?? 0;
  const { paymentRemarksGroups } = risk?.paymentRemarks ?? {};
  const { matches } = data?.sanctions ?? {};
  let sanctionsCount = 0;
  if (matches !== undefined && matches !== null) {
    sanctionsCount = Object.keys(matches).length;
  }

  let paymentRemarksLength = 0;
  paymentRemarksGroups?.forEach((remarksList) => {
    paymentRemarksLength += Object.values(remarksList)[0].length;
  });

  const eventsLength = events?.length ?? 0;

  return {
    financialStatementsLength: length,
    paymentRemarksLength,
    sanctionsCount,
    eventsLength,
    hasUploadedSigningRightsFile: hasUploadedSigningRightsFile(data)
  };
}

function getSwedishTabsCount(data: CreditProcessState) {
  const { risk, finance, events } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.SWEDEN
    >) ?? {};
  const length = finance?.financialStatements?.financialStatements?.length ?? 0;
  const { count } = risk?.paymentRemarks ?? {};
  const { matches } = data?.sanctions ?? {};
  let sanctionsCount = 0;
  if (matches !== undefined && matches !== null) {
    sanctionsCount = Object.keys(matches).length;
  }

  const eventsLength = events?.length ?? 0;

  return {
    financialStatementsLength: length,
    paymentRemarksLength: count,
    sanctionsCount,
    eventsLength,
    hasUploadedSigningRightsFile: hasUploadedSigningRightsFile(data)
  };
}

function getFinnishTabsCount(data: CreditProcessState) {
  const { risk, finance, events } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.FINLAND
    >) ?? {};
  const length = finance?.financialStatements?.financialStatements?.length ?? 0;
  const { count } = risk?.paymentRemarks ?? {};
  const { matches } = data?.sanctions ?? {};
  let sanctionsCount = 0;
  if (matches !== undefined && matches !== null) {
    sanctionsCount = Object.keys(matches).length;
  }

  const eventsLength = events?.length ?? 0;

  return {
    financialStatementsLength: length,
    paymentRemarksLength: count,
    sanctionsCount,
    eventsLength,
    hasUploadedSigningRightsFile: hasUploadedSigningRightsFile(data)
  };
}

function getDanishTabsCount(data: CreditProcessState) {
  const { risk, finance, events } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.DENMARK
    >) ?? {};
  const length = finance?.financialStatements?.financialStatements?.length ?? 0;
  const { count } = risk?.paymentRemarks ?? {};
  const { matches } = data?.sanctions ?? {};
  let sanctionsCount = 0;
  if (matches !== undefined && matches !== null) {
    sanctionsCount = Object.keys(matches).length;
  }

  const eventsLength = events?.length ?? 0;

  return {
    financialStatementsLength: length,
    paymentRemarksLength: count,
    sanctionsCount,
    eventsLength,
    hasUploadedSigningRightsFile: hasUploadedSigningRightsFile(data)
  };
}
