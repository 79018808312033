import { zodResolver } from "@hookform/resolvers/zod";
import {
  CenteredSpinner,
  Colors,
  PrimaryButton
} from "@stacc/flow-ui-components";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { CountryChooser } from "../inputs/CountryChooser";
import { Email } from "../inputs/Email";
import { OrganizationNumber } from "../inputs/OrganizationNumber";
import { creditScoreSchema } from "./reports.schema";

export function Reports() {
  const { isLoading, mutateAsync, isSuccess, data, variables } = useMutation({
    mutationFn: (data: any) => {
      return axios.post(
        `/api/flow/flow-definitions/credit-score-b2b-${data.country}`,
        {
          customerType: "b2b",
          organizationNumber: data.organizationNumber,
          reportEmail: data.email
        }
      );
    }
  });

  const methods = useForm({
    defaultValues: {
      country: "norway",
      organizationNumber: "",
      email: ""
    },
    resolver: zodResolver(creditScoreSchema)
  });

  function onSubmit(data: any) {
    mutateAsync(data);
    methods.reset();
  }

  return (
    <main className="flex flex-col w-full min-h-screen overflow-y-scroll flex-1 gap-4 p-12 md:gap-8">
      <div className="flex flex-row justify-center">
        <div className="flex flex-col flex-grow 2xl:max-w-7xl xl:px-56 lg:px-32 justify-center gap-10 p-8 bg-primary-searchBoxGray border border-gray-200">
          <div className="flex justify-center">
            <h1 className="font-medium text-3xl text-primary-ferrari">
              Reports
            </h1>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-2 max-w-md mx-auto">
                <Email
                  id="email"
                  label="E-mail (Where you want to receive the results)"
                />
                <CountryChooser id="country" label="Country" />
                <OrganizationNumber
                  id="organizationNumber"
                  label="Organization number"
                />
                <PrimaryButton type="submit" size="large" disabled={isLoading}>
                  {isLoading ? (
                    <CenteredSpinner color={Colors.Snow} size={20} />
                  ) : (
                    "Start"
                  )}
                </PrimaryButton>
              </div>
            </form>
          </FormProvider>
          {isSuccess && (
            <div className="mx-auto max-w-md">
              <h2 className="font-medium text-xl text-primary-ferrari">
                Successfully started credit scoring
              </h2>
              <p>
                You will receive an email with the results of the credit
                scoring. This can take up to a few minutes to complete.
              </p>
              {variables?.country && data?.data?.flowId && (
                <Link
                  to={`/cases/case/credit-score-b2b-${variables.country}/${data.data.flowId}`}
                  className="text-blue-500 underline hover:text-blue-700 transition duration-300"
                >
                  Go to result
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
