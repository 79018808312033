import { Card } from "../Card";
import { TestRunStats } from "../../utils/modifications";
import { Skeleton } from "./Skeleton";

export function DecisionTestingCard({
  title,
  data,
  isExisting
}: {
  title: string;
  data: TestRunStats | undefined;
  isExisting?: boolean;
}) {
  return (
    <div className="">
      <Card>
        <Card.Header className="bg-primary-200 justify-between">
          {data !== undefined ? (
            title
          ) : (
            <Skeleton className="h-5 w-20 rounded-xl" />
          )}
        </Card.Header>
        <Card.Content className="flex flex-col gap-1">
          <CardData
            label="Total approved credit"
            value={data?.totalApprovedCredit}
          />
          <CardData
            label="Approved applications"
            value={data?.percentApprovedApplications}
            isPercent={true}
          />
          <CardData
            label="Manual handling"
            value={data?.percentManualHandling}
            isPercent={true}
          />
          <CardData
            label="Rejected"
            value={data?.percentRejectedAppications}
            isPercent={true}
          />
          {isExisting ? (
            <div className="h-6"></div>
          ) : (
            <CardData
              label="Failed scoring"
              value={data?.percentFailedScoring}
              isPercent={true}
            />
          )}
        </Card.Content>
      </Card>
    </div>
  );
}

 function CardData({
  label,
  value,
  isPercent
}: {
  label: string;
  value: number | undefined;
  isPercent?: boolean;
}) {
  return (
    <div>
      {value !== undefined ? (
        <div className="flex justify-between">
          <span>{label}</span>
          <span className="flex flex-row items-center">
            {value}
            {isPercent ? "%" : ""}
          </span>
        </div>
      ) : (
        <Skeleton className="h-5 w-full rounded-xl" />
      )}
    </div>
  );
}
