import { useFlowContext } from "../../../../../../hooks/useFlowContext";

export function SanctionsDisplayTopics({ topics }: { topics: string[] }) {
  const { t } = useFlowContext();
  if (!topics || topics.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-wrap gap-2">
      {topics.map((topic, index) => {
        return (
          <span
            key={`${topic}-${index}`}
            className="border-2 rounded-lg px-4 py-1 flex flex-col bg-yellow-100 border-yellow-500 text-yellow-700"
          >
            {t(topic)}
          </span>
        );
      })}
    </div>
  );
}
