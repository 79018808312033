import { zodResolver } from "@hookform/resolvers/zod";
import { PrimaryButton, SecondaryButton } from "@stacc/flow-ui-components";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { TaskProps } from "../../../types/taskProps";
import { Header } from "../../Headers";
import { Note } from "../../inputs/Note";
import { RadioButtons } from "../../inputs/RadioButtons";

interface DecisionForm {
  outcome: string;
  note: string;
}

export function EvaluatePOADocumentDecision({ props }: { props: TaskProps }) {
  const { t, complete, save, task } = props;
  const { note: savedNote } = task.data ?? {};

  const methods = useForm<DecisionForm>({
    defaultValues: {
      note: savedNote ?? ""
    },
    resolver: zodResolver(
      z.object({
        outcome: z.string(),
        note: z.string()
      })
    )
  });

  function onSubmit(data: DecisionForm) {
    complete({ ...data, outcome: data.outcome === "true" });
  }

  function onSave(data: DecisionForm) {
    save(data);
  }

  const approvedIsDirty = methods.formState.dirtyFields.outcome;
  const noteIsDirty = methods.formState.dirtyFields.note;
  return (
    <FormProvider {...methods}>
      <form>
        <div className="flex flex-col gap-5 ">
          <div className="flex flex-col gap-2">
            <Header>{t("decision")}</Header>
            <RadioButtons id="outcome" />
          </div>
          <div className="flex flex-col gap-2">
            <Header>{t("notes")}</Header>
            <Note id="note" />
          </div>
          <div className="flex gap-3">
            <PrimaryButton
              disabled={!approvedIsDirty}
              type="button"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("submit")}
            </PrimaryButton>
            <SecondaryButton
              disabled={!noteIsDirty}
              type="button"
              onClick={methods.handleSubmit(onSave)}
            >
              {t("save")}
            </SecondaryButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
