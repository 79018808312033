import { ArrowIcon } from "../icons/Arrow";

type WidgetCardProps = {
  imgSrc?: string;
  text?: string;
  alt?: string;
};

export const WidgetCard = ({ imgSrc, text = "", alt }: WidgetCardProps) => {
  return (
    <div className="relative transform transition ease-in-out hover:scale-105 hover:shadow-2xl text-white shadow-lg rounded-md flex flex-col justify-end h-36 w-64">
      <div className="absolute blackToTransparentGradient rounded-md top-0 left-0 w-full h-full" />
      <img alt={alt} className="rounded-md  h-full w-full" src={imgSrc} />
      <div className="flex absolute bottom-2 left-0 px-4 w-full flex-row justify-between items-center gap-6">
        <span className="font-medium">{text}</span>
        <ArrowIcon />
      </div>
    </div>
  );
};
