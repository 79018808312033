import { Handshake, User } from "lucide-react";
import QRCode from "react-qr-code";
import { getFlag } from "../..";
import { useFlowContext } from "../../../../hooks/useFlowContext";
import { CreditProcessState } from "../../../../types/creditProcess/state";
import { getOverviewHelper } from "../../../../utils/overviewData";
import { COLOR, Card } from "../../../Card";
import { Header } from "../../../Headers";
import { NoRemarks } from "../../../NoRemarks";
import { CustomTable } from "../../../table/CustomTable";
import { RejectionReasons } from "./RejectionReasons";

export function MissingDataSummary({ data }: { data: CreditProcessState }) {
  const { t } = useFlowContext();
  const { country } = data?.applicant ?? {};
  const overviewData = getOverviewHelper(data, country);
  const isDevOrTestEnv = !window.location.origin.includes("prod");

  if (!overviewData) {
    return <NoRemarks label={t("missingData")} isSuccess={false} />;
  }
  const qrData = data?.raw?.signicat?.headless?.idpData?.qrData;
  return (
    <div className="flex max-w-7xl flex-col gap-5">
      <div className="flex flex-col gap-2">
        <div className="flex gap-3">
          {getFlag(data?.applicant?.country)}
          <Header className="text-2xl my-auto">
            {t(data?.application?.type?.toLowerCase())}
          </Header>
        </div>
        <span className="text-gray-600 mb-5">
          {`Status: ${t(data?.applicationStatus)} /
              ${t(data?.businessStatus)}`}
        </span>
      </div>
      {isDevOrTestEnv && qrData ? (
        <div>
          <h1 className="text-xl font-bold">Signicat BankID QR Code</h1>
          <QRCode value={qrData} />
        </div>
      ) : null}
      <RejectionReasons decisions={data?.decisions} />
      <div className="grid grid-cols-2 gap-10">
        <Card>
          <Card.Header color={COLOR.BLUE}>
            <Card.HeaderTitle>
              <User />
              {t("companyApplicant")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content horizontalPadding={false} className="px-5">
            <CustomTable tableData={overviewData.companyData} />
          </Card.Content>
        </Card>
        <Card>
          <Card.Header color={COLOR.BLUE}>
            <Card.HeaderTitle>
              <User />
              {t("representative")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content horizontalPadding={false} className="px-5">
            <CustomTable tableData={overviewData.representativeData} />
          </Card.Content>
        </Card>
        <Card>
          <Card.Header color={COLOR.BLUE}>
            <Card.HeaderTitle>
              <Handshake />
              {t("sellerInformation")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content horizontalPadding={false} className="px-5">
            <CustomTable tableData={overviewData.sellerInfoData} />
          </Card.Content>
        </Card>
      </div>
    </div>
  );
}
