import { useRef, useEffect, useState, useCallback } from "react";
import DmnViewer from "dmn-js";
import "dmn-js/dist/assets/diagram-js.css";
import "dmn-js/dist/assets/dmn-js-shared.css";
import "dmn-js/dist/assets/dmn-js-drd.css";
import "dmn-js/dist/assets/dmn-js-decision-table.css";
import "dmn-js/dist/assets/dmn-js-decision-table-controls.css";
import "dmn-js/dist/assets/dmn-js-literal-expression.css";
import "dmn-js/dist/assets/dmn-font/css/dmn.css";
import { ToggleGroup } from "./ToggleGroup";

type DMNViewerProps = {
  dmnXML: string;
  ruleIds: Array<string>;
};
export interface DmnView {
  element: any;
  id: string;
  name: string;
  type: ViewerType;
}

type ViewerType = "drd" | "decisionTable" | "literalExpression";

export function DMNViewer({ dmnXML, ruleIds }: DMNViewerProps) {
  const dmnContainerRef = useRef(null);
  const viewerRef = useRef<any>(null);
  const [activeView, setActiveView] = useState<DmnView | undefined>(undefined);
  const [views, setViews] = useState<DmnView[]>([]);

  useEffect(() => {
    viewerRef.current = new DmnViewer({
      container: dmnContainerRef.current,
      keyboard: {
        bindTo: document
      }
    });

    async function loadXML() {
      try {
        await viewerRef.current?.importXML(dmnXML);
        const views = viewerRef.current
          ?.getViews()
          .filter((view: DmnView) => view.type !== "drd");
        if (views.length > 0) {
          viewerRef.current?.open(views[0]);
          setActiveView(views[0]);
        }
        setViews(views);
      } catch (err) {
        console.error("error rendering", err);
      }
    }
    loadXML();

    return () => {
      viewerRef.current?.destroy();
    };
  }, [dmnXML]);

  const highlightRow = (id: string) => {
    const canvasCollection = document.getElementsByClassName("tjs-table");

    for (let i = 0; i < canvasCollection.length; i++) {
      const td = canvasCollection[i].querySelector(
        `[data-row-id="${id}"]`
      ) as HTMLElement;
      if (td) {
        const tr = td.parentNode as HTMLElement;
        if (tr) {
          tr.style.backgroundColor = "#52C761";
        }
      }
    }
  };

  useEffect(() => {
    if (activeView?.type === "decisionTable") {
      ruleIds.forEach((rule) => {
        highlightRow(rule);
      });
    }
  }, [activeView, ruleIds]);

  const changeMode = useCallback(
    async (viewId: string | undefined) => {
      if (viewId && activeView?.id !== viewId) {
        const view = views.find((view) => view.id === viewId);
        setActiveView(view);
        viewerRef.current?.open(view);
      }
    },
    [activeView, views]
  );
  return (
    <div className="h-full">
      <div ref={dmnContainerRef} className="w-full mx-auto" />;
      {views.length > 1 && (
        <ToggleGroup
          options={[
            ...views.map((view) => ({
              id: view.id,
              node: (
                <>
                  <span className="dmn-icon-decision-table" />
                  <span
                    title={view.name || "Unnamed"}
                    className="max-w-32 ml-0.5 overflow-hidden text-ellipsis whitespace-nowrap"
                  >
                    {view.name || "Unnamed"}
                  </span>
                </>
              )
            }))
          ]}
          onChange={changeMode}
          active={activeView}
        />
      )}
    </div>
  );
}
