import { classNames } from "../../utils/classNames";

export function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames("animate-pulse rounded-md bg-gray-200", className)}
      {...props}
    />
  );
}
