import { Icons, PrimaryButton } from "@stacc/flow-ui-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { isAdmin, isAdminB2c } from "../../functions/isRole";
import {
  MODIFICATION_STATUS,
  TypeModifications
} from "../../types/modification";
import { Role } from "../../types/role";
import { TaskProps } from "../../types/taskProps";
import { startModification } from "../../utils/modifications";
import { Card } from "../Card";
import { Collapsible } from "../Collapsible";

interface ModificationProps {
  overview: TypeModifications;
  modifications: TypeModifications["capture"];
  props: TaskProps;
}

interface Modifications {
  type: "CAPTURE" | "REFUND" | "CANCEL";
  uniqueRequestId: string;
  paymentProvider: string;
  sourceSystem: string;
  merchantId: string | undefined;
  merchantTransactionReference: string;
  pspPaymentId: string | undefined;
  amount: { value: number; currency: string };
  retryId?: string;
  retryUser?: string;
  isRetry?: boolean;
}

export function ModificationsCard({
  overview,
  modifications,
  props
}: ModificationProps) {
  const [loading, setLoading] = useState("");
  const role = props.user?.role || Role.User;
  const queryClient = useQueryClient();

  const hasInitialized = modifications?.some(
    (modification) => modification.status === MODIFICATION_STATUS.INITIALIZED
  );
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (data: Modifications) => {
      try {
        return await startModification(data);
      } catch (error) {}
    },
    onSuccess: async () => {
      queryClient.refetchQueries(["purchase"]);
      setLoading("");
    }
  });

  return (
    <Card>
      <Card.Header className="flex space-x-3 items-center text-base font-semibold px-5 h-12 rounded-t-md bg-sea-light">
        <Card.HeaderTitle>
          Modifications: {modifications?.length}
        </Card.HeaderTitle>
      </Card.Header>
      <Card.Content className="flex flex-col gap-4">
        {modifications?.map((modification, index) => (
          <div key={index} className="border-b">
            <div className="flex items-center justify-between">
              <h1 className="text-lg font-bold text-primary-900">
                Modification {index + 1}
              </h1>
              <div className="flex items-center">
                {modification?.status === MODIFICATION_STATUS.SUCCESS && (
                  <Icons.SuccessCircle />
                )}
                {modification?.status === MODIFICATION_STATUS.INITIALIZED && (
                  <Icons.ProgressActive />
                )}
                {modification?.status === MODIFICATION_STATUS.ERROR && (
                  <Icons.ErrorCircle />
                )}
              </div>
            </div>
            <div className="py-3">
              <div className="flex justify-between">
                <span className="text-lg font-bold text-primary-900">Type</span>
                <span className="text-primary-800">{modification?.type}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-lg font-bold text-primary-900">
                  Amount
                </span>
                <span className="text-primary-800">{modification?.amount}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-lg font-bold text-primary-900">
                  Status
                </span>
                <span className="text-primary-800">{modification?.status}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-lg font-bold text-primary-900">
                  Unique Id
                </span>
                <span className="text-primary-800">
                  {modification?.uniqueRequestId}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-lg font-bold text-primary-900">
                  Trans Ref
                </span>
                <span className="text-primary-800">
                  {modification?.merchantTransactionReference}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-lg font-bold text-primary-900">
                  Created At
                </span>
                <span className="text-primary-800">
                  {modification?.createdAt}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-lg font-bold text-primary-900">
                  Updated At
                </span>
                <span className="text-primary-800">
                  {modification?.updatedAt}
                </span>
              </div>
              {modification?.retries && modification?.retries.length > 0 && (
                <>
                  <div className="flex justify-between">
                    <span className="text-lg font-bold text-primary-900">
                      Number of Retries
                    </span>
                    <span className="text-primary-800">
                      {modification?.retries?.length || 0}
                    </span>
                  </div>
                  <Collapsible>
                    <Collapsible.Title
                      title="Retries"
                      className="text-lg font-bold"
                    />
                    <Collapsible.Content>
                      <div className="flex flex-col gap-2">
                        {modification.retries.map((retry, retryIndex) => (
                          <div
                            key={retryIndex}
                            className="flex justify-between"
                          >
                            <span className="text-primary-800">
                              Retry {retryIndex + 1}
                            </span>
                            <span className="text-primary-800">
                              Retried At: {retry.retriedAt}
                            </span>
                            <span className="text-primary-800">
                              Retry User: {retry.retryUser || "N/A"}
                            </span>
                          </div>
                        ))}
                      </div>
                    </Collapsible.Content>
                  </Collapsible>
                </>
              )}
              {(isAdmin(role) || isAdminB2c(role)) &&
                modification?.status === MODIFICATION_STATUS.ERROR && (
                  <div>
                    <div className="flex justify-between py-4">
                      <PrimaryButton
                        type="submit"
                        size="large"
                        onClick={() => {
                          setLoading(modification.id);
                          const payloadToRetry = {
                            type: modification.type,
                            uniqueRequestId: uuidv4(),
                            paymentProvider: "STACC",
                            sourceSystem: "SAPCET",
                            merchantId: overview.merchantId,
                            merchantTransactionReference:
                              modification.merchantTransactionReference,
                            pspPaymentId: overview.pspPaymentId,
                            retryId: modification.id,
                            retryUser: props.user?.username,
                            amount: {
                              value: modification.amount * 100,
                              currency: overview.currency
                            }
                          };
                          mutateAsync(payloadToRetry);
                        }}
                        disabled={isLoading || hasInitialized}
                        className={isLoading}
                      >
                        {isLoading && loading === modification.id
                          ? "Retrying..."
                          : "Retry"}
                      </PrimaryButton>
                    </div>
                  </div>
                )}
              {modification?.status === MODIFICATION_STATUS.ERROR && (
                <Collapsible className="text-red-700">
                  <Collapsible.Title
                    title="Error"
                    className="text-lg font-bold"
                  />
                  <Collapsible.Content>
                    <Error error={modification.error!} />
                  </Collapsible.Content>
                </Collapsible>
              )}
            </div>
          </div>
        ))}
      </Card.Content>
    </Card>
  );
}

function Error({ error }: { error: string }) {
  try {
    const errorObject = JSON.parse(error);
    return (
      <div className="h-full flex flex-col  text-red-500">
        {Object.entries(errorObject).map(([key, value], index) => (
          <div key={index} className="flex justify-between">
            <span className="font-bold">{key}:</span>
            <span>{String(value)}</span>
          </div>
        ))}
      </div>
    );
  } catch (e) {
    return <div className="text-red-500">{error}</div>;
  }
}
