import { Danish, Norwegian, Swedish } from "national-id";
import { z } from "zod";

const baseSchema = z.object({
  email: z.string().email()
});

const norwaySchema = baseSchema.extend({
  country: z.literal("norway"),
  organizationNumber: z.string().superRefine((data, ctx) => {
    const isValid = Norwegian.organization.isValid(data);
    if (!isValid) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid organization number"
      });
    }
    return isValid;
  })
});

const swedenSchema = baseSchema.extend({
  country: z.literal("sweden"),
  organizationNumber: z.string().superRefine((data, ctx) => {
    const isValid = Swedish.organization.isValid(data);
    if (!isValid) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid organization number"
      });
    }
    return isValid;
  })
});

const denmarkSchema = baseSchema.extend({
  country: z.literal("denmark"),
  organizationNumber: z.string().superRefine((data, ctx) => {
    const isValid = Danish.organization.isValid(data);
    if (!isValid) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid organization number"
      });
    }
    return isValid;
  })
});

const finlandSchema = baseSchema.extend({
  country: z.literal("finland"),
  organizationNumber: z.string().min(1)
});

export const creditScoreSchema = z.discriminatedUnion("country", [
  norwaySchema,
  swedenSchema,
  denmarkSchema,
  finlandSchema
]);
