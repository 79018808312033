import { COUNTRY, COUNTRY_CODE } from "./enums";

export const countryToCountryCodeMap = {
  [COUNTRY.NORWAY]: COUNTRY_CODE.NORWAY,
  [COUNTRY.SWEDEN]: COUNTRY_CODE.SWEDEN,
  [COUNTRY.FINLAND]: COUNTRY_CODE.FINLAND,
  [COUNTRY.DENMARK]: COUNTRY_CODE.DENMARK
};

export const countryCodeToCountryMap = {
  [COUNTRY_CODE.NORWAY]: COUNTRY.NORWAY,
  [COUNTRY_CODE.SWEDEN]: COUNTRY.SWEDEN,
  [COUNTRY_CODE.FINLAND]: COUNTRY.FINLAND,
  [COUNTRY_CODE.DENMARK]: COUNTRY.DENMARK
};
