export function ArrowIcon() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 12"
      height={22}
      width={18}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.146.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L10.293 6.5H.5a.5.5 0 0 1 0-1h9.793L6.146 1.354a.5.5 0 0 1 0-.708Z"
        fill="#FBFDFE"
      />
    </svg>
  );
}
