import { ZapOff } from "lucide-react";
import React from "react";
import { z } from "zod";
import { useFlowContext } from "../hooks/useFlowContext";
import { cn } from "../utils/cn";

export const COLOR = {
  BLUE: "blue",
  GREEN: "green",
  YELLOW: "yellow",
  RED: "red"
} as const;

export const colorSchema = z.nativeEnum(COLOR);

export const colors = Object.values(COLOR);
export type Color = z.infer<typeof colorSchema>;

export function returnColors(color: Color) {
  switch (color) {
    case "blue":
      return "bg-primary-100 text-primary-800";
    case "green":
      return "bg-grass-90 text-grass-20";
    case "yellow":
      return "bg-caution-100 text-caution-800";
    case "red":
      return "bg-red-100 text-red-800";
    default:
      return "";
  }
}

interface HeaderProps {
  rounded?: boolean;
  color?: Color;
}

function Header({
  children,
  className,
  rounded = true,
  color,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement> & HeaderProps) {
  return (
    <div
      {...props}
      className={cn(
        "flex h-12 items-center space-x-3 px-5 text-base font-semibold",
        rounded ? "rounded-t-md" : "",
        className ? className : "bg-caution-100",
        color && returnColors(color)
      )}
    >
      {children}
    </div>
  );
}

function HeaderTitle({
  children,
  ...props
}: React.HtmlHTMLAttributes<HTMLHeadingElement>) {
  return (
    <h2 {...props} className="flex items-center gap-3">
      {children}
    </h2>
  );
}

function Content({
  children,
  className,
  horizontalPadding = true,
  verticalPadding = true,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement> & {
  horizontalPadding?: boolean;
  verticalPadding?: boolean;
}) {
  return (
    <div
      {...props}
      className={cn(
        verticalPadding ? "py-4 pb-10" : "",
        horizontalPadding ? "px-5" : "",
        className
      )}
    >
      {children ? children : <EmptyState />}
    </div>
  );
}

export function Card({
  children,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className="m-1 h-fit-content rounded-md bg-white shadow">
      {children}
    </div>
  );
}

function EmptyState() {
  const { t } = useFlowContext();
  return (
    <div className="flex flex-col gap-5 pt-4 text-center font-bold text-black">
      <div className="flex justify-center">
        <ZapOff />
      </div>
      <span>{t("noData")}</span>
    </div>
  );
}

Card.Header = Header;
Card.HeaderTitle = HeaderTitle;
Card.Content = Content;
