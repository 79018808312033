import { useQuery } from "@tanstack/react-query";
import { downloadAttachment } from "../../utils/attachments";
import { LoadingSpinner } from "../LoadingSpinner";

type FilePreviewProps = {
  attachmentKey: string;
  mimeType: string;
  flowId: string;
  version?: number;
};
export function FilePreview({
  attachmentKey,
  mimeType,
  flowId,
  version
}: FilePreviewProps) {
  const { data: file, isFetching } = useQuery({
    queryKey: ["file", attachmentKey, version, flowId],
    queryFn: () => downloadAttachment(flowId, attachmentKey, mimeType)
  });

  if (isFetching)
    return (
      <td colSpan={5}>
        <LoadingSpinner width={24} height={24} className="w-full my-5" />
      </td>
    );
  const isImage = mimeType.includes("image");
  const isPdf = mimeType.includes("pdf");

  return (
    <td colSpan={5}>
      {isImage && <img src={file} alt="file" className="w-full" />}
      {isPdf && (
        <iframe className={"h-screen w-full"} title="file" src={file} />
      )}
    </td>
  );
}
