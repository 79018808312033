import { SquareStack } from "lucide-react";
import { useState } from "react";
import { useFlowContext } from "../../../../hooks/useFlowContext";
import { Events as EventsType } from "../../../../types/normalizedData";
import { TaskProps } from "../../../../types/taskProps";
import { getEventsHelper } from "../../../../utils/eventsData";
import { COLOR, Card } from "../../../Card";
import { NoRemarks } from "../../../NoRemarks";
import {
  Table,
  TableBody,
  TableCell,
  TableCellComment,
  TableCellTitle,
  TableHead,
  TableHeader,
  TableRow
} from "../../../table";

export function Events(flow: TaskProps["flow"]) {
  const { t } = useFlowContext();
  const { data } = flow;
  const { country } = data?.applicant;

  const [visibleCount, setVisibleCount] = useState(10);

  if (!country) {
    return (
      <NoRemarks
        label={t("could-not-fetch-data-without-country-code")}
        isSuccess={false}
      />
    );
  }
  const events = getEventsHelper(data, country);
  const hasEvents = events && events?.length > 0;

  if (!hasEvents) {
    return <NoRemarks label={t("no-events")} isSuccess={false} />;
  }

  const eventsSorted = sortedEvents(events);

  const toggleVisibleCount = () => {
    setVisibleCount(visibleCount === 10 ? eventsSorted.length : 10);
  };

  return (
    <div>
      {eventsSorted && (
        <Card>
          <Card.Header color={COLOR.BLUE}>
            <Card.HeaderTitle>
              <SquareStack />
              {t("events")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content horizontalPadding={false} className="px-5">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>{t("date")}</TableHead>
                  <TableHead className="px-2">{t("type")}</TableHead>
                  <TableHead className="pl-2">{t("description")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {eventsSorted.slice(0, visibleCount).map((event, count) => (
                  <TableRow
                    key={`${event?.formattedDate}-${count}`}
                    className="border-0"
                  >
                    <TableCellTitle>
                      {event?.formattedDate ?? t("N/A")}
                    </TableCellTitle>
                    <TableCell className="px-2">
                      {event?.type ?? t("N/A")}
                    </TableCell>
                    <TableCellComment>
                      {event?.description ?? t("N/A")}
                    </TableCellComment>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {eventsSorted.length > 10 && (
              <div className="mt-4">
                <button
                  className="cursor-pointer text-sm text-blue-500 underline"
                  onClick={toggleVisibleCount}
                >
                  {visibleCount > 10 ? "Show Less" : "Show More"}
                </button>
              </div>
            )}
          </Card.Content>
        </Card>
      )}
    </div>
  );
}

function sortedEvents(events: EventsType[]) {
  return events.sort((a, b) => {
    const aDate = a.sortingDate || { year: 0, month: 0, day: 0 };
    const bDate = b.sortingDate || { year: 0, month: 0, day: 0 };

    if (aDate.year !== bDate.year) {
      return bDate.year - aDate.year;
    }
    if (aDate.month !== bDate.month) {
      return bDate.month - aDate.month;
    }
    if (aDate.day !== bDate.day) {
      return bDate.day - aDate.day;
    }

    return 0;
  });
}
