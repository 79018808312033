import { Tab } from "@headlessui/react";
import { DK, FI, NO, SE } from "country-flag-icons/react/3x2";
import { Fragment } from "react";
import { useFlowContext } from "../../hooks/useFlowContext";
import { COUNTRY, Country } from "../../types/creditProcess/enums";
import { TaskProps } from "../../types/taskProps";
import { classNames } from "../../utils/classNames";
import { cn } from "../../utils/cn";
import { getManualHandlingTabsHelper } from "../../utils/manualHandlingTabsInfo";
import { CircleIcon } from "../CircleIcon";
import { Header } from "../Headers";
import { Events } from "./components/creditProcessCaseSummary/Events";
import { SigningRightsFile } from "./components/creditProcessCaseSummary/Files";
import { MissingDataSummary } from "./components/creditProcessCaseSummary/MissingDataSummary";
import { PaymentRemarks } from "./components/creditProcessCaseSummary/PaymentRemarks";
import { Compliance } from "./components/creditProcessCaseSummary/compliance";
import { Financial } from "./components/creditProcessCaseSummary/financial";
import { Management } from "./components/creditProcessCaseSummary/management";
import { Overview } from "./components/creditProcessCaseSummary/overview";

export function CreditProcessCaseSummary({
  flow,
  className
}: {
  flow: TaskProps["flow"];
  className?: string;
}) {
  const { t } = useFlowContext();
  const { data } = flow ?? {};
  const { country } = data?.applicant ?? {};
  const tabsCounters = getManualHandlingTabsHelper(data, country);
  const { dunAndBradstreet } = data?.raw ?? {};

  if (!dunAndBradstreet) {
    return <MissingDataSummary data={flow?.data} />;
  }

  const tabs = [
    { key: "overview", value: t("overview") },
    {
      key: "financial-statements",
      value: t("financial-statements"),
      icon: <CircleIcon number={tabsCounters?.financialStatementsLength} />
    },
    {
      key: "payment-remarks",
      value: t("payment-remarks"),
      icon: <CircleIcon number={tabsCounters?.paymentRemarksLength} />
    },
    {
      key: "compliance",
      value: t("compliance"),
      icon: <CircleIcon number={tabsCounters?.sanctionsCount} />
    },
    {
      key: "events",
      value: t("events"),
      icon: <CircleIcon number={tabsCounters?.eventsLength} />
    },
    {
      key: "file",
      value: t("file"),
      icon: <CircleIcon number={tabsCounters?.hasUploadedSigningRightsFile} />
    },
    { key: "management", value: t("management") }
  ];
  return (
    <div className={cn(className, "overflow-auto flex flex-col gap-4")}>
      <div className="flex flex-col gap-2">
        <div className="flex gap-3">
          {getFlag(data?.applicant?.country)}
          <Header className="text-2xl my-auto">
            {t(data?.application?.type?.toLowerCase())}
          </Header>
        </div>
        <span className="text-gray-600">
          {`Status: ${t(data?.applicationStatus)} /
              ${t(data?.businessStatus)}`}
        </span>
      </div>
      <Tab.Group>
        <Tab.List className="sticky top-0 z-50  border-b bg-white">
          {tabs.map((tab, index) => (
            <Tab as={Fragment} key={index}>
              {({ selected }) => (
                <button
                  className={classNames(
                    "whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-sea-light hover:text-sea-sea",
                    selected ? "border-sea-light text-sea-sea" : ""
                  )}
                >
                  <div className="flex flex-row gap-1">
                    {tab.value}
                    {tab.icon ? tab.icon : null}
                  </div>
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="h-full bg-white-100">
          <Tab.Panel>
            <Overview {...flow} />
          </Tab.Panel>
          <Tab.Panel>
            <Financial {...flow} />
          </Tab.Panel>
          <Tab.Panel>
            <PaymentRemarks {...flow} />
          </Tab.Panel>
          <Tab.Panel>
            <Compliance flow={flow} />
          </Tab.Panel>
          <Tab.Panel>
            <Events {...flow} />
          </Tab.Panel>
          <Tab.Panel>
            <SigningRightsFile {...flow} />
          </Tab.Panel>
          <Tab.Panel>
            <Management {...flow} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export function getFlag(country: Country) {
  switch (country) {
    case COUNTRY.NORWAY:
      return <NO className="w-16 h-16" />;
    case COUNTRY.SWEDEN:
      return <SE className="w-16 h-16" />;
    case COUNTRY.FINLAND:
      return <FI className="w-16 h-16" />;
    case COUNTRY.DENMARK:
      return <DK className="w-16 h-16" />;
  }
}
