import { Text } from "@stacc/flow-ui-components";
import { useFormConnect } from "../../hooks/useFormConnect";

export function OrganizationNumber({
  id,
  label
}: {
  id: string;
  label: string;
}) {
  const { ref, error, ...props } = useFormConnect(id);
  return (
    <div>
      <Text label={label} {...props} />
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}
