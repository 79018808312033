import { DK, FI, NO, SE } from "country-flag-icons/react/3x2";
import { COUNTRY, Country } from "../../types/creditProcess/enums";
import { PROCESSES } from "../../types/processes";
import { TaskProps } from "../../types/taskProps";
import { CreditPaymentCaseSummary } from "./CreditPaymentCaseSummary";
import { CreditProcessCaseSummary } from "./CreditProcessCaseSummary";
import { ModificationsCaseSummary } from "./ModificationsCaseSummary";
import { NotificationsCaseSummary } from "./NotificationsCaseSummary";
import { PreApprovalCaseSummary } from "./PreApprovalCaseSummary";

export function getFlag(country: Country) {
  switch (country) {
    case COUNTRY.NORWAY:
      return <NO className="w-16 h-16" />;
    case COUNTRY.SWEDEN:
      return <SE className="w-16 h-16" />;
    case COUNTRY.FINLAND:
      return <FI className="w-16 h-16" />;
    case COUNTRY.DENMARK:
      return <DK className="w-16 h-16" />;
  }
}

export function CaseSummary({ caseData, flowDefinitionId, t }: TaskProps) {
  const flowData = caseData?.data;
  const flowId = caseData?.flowId;

  switch (flowDefinitionId) {
    case PROCESSES.CREDIT_PAYMENT:
      return (
        <CreditPaymentCaseSummary
          flowData={flowData}
          flowId={flowId}
          fixedT={t}
        />
      );
    case PROCESSES.NOTIFICATIONS:
      return <NotificationsCaseSummary flowData={flowData} fixedT={t} />;
    case PROCESSES.PRE_APPROVAL:
      return <PreApprovalCaseSummary flowData={flowData} fixedT={t} />;
    case PROCESSES.CREDIT_PROCESS:
      return <CreditProcessCaseSummary flow={caseData} />;
    case PROCESSES.CAPTURE:
    case PROCESSES.CANCEL:
    case PROCESSES.REFUND:
      return <ModificationsCaseSummary flowData={flowData} fixedT={t} />;
    case PROCESSES.CREDIT_SCORE_B2B_DENMARK:
    case PROCESSES.CREDIT_SCORE_B2B_FINLAND:
    case PROCESSES.CREDIT_SCORE_B2B_NORWAY:
    case PROCESSES.CREDIT_SCORE_B2B_SWEDEN:
      return <CreditProcessCaseSummary flow={caseData} />;
    default:
      return null;
  }
}
