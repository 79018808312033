import {
  isAdmin,
  isAdminB2b,
  isAdminB2c,
  isCaseworkerDkB2b,
  isCaseworkerElevatedDkB2b,
  isCaseworkerElevatedFiB2b,
  isCaseworkerElevatedNoB2b,
  isCaseworkerElevatedSeB2b,
  isCaseworkerFiB2b,
  isCaseworkerFiB2c,
  isCaseworkerNoB2b,
  isCaseworkerNoSeB2b,
  isCaseworkerReadOnly,
  isCaseWorkerReadOnlyB2b,
  isCaseWorkerReadOnlyB2c,
  isCaseworkerSeB2b,
  isCaseworkerSeB2c,
  isNordicCFOB2b,
  isNordicComplianceB2b,
  isNordicElevatedB2b
} from "../functions/isRole";
import {
  MenuStructure,
  QueueDefinition
} from "../types/case-manager/custom-components";
import { COUNTRY } from "../types/creditProcess/enums";
import {
  FINANCIAL_PROVIDERS,
  FinancialProviders
} from "../types/financialProviders";
import type { TypeRole } from "../types/role";

function queueDefinitionsByBankId(bankId: FinancialProviders) {
  const queues: {
    [key: string]: QueueDefinition;
  } = {};
  if (bankId === FINANCIAL_PROVIDERS.SANTANDERSE) {
    queues[`approved-cases-queue-${bankId}`] = {
      name: `approved-cases-queue-${bankId}`,
      label: "approved-cases-label",
      queueFilter: `?status=active&hasIncidents=false&data.bankId=${bankId}&data.init-${bankId}.status=Approved&data.sign-${bankId}.signing.status=!Completed`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
    queues[`signed-cases-queue-${bankId}`] = {
      name: `signed-cases-queue-${bankId}`,
      label: "signed-cases-label",
      queueFilter: `?status=active&hasIncidents=false&data.bankId=${bankId}&data.sign-${bankId}.signing.status=Completed`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
    queues[`cancelled-cases-queue-${bankId}`] = {
      name: `cancelled-cases-queue-${bankId}`,
      label: "cancelled-cases-label",
      queueFilter: `?status=completed&data.bankId=${bankId}&data.canceled=true`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
  }
  if (bankId === FINANCIAL_PROVIDERS.RESURSFI) {
    queues[`approved-cases-queue-${bankId}`] = {
      name: `approved-cases-queue-${bankId}`,
      label: "approved-cases-label",
      queueFilter: `?status=active&hasIncidents=false&data.bankId=${bankId}&data.init-${bankId}.status=Approved`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
    queues[`cancelled-cases-queue-${bankId}`] = {
      name: `cancelled-cases-queue-${bankId}`,
      label: "cancelled-cases-label",
      queueFilter: `?data.bankId=${bankId}&data.init-${bankId}.status=Cancelled`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
    queues[`rejected-cases-queue-${bankId}`] = {
      name: `rejected-cases-queue-${bankId}`,
      label: "rejected-cases-label",
      queueFilter: `?data.bankId=${bankId}&data.init-${bankId}.payment.updated.status=REJECTED`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
  }
  queues[`active-cases-queue-${bankId}`] = {
    name: `active-cases-queue-${bankId}`,
    label: "active-cases-label",
    queueFilter: `?status=active&hasIncidents=false&data.bankId=${bankId}`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  queues[`completed-cases-queue-${bankId}`] = {
    name: `completed-cases-queue-${bankId}`,
    label: "completed-cases-label",
    queueFilter: `?hasIncidents=false&data.bankId=${bankId}&data.storage.stored.saveState=STORED`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  queues[`failed-cases-queue-${bankId}`] = {
    name: `failed-cases-queue-${bankId}`,
    label: "failed-cases-label",
    queueFilter: `?status=active&hasIncidents=true&data.bankId=${bankId}`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  queues[`captured-cases-queue-${bankId}`] = {
    name: `captured-cases-queue-${bankId}`,
    label: "captured-cases-label",
    queueFilter: `?hasIncidents=false&data.bankId=${bankId}&data.purchase.capture.0=`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };

  return queues;
}

function queueDefinitionsByCountry(country: string) {
  const queues: {
    [key: string]: QueueDefinition;
  } = {};
  queues[`manual-handling-queue-${country}`] = {
    name: `manual-handling-queue-${country}`,
    label: "manual-handling",
    queueFilter: `?status=active&hasIncidents=false&data.applicant.country=${country}&data.businessStatus=MANUAL_HANDLING&data.businessStatus=EVALUATE_SIGNING_RIGHTS_DOCUMENTATION`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  queues[`error-queue-${country}`] = {
    name: `error-queue-${country}`,
    label: "error-queue",
    queueFilter: `?status=active&hasIncidents=true&data.applicant.country=${country}`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  return queues;
}

function queueDefinitionsByFourEyesRole(country: string, level: string) {
  const queues: {
    [key: string]: QueueDefinition;
  } = {};
  queues[`four-eyes-verification-queue-${country}`] = {
    name: `four-eyes-verification-queue-${country}`,
    label: "four-eyes-verification",
    queueFilter: `?status=active&hasIncidents=false&data.applicant.country=${country}&data.businessStatus=FOUR_EYES_VERIFICATION_${level}`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  return queues;
}

function queueDefinitionsFourEyesAllLevels(country: string) {
  const queues: {
    [key: string]: QueueDefinition;
  } = {};
  queues[`four-eyes-verification-queue-${country}`] = {
    name: `four-eyes-verification-queue-${country}`,
    label: "four-eyes-verification",
    queueFilter: `?status=active&hasIncidents=false&data.applicant.country=${country}&data.businessStatus=~FOUR_EYES_VERIFICATION`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  return queues;
}

function queueDefinitionsByComplianceCheck() {
  const queues: {
    [key: string]: QueueDefinition;
  } = {};
  queues[`compliance-check-queue`] = {
    name: `compliance-check-queue`,
    label: "compliance-check",
    queueFilter: `?status=active&hasIncidents=false&data.businessStatus=COMPLIANCE_CHECK`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  return queues;
}

function queueDefinitionsCreditScore() {
  const queues: {
    [key: string]: QueueDefinition;
  } = {};
  queues[`credit-score-queue`] = {
    name: `credit-score-queue`,
    label: "credit-score",
    queueFilter: `?flowDefinitionId=~credit-score`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  queues[`error-queue-credit-score`] = {
    name: `error-queue-credit-score`,
    label: "error-queue",
    queueFilter: `?status=active&hasIncidents=true&flowDefinitionId=~credit-score`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  return queues;
}

export function queueDefinition(role: TypeRole) {
  if (isCaseworkerSeB2c(role)) {
    return { ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.SANTANDERSE) };
  }
  if (isCaseworkerFiB2c(role)) {
    return { ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.RESURSFI) };
  }
  if (isCaseWorkerReadOnlyB2c(role)) {
    return {
      ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.SANTANDERSE),
      ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.RESURSFI)
    };
  }

  if (isCaseworkerNoB2b(role)) {
    return { ...queueDefinitionsByCountry(COUNTRY.NORWAY) };
  }
  if (isCaseworkerElevatedNoB2b(role)) {
    return {
      ...queueDefinitionsByCountry(COUNTRY.NORWAY),
      ...queueDefinitionsByFourEyesRole(COUNTRY.NORWAY, "LOW")
    };
  }
  if (isCaseworkerFiB2b(role)) {
    return { ...queueDefinitionsByCountry(COUNTRY.FINLAND) };
  }
  if (isCaseworkerElevatedFiB2b(role)) {
    return {
      ...queueDefinitionsByCountry(COUNTRY.FINLAND),
      ...queueDefinitionsByFourEyesRole(COUNTRY.FINLAND, "LOW")
    };
  }
  if (isCaseworkerSeB2b(role)) {
    return { ...queueDefinitionsByCountry(COUNTRY.SWEDEN) };
  }
  if (isCaseworkerElevatedSeB2b(role)) {
    return {
      ...queueDefinitionsByCountry(COUNTRY.SWEDEN),
      ...queueDefinitionsByFourEyesRole(COUNTRY.SWEDEN, "LOW")
    };
  }
  if (isCaseworkerDkB2b(role)) {
    return { ...queueDefinitionsByCountry(COUNTRY.DENMARK) };
  }
  if (isCaseworkerElevatedDkB2b(role)) {
    return {
      ...queueDefinitionsByCountry(COUNTRY.DENMARK),
      ...queueDefinitionsByFourEyesRole(COUNTRY.DENMARK, "LOW")
    };
  }
  if (isCaseworkerNoSeB2b(role)) {
    return {
      ...queueDefinitionsByCountry(COUNTRY.NORWAY),
      ...queueDefinitionsByCountry(COUNTRY.SWEDEN)
    };
  }

  if (isNordicElevatedB2b(role)) {
    return {
      ...queueDefinitionsByCountry(COUNTRY.NORWAY),
      ...queueDefinitionsByCountry(COUNTRY.FINLAND),
      ...queueDefinitionsByCountry(COUNTRY.DENMARK),
      ...queueDefinitionsByCountry(COUNTRY.SWEDEN),
      ...queueDefinitionsByFourEyesRole(COUNTRY.NORWAY, "MEDIUM"),
      ...queueDefinitionsByFourEyesRole(COUNTRY.FINLAND, "MEDIUM"),
      ...queueDefinitionsByFourEyesRole(COUNTRY.DENMARK, "MEDIUM"),
      ...queueDefinitionsByFourEyesRole(COUNTRY.SWEDEN, "MEDIUM")
    };
  }
  if (isNordicCFOB2b(role)) {
    return {
      ...queueDefinitionsByFourEyesRole(COUNTRY.NORWAY, "HIGH"),
      ...queueDefinitionsByFourEyesRole(COUNTRY.FINLAND, "HIGH"),
      ...queueDefinitionsByFourEyesRole(COUNTRY.DENMARK, "HIGH"),
      ...queueDefinitionsByFourEyesRole(COUNTRY.SWEDEN, "HIGH")
    };
  }
  if (isNordicComplianceB2b(role)) {
    return { ...queueDefinitionsByComplianceCheck() };
  }
  if (isAdmin(role) || isCaseworkerReadOnly(role)) {
    return {
      ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.SANTANDERSE),
      ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.RESURSFI),
      ...queueDefinitionsByCountry(COUNTRY.NORWAY),
      ...queueDefinitionsByCountry(COUNTRY.FINLAND),
      ...queueDefinitionsByCountry(COUNTRY.DENMARK),
      ...queueDefinitionsByCountry(COUNTRY.SWEDEN),
      ...queueDefinitionsFourEyesAllLevels(COUNTRY.NORWAY),
      ...queueDefinitionsFourEyesAllLevels(COUNTRY.FINLAND),
      ...queueDefinitionsFourEyesAllLevels(COUNTRY.DENMARK),
      ...queueDefinitionsFourEyesAllLevels(COUNTRY.SWEDEN),
      ...queueDefinitionsByComplianceCheck(),
      ...queueDefinitionsCreditScore()
    };
  }

  if (isCaseWorkerReadOnlyB2b(role) || isAdminB2b(role)) {
    return {
      ...queueDefinitionsByCountry(COUNTRY.NORWAY),
      ...queueDefinitionsByCountry(COUNTRY.FINLAND),
      ...queueDefinitionsByCountry(COUNTRY.DENMARK),
      ...queueDefinitionsByCountry(COUNTRY.SWEDEN),
      ...queueDefinitionsFourEyesAllLevels(COUNTRY.NORWAY),
      ...queueDefinitionsFourEyesAllLevels(COUNTRY.FINLAND),
      ...queueDefinitionsFourEyesAllLevels(COUNTRY.DENMARK),
      ...queueDefinitionsFourEyesAllLevels(COUNTRY.SWEDEN)
    };
  }
  if (isAdminB2c(role)) {
    return {
      ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.SANTANDERSE),
      ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.RESURSFI)
    };
  }
  return {};
}

export function menuStructure(role: TypeRole): MenuStructure[] {
  const santanderseMenuItem = {
    name: "Santander SE B2C",
    elems: [
      "active-cases-queue-santanderse",
      "completed-cases-queue-santanderse",
      "cancelled-cases-queue-santanderse",
      "failed-cases-queue-santanderse",
      "approved-cases-queue-santanderse",
      "signed-cases-queue-santanderse",
      "captured-cases-queue-santanderse"
    ],
    isDefaultOpen: true
  };
  const resursfiMenuItem = {
    name: "Resurs FI B2C",
    elems: [
      "active-cases-queue-resursfi",
      "completed-cases-queue-resursfi",
      "cancelled-cases-queue-resursfi",
      "rejected-cases-queue-resursfi",
      "failed-cases-queue-resursfi",
      "approved-cases-queue-resursfi",
      "captured-cases-queue-resursfi"
    ],
    isDefaultOpen: true
  };
  const norwayMenuItem = {
    name: "Norway",
    elems: ["manual-handling-queue-norway", "error-queue-norway"],
    isDefaultOpen: true
  };
  const finlandMenuItem = {
    name: "Finland",
    elems: ["manual-handling-queue-finland", "error-queue-finland"],
    isDefaultOpen: true
  };
  const swedenMenuItem = {
    name: "Sweden",
    elems: ["manual-handling-queue-sweden", "error-queue-sweden"],
    isDefaultOpen: true
  };
  const denmarkMenuItem = {
    name: "Denmark",
    elems: ["manual-handling-queue-denmark", "error-queue-denmark"],
    isDefaultOpen: true
  };
  const creditScoreMenuItem = {
    name: "Credit Score",
    elems: ["credit-score-queue", "error-queue-credit-score"],
    isDefaultOpen: true
  };

  // Elevated caseworker roles
  const norwayMenuItemAndFourEyes = {
    ...norwayMenuItem,
    elems: [...norwayMenuItem.elems, "four-eyes-verification-queue-norway"]
  };
  const denmarkMenuItemAndFourEyes = {
    ...denmarkMenuItem,
    elems: [...denmarkMenuItem.elems, "four-eyes-verification-queue-denmark"]
  };
  const finlandMenuItemAndFourEyes = {
    ...finlandMenuItem,
    elems: [...finlandMenuItem.elems, "four-eyes-verification-queue-finland"]
  };
  const swedenMenuItemAndFourEyes = {
    ...swedenMenuItem,
    elems: [...swedenMenuItem.elems, "four-eyes-verification-queue-sweden"]
  };

  // Nordic elevated and CFO roles
  const onlyFourEyesNorway = {
    ...norwayMenuItem,
    elems: ["four-eyes-verification-queue-norway"]
  };
  const onlyFourEyesFinland = {
    ...finlandMenuItem,
    elems: ["four-eyes-verification-queue-finland"]
  };
  const onlyFourEyesSweden = {
    ...swedenMenuItem,
    elems: ["four-eyes-verification-queue-sweden"]
  };
  const onlyFourEyesDenmark = {
    ...denmarkMenuItem,
    elems: ["four-eyes-verification-queue-denmark"]
  };

  // Compliance check
  const complianceCheckMenuItem = {
    name: "Compliance Check",
    elems: ["compliance-check-queue"],
    isDefaultOpen: true
  };

  if (isCaseworkerSeB2c(role)) {
    return [santanderseMenuItem];
  }
  if (isCaseworkerFiB2c(role)) {
    return [resursfiMenuItem];
  }
  if (isCaseworkerNoB2b(role)) {
    return [norwayMenuItem];
  }
  if (isCaseworkerElevatedNoB2b(role)) {
    return [norwayMenuItemAndFourEyes];
  }
  if (isCaseworkerFiB2b(role)) {
    return [finlandMenuItem];
  }
  if (isCaseworkerElevatedFiB2b(role)) {
    return [finlandMenuItemAndFourEyes];
  }
  if (isCaseworkerDkB2b(role)) {
    return [denmarkMenuItem];
  }
  if (isCaseworkerElevatedDkB2b(role)) {
    return [denmarkMenuItemAndFourEyes];
  }
  if (isCaseworkerSeB2b(role)) {
    return [swedenMenuItem];
  }
  if (isCaseworkerElevatedSeB2b(role)) {
    return [swedenMenuItemAndFourEyes];
  }
  if (isCaseworkerNoSeB2b(role)) {
    return [norwayMenuItem, swedenMenuItem];
  }
  if (isNordicElevatedB2b(role) || isNordicCFOB2b(role)) {
    return [
      onlyFourEyesNorway,
      onlyFourEyesFinland,
      onlyFourEyesSweden,
      onlyFourEyesDenmark
    ];
  }

  if (isNordicComplianceB2b(role)) {
    return [complianceCheckMenuItem];
  }
  if (isAdmin(role) || isCaseworkerReadOnly(role)) {
    // Shows all queues
    return [
      santanderseMenuItem,
      resursfiMenuItem,
      norwayMenuItemAndFourEyes,
      swedenMenuItemAndFourEyes,
      finlandMenuItemAndFourEyes,
      denmarkMenuItemAndFourEyes,
      complianceCheckMenuItem,
      creditScoreMenuItem
    ];
  }
  if (isAdminB2b(role) || isCaseWorkerReadOnlyB2b(role)) {
    return [
      swedenMenuItemAndFourEyes,
      norwayMenuItemAndFourEyes,
      denmarkMenuItemAndFourEyes,
      finlandMenuItemAndFourEyes
    ];
  }
  if (isAdminB2c(role) || isCaseWorkerReadOnlyB2c(role)) {
    return [santanderseMenuItem, resursfiMenuItem];
  }
  return [];
}
