import { SecondaryButton } from "@stacc/flow-ui-components";
import { useFlowContext } from "../../../../../../hooks/useFlowContext";
import { countryCodeToCountryMap } from "../../../../../../types/creditProcess/countryMapper";
import { CountryCode } from "../../../../../../types/creditProcess/enums";
import {
  EntityMatch,
  EntityResponse
} from "../../../../../../types/creditProcess/opensanctions/response";
import { standarizeString } from "../../../../../../utils/helpers";
import { Header } from "../../../../../Headers";
import { NoRemarks } from "../../../../../NoRemarks";
import {
  Table,
  TableBody,
  TableCell,
  TableCellTitle,
  TableRow
} from "../../../../../table";
import { SanctionsDisplayTopics } from "./SanctionsDisplayTopics";

export function isEntityResponse(
  item: string | EntityResponse
): item is EntityResponse {
  return (item as EntityResponse).properties !== undefined;
}

export function isStringArray(value: any): value is string[] {
  return (
    Array.isArray(value) && value.every((item) => typeof item === "string")
  );
}

export function SanctionsCompany({ data }: { data: EntityMatch | undefined }) {
  const { t } = useFlowContext();
  if (!data) {
    return <NoRemarks label={t("no-company-sanctions")} />;
  }

  const type = data?.type ?? "";
  const { match, entity } = data ?? {};
  const topics = match?.properties?.topics ?? [];
  const ownershipOwner = entity?.properties?.ownershipOwner ?? [];
  const { id } = match ?? {};
  const name = match?.caption ?? "";

  const applicantType = type.split("-")[0] ?? "";

  const relationshipsData =
    ownershipOwner?.length > 0 &&
    ownershipOwner?.map((item, index) => {
      const isEntityResponseResult = isEntityResponse(item);

      const relationshipTopics = isEntityResponseResult
        ? ((item?.properties?.asset?.[0] as EntityResponse)?.properties
            ?.topics ?? [])
        : [];

      const relationshipsData: { [key: string]: string | undefined } = {
        name: isEntityResponseResult
          ? ((item.properties?.asset?.[0] as EntityResponse)?.caption as string)
          : undefined,

        percentageHeld: isEntityResponseResult
          ? (item.properties?.percentage?.[0] as string)
          : undefined,

        startDate: isEntityResponseResult
          ? (item.properties?.startDate?.[0] as string)
          : undefined,

        endDate: isEntityResponseResult
          ? (item.properties?.endDate?.[0] as string)
          : undefined,

        firstSeen: isEntityResponseResult
          ? ((item.first_seen as string).split("T")?.[0] as string)
          : undefined,

        lastChanged: isEntityResponseResult
          ? ((item.last_change as string).split("T")?.[0] as string)
          : undefined
      };

      return (
        <div className="py-1 flex flex-col gap-2" key={`asset-${index}`}>
          <Header>{`Asset ${index + 1}`}</Header>
          {relationshipTopics && isStringArray(relationshipTopics) && (
            <SanctionsDisplayTopics topics={relationshipTopics} />
          )}
          <Table>
            <TableBody>
              {Object.keys(relationshipsData).map((key) => {
                return (
                  <TableRow key={key} className="flex justify-between border-0">
                    <TableCellTitle>{t(key)}</TableCellTitle>
                    <TableCell>{relationshipsData[key] ?? "N/A"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      );
    });

  return (
    <div className="flex flex-col gap-5">
      <div className="border-b text-xl">
        <Header>{t(applicantType)}</Header>
      </div>
      {topics && <SanctionsDisplayTopics topics={topics} />}
      <div className="flex flex-col gap-5">
        <Table>
          <TableBody>
            <TableRow className="flex justify-between border-0">
              <TableCellTitle>{t("name")}</TableCellTitle>
              <TableCell>{name ?? "N/A"}</TableCell>
            </TableRow>
            <TableRow className="flex justify-between border-0">
              <TableCellTitle>{t("type")}</TableCellTitle>
              <TableCell>{entity?.schema ?? "N/A"}</TableCell>
            </TableRow>
            <TableRow className="flex justify-between border-0">
              <TableCellTitle>{t("registrationNumber")}</TableCellTitle>
              <TableCell>
                {(entity?.properties?.registrationNumber?.[0] as string) ??
                  "N/A"}
              </TableCell>
            </TableRow>
            <TableRow className="flex justify-between border-0">
              <TableCellTitle>{t("jurisdiction")}</TableCellTitle>
              <TableCell>
                {standarizeString(
                  countryCodeToCountryMap[
                    match?.properties?.jurisdiction?.[0] as CountryCode
                  ]
                ) ?? "N/A"}
              </TableCell>
            </TableRow>
            <TableRow className="flex justify-between border-0">
              <TableCellTitle>{t("firstSeen")}</TableCellTitle>
              <TableCell>
                {(match?.first_seen as string).split("T")?.[0] ?? "N/A"}
              </TableCell>
            </TableRow>
            <TableRow className="flex justify-between border-0">
              <TableCellTitle>{t("lastChanged")}</TableCellTitle>
              <TableCell>
                {(match?.last_change as string).split("T")?.[0] ?? "N/A"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {relationshipsData && (
          <div>
            <div className="text-base">
              <Header>{t("relationships")}</Header>
            </div>
            {relationshipsData}
          </div>
        )}
      </div>
      {id && (
        <div className="w-32">
          <a
            href={`https://www.opensanctions.org/entities/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="More info"
          >
            <SecondaryButton className="w-full">
              {t("more-info")}
            </SecondaryButton>
          </a>
        </div>
      )}
    </div>
  );
}
