import { Country, COUNTRY, COUNTRY_CODE } from "../types/creditProcess/enums";
import { CreditProcessState } from "../types/creditProcess/state";
import { B2BResponseModel } from "../types/d&b/b2b";
import { dateValuesToFormattedDate } from "./helpers";

export function getEventsHelper(
  data: CreditProcessState,
  country: Country | undefined
) {
  switch (country) {
    case COUNTRY.SWEDEN:
      return getEventsDataSweden(data);
    case COUNTRY.NORWAY:
      return getEventsDataNorway(data);
    case COUNTRY.FINLAND:
      return getEventsDataFinland(data);
    case COUNTRY.DENMARK:
      return getEventsDataDenmark(data);
    default:
      return undefined;
  }
}

function getEventsDataSweden(data: CreditProcessState) {
  const { events } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.SWEDEN
    >) ?? {};

  return (
    events?.map((itm) => {
      const { year, month, day } = itm.date ?? {};

      const formattedDate = dateValuesToFormattedDate(day, month, year);
      return {
        sortingDate: {
          year: year ?? 0,
          month: month ?? 0,
          day: day ?? 0
        },
        formattedDate: formattedDate,
        type: itm.type,
        description: itm.description
      };
    }) ?? []
  );
}

function getEventsDataNorway(data: CreditProcessState) {
  const { events } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.NORWAY
    >) ?? {};

  return (
    events?.map((itm) => {
      const { year, month, day } = itm.date ?? {};

      const formattedDate = dateValuesToFormattedDate(day, month, year);
      return {
        sortingDate: {
          year: year ?? 0,
          month: month ?? 0,
          day: day ?? 0
        },
        formattedDate: formattedDate,
        type: itm.type,
        description: itm.details
      };
    }) ?? []
  );
}

function getEventsDataFinland(data: CreditProcessState) {
  const { events } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.FINLAND
    >) ?? {};

  return (
    events?.map((itm) => {
      const { year, month, day } = itm.date ?? {};

      const formattedDate = dateValuesToFormattedDate(day, month, year);
      return {
        sortingDate: {
          year: year ?? 0,
          month: month ?? 0,
          day: day ?? 0
        },
        formattedDate: formattedDate,
        type: itm.code,
        description: itm.description
      };
    }) ?? []
  );
}

function getEventsDataDenmark(data: CreditProcessState) {
  const { events } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.DENMARK
    >) ?? {};

  return (
    events?.map((itm) => {
      const { year, month, day } = itm.date ?? {};

      const formattedDate = dateValuesToFormattedDate(day, month, year);
      return {
        sortingDate: {
          year: year ?? 0,
          month: month ?? 0,
          day: day ?? 0
        },
        formattedDate: formattedDate,
        type: itm.code,
        description: itm.description
      };
    }) ?? []
  );
}
