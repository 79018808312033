import { Translation } from "../../../types/taskProps";

type StorageLinkProps = {
  pspPaymentId: string;
  fixedT: Translation;
};

export function StorageLink({ pspPaymentId, fixedT }: StorageLinkProps) {
  return (
    <div className="px-1 mb-3 font-semibold text-lg rounded-md">
      <a
        className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary-900 text-white hover:bg-primary-800 h-10 px-4 py-2"
        href={`${window.location.origin}/storage?pspPaymentId=${pspPaymentId}`}
      >
        {fixedT("storage")}
      </a>
    </div>
  );
}
