import { COUNTRY, Country, COUNTRY_CODE } from "../types/creditProcess/enums";
import { CreditProcessState } from "../types/creditProcess/state";
import { B2BResponseModel } from "../types/d&b/b2b";
import { dateValuesToFormattedDate, formatCurrency } from "./helpers";

export function getPaymentRemarksHelper(
  data: CreditProcessState,
  country: Country | undefined
) {
  switch (country) {
    case COUNTRY.SWEDEN:
      return getHistoricalPaymentRemarksSweden(data);
    case COUNTRY.NORWAY:
      return getHistoricalPaymentRemarksNorway(data);
    case COUNTRY.FINLAND:
      return getHistoricalPaymentRemarksFinland(data);
    case COUNTRY.DENMARK:
      return getHistoricalPaymentRemarksDenmark(data);
    default:
      return undefined;
  }
}

function getHistoricalPaymentRemarksSweden(data: CreditProcessState) {
  const { risk } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.SWEDEN
    >) ?? {};
  const { paymentRemarks } = risk?.paymentRemarks ?? {};
  return (
    paymentRemarks?.map((itm) => {
      const { amount: amountData, date, description } = itm ?? {};
      const { currency, amount } = amountData ?? {};
      const { year, month, day } = date ?? {};

      const formattedDate = dateValuesToFormattedDate(day, month, year);
      return {
        formattedDate,
        amount: formatCurrency(amount, currency),
        description
      };
    }) ?? []
  );
}

/**
 * Retrieves historical payment remarks for Norway. Would originally return an
 * array of PaymentRemarks within an array of PaymentRemarkGroups sorted
 * chronologically in descending order, but .flat() is used to flatten the array.
 * @param data - The B2B response model containing payment remarks data.
 * @returns An array of payment remarks objects.
 */
function getHistoricalPaymentRemarksNorway(data: CreditProcessState) {
  const { risk } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.NORWAY
    >) ?? {};
  const { paymentRemarksGroups } = risk?.paymentRemarks ?? {};

  return (
    paymentRemarksGroups
      ?.map((itm) => {
        const { paymentRemarks } = itm ?? {};
        return paymentRemarks?.map((itm) => {
          const { amount: amountData, date, type } = itm ?? {};
          const { currency, amount } = amountData?.monetaryAmount ?? {};
          const { year, month, day } = date ?? {};

          const formattedDate = dateValuesToFormattedDate(day, month, year);
          return {
            formattedDate,
            amount: formatCurrency(amount, currency),
            description: type
          };
        });
      })
      .flat() ?? []
  );
}

function getHistoricalPaymentRemarksDenmark(data: CreditProcessState) {
  const { risk } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.DENMARK
    >) ?? {};
  const { paymentRemarks } = risk?.paymentRemarks ?? {};
  return (
    paymentRemarks?.map((itm) => {
      const { amount: amountData, registrationDate, description } = itm ?? {};
      const { currency, amount } = amountData ?? {};
      const { year, month, day } = registrationDate ?? {};

      const formattedDate = dateValuesToFormattedDate(day, month, year);
      return {
        formattedDate,
        amount: formatCurrency(amount, currency),
        description
      };
    }) ?? []
  );
}

function getHistoricalPaymentRemarksFinland(data: CreditProcessState) {
  const { risk } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.FINLAND
    >) ?? {};
  const { paymentRemarks } = risk?.paymentRemarks ?? {};
  return (
    paymentRemarks?.map((itm) => {
      const { amount: amountData, date, description } = itm ?? {};
      const { currency, amount } = amountData ?? {};
      const { year, month, day } = date ?? {};

      const formattedDate = dateValuesToFormattedDate(day, month, year);
      return {
        formattedDate,
        amount: formatCurrency(amount, currency),
        description
      };
    }) ?? []
  );
}
