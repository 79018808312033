import { APPS, CaseManager } from "@flow/case-manager";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MainLogo } from "./components/MainLogo";
import { customComponents } from "./configuration/customComponents";
import { getCustomRoutes } from "./configuration/customRoutes";
import { searchConfiguration } from "./configuration/search";
import customTranslations from "./translations/index.json";
import { CaseManagerConfiguration } from "./types/case-manager";
import { PROCESSES, Processes } from "./types/processes";
import { Role } from "./types/role";
import { TaskProps } from "./types/taskProps";
import type { User } from "./types/user";
import { getLaunchDarklyConfig } from "./utils/getLaunchDarklyConfig";

const skin = {
  mainLogo: () => <MainLogo />
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  }
});

export const caseManagerConfiguration = (
  user: User
): CaseManagerConfiguration<TaskProps["flow"], TaskProps, Array<Processes>> => {
  const role = user?.profile?.role || Role.User;
  return {
    apps: [APPS.Inbox, APPS.Search, APPS.Process],
    inboxConfiguration: {
      startableProcesses: [PROCESSES.CLEANUP],
      queuesPollingEnabled: false
    },
    skin,
    customComponents: customComponents(role),
    language: "en",
    translates: [
      {
        language: "no",
        translates: customTranslations.no
      },
      {
        language: "en",
        translates: customTranslations.en
      }
    ],
    customRoutes: getCustomRoutes(role),
    searchConfiguration
  };
};

export const App = getLaunchDarklyConfig()(() => {
  return (
    <QueryClientProvider client={queryClient}>
      <CaseManager configuration={caseManagerConfiguration} />
    </QueryClientProvider>
  );
});
