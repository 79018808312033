import { ValueOf } from "type-fest";

export const Role = {
  Admin: "admin",
  // B2C
  AdminB2c: "admin-b2c",
  CaseworkerSeB2c: "caseworker-se-b2c",
  CaseworkerNoB2c: "caseworker-no-b2c",
  CaseworkerFiB2c: "caseworker-fi-b2c",
  CaseworkerDkB2c: "caseworker-dk-b2c",
  CaseworkerReadOnlyB2C: "caseworker-readOnly-b2c",
  User: "user",
  // B2B
  AdminB2b: "admin-b2b",
  CaseworkerSeB2b: "caseworker-se-b2b",
  CaseworkerNoB2b: "caseworker-no-b2b",
  CaseworkerFiB2b: "caseworker-fi-b2b",
  CaseworkerDkB2b: "caseworker-dk-b2b",
  CaseworkerNoSeB2b: "caseworker-no-se-b2b",
  CaseworkerReadOnlyB2b: "caseworker-readOnly-b2b",
  NordicCFOB2b: "nordic-cfo-b2b",
  NordicComplianceB2b: "nordic-compliance-b2b",
  NordicElevatedB2b: "nordic-elevated-b2b",
  CaseworkerElevatedNoB2b: "caseworker-elevated-no-b2b",
  CaseworkerElevatedSeB2b: "caseworker-elevated-se-b2b",
  CaseworkerElevatedFiB2b: "caseworker-elevated-fi-b2b",
  CaseworkerElevatedDkB2b: "caseworker-elevated-dk-b2b",
  CaseworkerReadOnly: "caseworker-readOnly"
} as const;

export type TypeRole = ValueOf<typeof Role>;
