import { useFlowContext } from "../../../../../hooks/useFlowContext";
import { BeneficialOwnerListResponse } from "../../../../../types/d&b/b2b/ubo/directPlusUbo";
import { Card } from "../../../../Card";
import { NoRemarks } from "../../../../NoRemarks";

export function UBO({
  foreignCompanyOwners
}: {
  foreignCompanyOwners?: BeneficialOwnerListResponse[];
}) {
  const { t } = useFlowContext();

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-10 max-w-4xl">
        {!foreignCompanyOwners || foreignCompanyOwners.length === 0 ? (
          <NoRemarks label={t("no-foreign-company-owners")} />
        ) : (
          foreignCompanyOwners.map((owner, index) => {
            return (
              <Card key={index}>
                <Card.Header>{owner.organization.primaryName}</Card.Header>
                <Card.Content className="flex flex-col gap-2">
                  <table className="w-full text-basic-ink">
                    <thead>
                      <tr className="w-full">
                        <th className="text-xs text-left pl-5 pb-2">
                          {t("name")}
                        </th>
                        <th className="text-xs text-right pb-2">
                          {t("impliedBeneficialOwnershipPercentage")}
                        </th>
                        <th className="text-xs text-right pb-2">
                          {t("isUndisclosed")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {owner.organization.beneficialOwnership.beneficialOwners.map(
                        (beneficialOwner) => {
                          const hit =
                            beneficialOwner.impliedBeneficialOwnershipPercentage >=
                              25 || beneficialOwner.isUndisclosed;
                          return (
                            <tr
                              key={beneficialOwner.memberID}
                              className={hit ? "bg-caution-100" : ""}
                            >
                              <td className="pl-5 py-3">
                                {beneficialOwner.name}
                                {hit ? "*" : ""}
                              </td>
                              <td className="text-right">
                                {beneficialOwner.impliedBeneficialOwnershipPercentage &&
                                  beneficialOwner.impliedBeneficialOwnershipPercentage +
                                    " %"}
                              </td>
                              <td className="text-right pr-2">
                                {beneficialOwner.isUndisclosed ? (
                                  <span className="text-negative-600">X</span>
                                ) : null}
                              </td>
                            </tr>
                          );
                        }
                      )}
                      <span className="text-xs text-gray-400 mt-2">
                        * {t("undisclosed-beneficial-ownership-explanation")}
                      </span>
                    </tbody>
                  </table>
                </Card.Content>
              </Card>
            );
          })
        )}
      </div>
    </div>
  );
}
