import type { TypeSession } from "../../../types/session";
import { Translation } from "../../../types/taskProps";
import { TextMasker } from "../../TextMasker";
import { CaseSummaryItem } from "./CaseSummaryItem";

type SessionProps = {
  session: TypeSession;
  fixedT: Translation;
};

export function Session({ session, fixedT }: SessionProps) {
  const salesPerson = session?.salesPerson;
  const fullName =
    salesPerson?.firstName && salesPerson?.lastName
      ? `${salesPerson.firstName} ${salesPerson.lastName}`
      : "";
  return (
    <div>
      <div className="px-1 mb-3 grid grid-cols-2 font-semibold text-lg">
        <h2>{fixedT("salesperson")}</h2>
        <h2 className="justify-end text-right">{fixedT("session")}</h2>
      </div>

      <CaseSummaryItem
        items={[
          {
            label: fixedT("full-name"),
            value: <TextMasker text={fullName} />
          },
          {
            label: fixedT("source-system"),
            value: session?.sourceSystem
          },
          {
            label: fixedT("seller-id"),
            value: salesPerson?.sellerId
          },
          {
            label: fixedT("store-id"),
            value: session?.storeId
          }
        ]}
      />
    </div>
  );
}
