import { CurrencyInfo } from "../types/currencyInfo";

export const getCurrencyInfo: CurrencyInfo = {
  NOK: { currency: "NOK", symbol: "no-NO" },
  SEK: { currency: "SEK", symbol: "sv-SE" },
  EUR: { currency: "EUR", symbol: "fi-FI" },
  DKK: { currency: "DKK", symbol: "da-DK" }
};

export function calculateYearDifference(
  establishmentYear: number | undefined | null
): number | string | undefined {
  if (
    establishmentYear === undefined ||
    establishmentYear === null ||
    establishmentYear === 0
  ) {
    return undefined;
  }
  const currentYear = new Date().getFullYear();
  const yearDifference = currentYear - establishmentYear;
  return yearDifference;
}

export function standarizeString(inputString: string): string {
  if (!inputString || inputString.length === 0) {
    return inputString;
  }

  const firstLetter = inputString.charAt(0).toUpperCase();
  const restOfTheString = inputString.slice(1).toLowerCase();

  return firstLetter + restOfTheString;
}

export function dateToString(
  day?: number,
  month?: number,
  year?: number
): string {
  if (!day || !month || !year) {
    return "";
  }

  const date = new Date(year, month - 1, day); // Adjust month to be zero-based
  return date
    .toLocaleDateString("se-SE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    })
    .replace(/\//g, ".");
}

/**
 * Converts individual day, month, and year values to a formatted date string.
 * If any of the values are missing, the corresponding part of the date will be omitted.
 * @param day - The day of the month (optional).
 * @param month - The month of the year (optional).
 * @param year - The year (optional).
 * @returns The formatted date string.
 */
export function dateValuesToFormattedDate(
  day?: number,
  month?: number,
  year?: number
) {
  if (!year) {
    return "";
  }

  if (!month) {
    return `${year}`;
  }

  if (!day) {
    return `${month} ${year}`;
  }

  const date = new Date(year, month - 1, day); // Adjust month to be zero-based
  return new Intl.DateTimeFormat("se-SV", {
    year: "numeric",
    month: "long",
    day: "numeric"
  }).format(date);
}

export function formatCurrency(
  amount: string | number | undefined,

  currency: string | undefined
): string | undefined {
  if ((!amount && amount !== 0) || !currency) {
    return undefined;
  }
  const { symbol } = getCurrencyInfo[currency] ?? {};
  if (!symbol) {
    return undefined;
  }

  let value;
  if (typeof amount === "string") {
    if (amount === "") {
      return undefined;
    }
    value = parseFloat(amount);
  } else {
    value = amount;
  }

  if (Number.isNaN(value)) {
    return undefined;
  }

  return new Intl.NumberFormat(symbol, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    currencyDisplay: "code"
  }).format(value);
}
