import type { TypeApplication } from "../../../types/application";
import { Translation } from "../../../types/taskProps";
import { CaseSummaryItem } from "./CaseSummaryItem";

type ApplicationProps = {
  application: TypeApplication;
  fixedT: Translation;
};

export function Application({ application, fixedT }: ApplicationProps) {
  const desiredCredit = application?.desiredCredit;
  const orderAmount = application?.order?.amount;
  return (
    <div>
      <h2 className="font-semibold pl-1 text-lg mb-3">
        {fixedT("application")}
      </h2>
      <div className="grid w-full auto-rows-auto gap-1 bg-white px-2 py-1 rounded-md">
        <CaseSummaryItem
          items={[
            {
              label: fixedT("desired-credit"),
              value: desiredCredit
                ? `${desiredCredit.value} ${desiredCredit.currency}`
                : ""
            },
            {
              label: fixedT("order-amount"),
              value: orderAmount
                ? `${orderAmount.value} ${orderAmount.currency}`
                : ""
            },
            {
              label: fixedT("basket-id"),
              value: application?.order?.basketId
            }
          ]}
        />
      </div>
    </div>
  );
}
