import { COUNTRY, Country } from "../types/creditProcess/enums";
import { DanishFinancialStatement } from "../types/d&b/b2b/finance/dk";
import { FinnishFinancialStatement } from "../types/d&b/b2b/finance/fi";
import { NorwegianFinancialStatement } from "../types/d&b/b2b/finance/no";
import { SwedishFinancialStatement } from "../types/d&b/b2b/finance/se";
import { FinancialStatementsHelper } from "../types/normalizedData";
import { formatCurrency } from "./helpers";

export function getFinancialStatementsDataHelper(
  data: FinancialStatementsHelper,
  country: Country | undefined
) {
  switch (country) {
    case COUNTRY.SWEDEN:
      return getHistoricalFinancialDataSweden(
        data as SwedishFinancialStatement[]
      );
    case COUNTRY.NORWAY:
      return getHistoricalFinancialDataNorway(
        data as NorwegianFinancialStatement[]
      );
    case COUNTRY.DENMARK:
      return getHistoricalFinancialDataDenmark(
        data as DanishFinancialStatement[]
      );
    case COUNTRY.FINLAND:
      return getHistoricalFinancialDataFinland(
        data as FinnishFinancialStatement[]
      );
    default:
      return undefined;
  }
}

function getHistoricalFinancialDataSweden(
  financialStatements: SwedishFinancialStatement[]
) {
  const financialStatementsHistory = financialStatements
    ?.map((itm) => {
      const currency = itm?.currency;
      const { incomeStatement } = itm ?? {};
      const { currentAssets, nonCurrentAssets, assetsTotal } =
        itm?.balanceSheet?.assets ?? {};
      const { shareholdersEquity, liabilities, equityAndLiabilitiesTotal } =
        itm?.balanceSheet?.liabilitiesAndEquity ?? {};
      const { currentLiabilities, nonCurrentLiabilities } = liabilities ?? {};

      return {
        year: itm?.closingPeriod?.year ?? "",
        data: {
          income: [
            {
              key: "Net income",
              value: formatCurrency(incomeStatement?.netProfitLoss, currency)
            },
            {
              key: "Turnover",
              value: formatCurrency(incomeStatement?.turnover, currency)
            },
            {
              key: "Result before tax",
              value: formatCurrency(
                incomeStatement?.profitLossBeforeTax,
                currency
              )
            },
            {
              key: "Depreciation",
              value: formatCurrency(incomeStatement?.depreciation, currency)
            },
            {
              key: "Total financial costs",
              value: formatCurrency(
                incomeStatement?.financialCostsTotal,
                currency
              )
            },
            {
              key: "Total financial income",
              value: formatCurrency(
                incomeStatement?.financialIncomeTotal,
                currency
              )
            }
          ],
          currentAssets: [
            {
              key: "Cash in hand",
              value: formatCurrency(currentAssets?.cashBankTotal, currency)
            },
            {
              key: "Inventories",
              value: formatCurrency(currentAssets?.inventoriesTotal, currency)
            },
            {
              key: "Total current receivables",
              value: formatCurrency(
                currentAssets?.currentReceivablesTotal,
                currency
              )
            },
            {
              key: "Current investments",
              value: formatCurrency(
                currentAssets?.currentInvestmentsTotal,
                currency
              )
            },
            {
              key: "Total current assets",
              value: formatCurrency(currentAssets?.currentAssetsTotal, currency)
            }
          ],
          nonCurrentAssets: [
            {
              key: "Intangible assets",
              value: formatCurrency(
                nonCurrentAssets?.intangibleAssetsTotal,
                currency
              )
            },
            {
              key: "Financial assets",
              value: formatCurrency(
                nonCurrentAssets?.financialNonCurrentAssetsTotal,
                currency
              )
            },
            {
              key: "Other fixed assets",
              value: formatCurrency(
                nonCurrentAssets?.otherNonCurrentAssets,
                currency
              )
            },
            {
              key: "Total fixed assets",
              value: formatCurrency(
                nonCurrentAssets?.whereofNotDepreciableNonCurrentCosts,
                currency
              )
            }
          ],
          totalAssets: [
            {
              key: "Total assets",
              value: formatCurrency(assetsTotal, currency)
            }
          ],
          shareholderEquity: [
            {
              key: "Shareholder equity",
              value: formatCurrency(shareholdersEquity?.equityTotal, currency)
            },
            {
              key: "Retained earnings",
              value: formatCurrency(
                shareholdersEquity?.retainedEarnings,
                currency
              )
            }
          ],
          currentLiabilities: [
            {
              key: "Current liabilities",
              value: formatCurrency(
                currentLiabilities?.currentLiabilitiesTotal,
                currency
              )
            }
          ],
          longTermLiabilities: [
            {
              key: "Long-term liabilities",
              value: formatCurrency(
                nonCurrentLiabilities?.longTermLiabilitiesTotal,
                currency
              )
            }
          ],
          totalLiabilities: [
            {
              key: "Total liabilities",
              value:
                currentLiabilities?.currentLiabilitiesTotal == null ||
                nonCurrentLiabilities?.longTermLiabilitiesTotal == null
                  ? ""
                  : formatCurrency(
                      currentLiabilities?.currentLiabilitiesTotal +
                        nonCurrentLiabilities?.longTermLiabilitiesTotal,
                      currency
                    )
            }
          ],
          totalEquityAndLiabilities: [
            {
              key: "Total equity and liabilities",
              value: formatCurrency(equityAndLiabilitiesTotal, currency)
            }
          ]
        }
      };
    })
    ?.sort((a, b) => b.year - a.year);

  return financialStatementsHistory;
}

function getHistoricalFinancialDataNorway(
  financialStatements: NorwegianFinancialStatement[]
) {
  const financialStatementsHistory = financialStatements
    ?.map((itm) => {
      const currency = itm?.currency;
      const { incomeStatement } = itm ?? {};
      const {
        currentAssets: currentAssetsRaw,
        nonCurrentAssets: nonCurrentAssetsRaw,
        assetsTotal
      } = itm?.balanceSheet?.assets ?? {};
      const { shareholdersEquity, liabilities, equityAndLiabilitiesTotal } =
        itm?.balanceSheet?.liabilitiesAndEquity ?? {};
      const {
        currentLiabilities: currentLiabilitiesRaw,
        nonCurrentLiabilities
      } = liabilities ?? {};

      return {
        year: itm?.closingPeriod?.year ?? "",
        data: {
          income: [
            {
              key: "Net income",
              value: formatCurrency(incomeStatement?.resultForTheYear, currency)
            },
            {
              key: "Turnover",
              value: formatCurrency(incomeStatement?.grossTurnover, currency)
            },
            {
              key: "Result before tax",
              value: formatCurrency(
                incomeStatement?.profitLossBeforeTax,
                currency
              )
            },
            {
              key: "Depreciation",
              value: formatCurrency(incomeStatement?.depreciation, currency)
            }
          ],
          currentAssets: [
            {
              key: "Cash in hand",
              value: formatCurrency(
                currentAssetsRaw?.cashAndBankBalance,
                currency
              )
            },
            {
              key: "Inventories",
              value: formatCurrency(currentAssetsRaw?.inventories, currency)
            },
            {
              key: "Total current receivables",
              value: formatCurrency(
                currentAssetsRaw?.currentReceivablesTotal,
                currency
              )
            },
            {
              key: "Current investments",
              value: formatCurrency(
                currentAssetsRaw?.investmentsTotal,
                currency
              )
            },
            {
              key: "Total current assets",
              value: formatCurrency(
                currentAssetsRaw?.currentAssetsTotal,
                currency
              )
            }
          ],
          nonCurrentAssets: [
            {
              key: "Intangible assets",
              value: formatCurrency(
                nonCurrentAssetsRaw?.intangibleAssetsTotal,
                currency
              )
            },
            {
              key: "Financial assets",
              value: formatCurrency(
                nonCurrentAssetsRaw?.financialAssetsTotal,
                currency
              )
            },
            {
              key: "Other fixed assets",
              value: formatCurrency(
                nonCurrentAssetsRaw?.otherFixedAssets,
                currency
              )
            },
            {
              key: "Total fixed assets",
              value: formatCurrency(
                nonCurrentAssetsRaw?.fixedAssetsTotal,
                currency
              )
            }
          ],
          totalAssets: [
            {
              key: "Total assets",
              value: formatCurrency(assetsTotal, currency)
            }
          ],
          shareholderEquity: [
            {
              key: "Shareholder equity",
              value: formatCurrency(shareholdersEquity?.equity, currency)
            },
            {
              key: "Retained earnings",
              value: formatCurrency(
                shareholdersEquity?.retainedEarnings,
                currency
              )
            }
          ],
          currentLiabilities: [
            {
              key: "Current liabilities",
              value: formatCurrency(
                currentLiabilitiesRaw?.shortTermLiabilitiesTotal,
                currency
              )
            }
          ],
          longTermLiabilities: [
            {
              key: "Long-term liabilities",
              value: formatCurrency(
                nonCurrentLiabilities?.longTermLiabilitiesTotal,
                currency
              )
            }
          ],
          totalLiabilities: [
            {
              key: "Total liabilities",
              value: formatCurrency(liabilities?.liabilitiesTotal, currency)
            }
          ],
          totalEquityAndLiabilities: [
            {
              key: "Total equity and liabilities",
              value: formatCurrency(equityAndLiabilitiesTotal, currency)
            }
          ]
        }
      };
    })
    ?.sort((a, b) => b.year - a.year);

  return financialStatementsHistory;
}

function getHistoricalFinancialDataDenmark(
  financialStatements: DanishFinancialStatement[]
) {
  const financialStatementsHistory = financialStatements
    ?.map((itm) => {
      const currency = itm?.currency;
      const { incomeStatement } = itm ?? {};
      const { currentAssets, nonCurrentAssets, assetsTotal } =
        itm?.balanceSheet?.assets ?? {};
      const { shareholdersEquity, liabilities, equityAndLiabilitiesTotal } =
        itm?.balanceSheet?.liabilitiesAndEquity ?? {};
      const { currentLiabilities, nonCurrentLiabilities } = liabilities ?? {};

      return {
        year: itm?.closingPeriod?.year ?? "",
        data: {
          income: [
            {
              key: "Net income",
              value: formatCurrency(incomeStatement?.annualResult, currency)
            },
            {
              key: "Turnover",
              value: formatCurrency(incomeStatement?.grossTurnover, currency)
            },
            {
              key: "Result before tax",
              value: formatCurrency(
                incomeStatement?.profitLossBeforeTax,
                currency
              )
            },
            {
              key: "Depreciation",
              value: formatCurrency(incomeStatement?.depreciation, currency)
            },
            {
              key: "Total financial costs",
              value: formatCurrency(
                incomeStatement?.financialCostsTotal,
                currency
              )
            },
            {
              key: "Total financial income",
              value: formatCurrency(
                incomeStatement?.financialIncomeTotal,
                currency
              )
            }
          ],
          currentAssets: [
            {
              key: "Cash in hand",
              value: formatCurrency(
                currentAssets?.cashAndCashEquivalents,
                currency
              )
            },
            {
              key: "Inventories",
              value: formatCurrency(currentAssets?.inventories, currency)
            },
            {
              key: "Total current receivables",
              value:
                currentAssets?.accountsReceivable == null ||
                currentAssets?.receivableFromGroupAndAssociatedCompanies ==
                  null ||
                currentAssets?.otherReceivables == null
                  ? ""
                  : formatCurrency(
                      currentAssets?.accountsReceivable +
                        currentAssets?.receivableFromGroupAndAssociatedCompanies +
                        currentAssets?.otherReceivables,
                      currency
                    )
            },
            {
              key: "Securities",
              value: formatCurrency(currentAssets?.securities, currency)
            },
            {
              key: "Total current assets",
              value: formatCurrency(currentAssets?.currentAssetsTotal, currency)
            }
          ],
          nonCurrentAssets: [
            {
              key: "Intangible assets",
              value: formatCurrency(
                nonCurrentAssets?.intangibleAssetsTotal,
                currency
              )
            },
            {
              key: "Financial assets",
              value: formatCurrency(
                nonCurrentAssets?.financialNonCurrentAssetsTotal,
                currency
              )
            },
            {
              key: "Other fixed assets",
              value: formatCurrency(
                nonCurrentAssets?.otherFinancialAssets,
                currency
              )
            },
            {
              key: "Total fixed assets",
              value: formatCurrency(
                nonCurrentAssets?.nonCurrentAssetsTotal,
                currency
              )
            }
          ],
          totalAssets: [
            {
              key: "Total assets",
              value: formatCurrency(assetsTotal, currency)
            }
          ],
          shareholderEquity: [
            {
              key: "Shareholder equity",
              value: formatCurrency(shareholdersEquity?.equityTotal, currency)
            },
            {
              key: "Transferred earnings",
              value: formatCurrency(
                shareholdersEquity?.transferredEarnings,
                currency
              )
            }
          ],
          currentLiabilities: [
            {
              key: "Current liabilities",
              value: formatCurrency(
                currentLiabilities?.currentLiabilitiesTotal,
                currency
              )
            }
          ],
          longTermLiabilities: [
            {
              key: "Long-term liabilities",
              value: formatCurrency(
                nonCurrentLiabilities?.longTermLiabilitiesTotal,
                currency
              )
            }
          ],
          totalLiabilities: [
            {
              key: "Total liabilities",
              value:
                liabilities?.nonCurrentLiabilities?.longTermLiabilitiesTotal ==
                  null ||
                liabilities?.currentLiabilities?.currentLiabilitiesTotal == null
                  ? ""
                  : formatCurrency(
                      liabilities?.nonCurrentLiabilities
                        ?.longTermLiabilitiesTotal +
                        liabilities?.currentLiabilities
                          ?.currentLiabilitiesTotal,
                      currency
                    )
            }
          ],
          totalEquityAndLiabilities: [
            {
              key: "Total equity and liabilities",
              value: formatCurrency(equityAndLiabilitiesTotal, currency)
            }
          ]
        }
      };
    })
    ?.sort((a, b) => b.year - a.year);

  return financialStatementsHistory;
}

function getHistoricalFinancialDataFinland(
  financialStatements: FinnishFinancialStatement[]
) {
  const financialStatementsHistory = financialStatements
    ?.map((itm) => {
      const currency = itm?.currency;
      const { incomeStatement } = itm ?? {};
      const { currentAssets, nonCurrentAssets, assetsTotal } =
        itm?.balanceSheet?.assets ?? {};
      const { shareholdersEquity, liabilities, equityAndLiabilitiesTotal } =
        itm?.balanceSheet?.liabilitiesAndEquity ?? {};
      const { currentLiabilities, nonCurrentLiabilities } = liabilities ?? {};

      return {
        year: itm?.closingPeriod?.year ?? "",
        data: {
          income: [
            {
              key: "Net income",
              value: formatCurrency(incomeStatement?.netProfitLoss, currency)
            },
            {
              key: "Turnover",
              value: formatCurrency(incomeStatement?.turnoverTotal, currency)
            },
            {
              key: "Result before tax",
              value: formatCurrency(incomeStatement?.operatingResult, currency)
            },
            {
              key: "Depreciation",
              value: formatCurrency(incomeStatement?.depreciation, currency)
            }
          ],
          currentAssets: [
            {
              key: "Cash in hand",
              value: formatCurrency(
                currentAssets?.cashAndCashEquivalents,
                currency
              )
            },
            {
              key: "Inventories",
              value: formatCurrency(currentAssets?.inventoriesTotal, currency)
            },
            {
              key: "Total current receivables",
              value:
                currentAssets?.accountsReceivable == null ||
                currentAssets?.receivableFromGroupAndAssociatedCompanies ==
                  null ||
                currentAssets?.otherReceivables == null
                  ? ""
                  : formatCurrency(
                      currentAssets?.accountsReceivable +
                        currentAssets?.receivableFromGroupAndAssociatedCompanies +
                        currentAssets?.otherReceivables,
                      currency
                    )
            },
            {
              key: "Securities",
              value: formatCurrency(currentAssets?.securities, currency)
            },
            {
              key: "Total current assets",
              value: formatCurrency(currentAssets?.currentAssetsTotal, currency)
            }
          ],
          nonCurrentAssets: [
            {
              key: "Intangible assets",
              value: formatCurrency(
                nonCurrentAssets?.intangibleAssetsTotal,
                currency
              )
            },
            {
              key: "Financial assets",
              value: formatCurrency(
                nonCurrentAssets?.financialNonCurrentAssetsTotal,
                currency
              )
            },
            {
              key: "Other fixed assets",
              value: formatCurrency(
                nonCurrentAssets?.otherFinancialAssets,
                currency
              )
            },
            {
              key: "Total fixed assets",
              value: formatCurrency(
                nonCurrentAssets?.nonCurrentAssetsTotal,
                currency
              )
            }
          ],
          totalAssets: [
            {
              key: "Total assets",
              value: formatCurrency(assetsTotal, currency)
            }
          ],
          shareholderEquity: [
            {
              key: "Shareholder equity",
              value: formatCurrency(shareholdersEquity?.equityTotal, currency)
            },
            {
              key: "Transferred earnings",
              value: formatCurrency(
                shareholdersEquity?.transferredEarnings,
                currency
              )
            }
          ],
          currentLiabilities: [
            {
              key: "Current liabilities",
              value: formatCurrency(
                currentLiabilities?.currentLiabilitiesTotal,
                currency
              )
            }
          ],
          longTermLiabilities: [
            {
              key: "Long-term liabilities",
              value: formatCurrency(
                nonCurrentLiabilities?.longTermLiabilitiesTotal,
                currency
              )
            }
          ],
          totalLiabilities: [
            {
              key: "Total liabilities",
              value:
                liabilities?.nonCurrentLiabilities?.longTermLiabilitiesTotal ==
                  null ||
                liabilities?.currentLiabilities?.currentLiabilitiesTotal == null
                  ? ""
                  : formatCurrency(
                      liabilities?.nonCurrentLiabilities
                        ?.longTermLiabilitiesTotal +
                        liabilities?.currentLiabilities
                          ?.currentLiabilitiesTotal,
                      currency
                    )
            }
          ],
          totalEquityAndLiabilities: [
            {
              key: "Total equity and liabilities",
              value: formatCurrency(equityAndLiabilitiesTotal, currency)
            }
          ]
        }
      };
    })
    ?.sort((a, b) => b.year - a.year);

  return financialStatementsHistory;
}
