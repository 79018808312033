import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { TestRunData } from "../../utils/modifications";
import { Skeleton } from "./Skeleton";

function Label({ x, y, width, value, dataKey }: any) {
  const label = dataKey || value;
  return (
    <text x={x + width / 2} y={y} dy={-4} fontSize={11} textAnchor="middle">
      {value ? label : ""}
    </text>
  );
}

export function DecisionTestingResultChart({
  totals,
  headingTitle
}: {
  totals: TestRunData["totalOutcome"] | undefined;
  headingTitle: string;
}) {
  if (!totals) {
    return (
      <div className="flex flex-col space-y-4">
        <Skeleton className="w-64 h-14 " />
        <Skeleton className="w-full h-64" />
      </div>
    );
  }
  return (
    <ResponsiveContainer>
      <BarChart
        data={[
          {
            name: "Existing",
            Approved: totals.existing.percentApprovedApplications,
            "Manual Handling": totals.existing.percentManualHandling,
            Rejected: totals.existing.percentRejectedAppications
          },
          {
            name: "Live",
            Approved: totals.live.percentApprovedApplications,
            "Manual Handling": totals.live.percentManualHandling,
            Rejected: totals.live.percentRejectedAppications
          },
          {
            name: "DryRun",
            Approved: totals.dryRun.percentApprovedApplications,
            "Manual Handling": totals.dryRun.percentManualHandling,
            Rejected: totals.dryRun.percentRejectedAppications
          }
        ]}
        margin={{
          top: 50,
          right: 40,
          left: 40,
          bottom: 5
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey="name" padding={{ left: 10, right: 10 }} />
        <YAxis
          tickFormatter={(value) => `${value} %`}
          domain={[0, 100]}
          tickCount={6}
        />
        <Tooltip />
        <Legend />
        <Bar dataKey="Approved" fill="#467EFF" unit={" %"} />
        <Bar dataKey="Manual Handling" fill="#FFBB16" unit={" %"} />
        <Bar dataKey="Rejected" fill="#ef4444" unit={" %"} />
        <text x={40} y={20} fontSize="24" textAnchor="start">
          {headingTitle}
        </text>
      </BarChart>
    </ResponsiveContainer>
  );
}

export function DecisionTestingAmountChart({
  totals,
  headingTitle
}: {
  totals: TestRunData["totalOutcome"] | undefined;
  headingTitle: string;
}) {
  if (!totals) {
    return (
      <div className="flex flex-col space-y-4">
        <Skeleton className="w-64 h-14" />
        <Skeleton className="w-full h-64" />
      </div>
    );
  }
  return (
    <ResponsiveContainer>
      <BarChart
        data={[
          {
            name: "Existing",
            "Approved Credit": totals.existing.totalApprovedCredit
          },
          {
            name: "Live",
            "Approved Credit": totals.live.totalApprovedCredit
          },
          {
            name: "DryRun",
            "Approved Credit": totals.dryRun.totalApprovedCredit
          }
        ]}
        margin={{
          top: 50,
          right: 40,
          left: 40,
          bottom: 5
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey="name" padding={{ left: 10, right: 10 }} />
        <YAxis />
        <Tooltip />
        <Bar
          dataKey="Approved Credit"
          fill="#467EFF"
          label={<Label />}
          maxBarSize={200}
        />
        <text x={40} y={20} fontSize="24" textAnchor="start">
          {headingTitle}
        </text>
      </BarChart>
    </ResponsiveContainer>
  );
}
