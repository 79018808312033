import { Translation } from "../../types/taskProps";
import { CaseSummaryItem } from "./components/CaseSummaryItem";

type ModificationsCaseSummaryProps = {
  flowData: {
    merchantTransactionReference: string;
    pspPaymentId: string;
    amount: {
      value: number;
      currency: string;
    };
    creditPayment: {
      flowId: string;
    };
  };
  fixedT: Translation;
};

export function ModificationsCaseSummary({
  flowData,
  fixedT
}: ModificationsCaseSummaryProps) {
  const applicationId = flowData?.creditPayment?.flowId;
  const pspPaymentId = flowData?.pspPaymentId;
  return (
    <div
      className={
        "border-line grid gap-4 rounded-md border-solid border-primary-200 bg-primary-50 p-4 shadow items-center break-words text-gray-700 font-medium text-sm"
      }
    >
      {applicationId ? (
        <div className="pl-2 text-base text-blue-400 hover:underline">
          <a
            href={`${window.location.origin}/cases/case/credit-payment/${applicationId}`}
          >
            {`${fixedT("application")}: ${applicationId}`}
          </a>
        </div>
      ) : null}

      <CaseSummaryItem
        items={[
          {
            label: fixedT("application-flow-id"),
            value: applicationId
          },
          {
            label: fixedT("merchant-transaction-reference"),
            value: flowData?.merchantTransactionReference
          },
          {
            label: fixedT("psp-payment-id"),
            value: pspPaymentId
          },
          {
            label: fixedT("amount"),
            value: `${flowData?.amount.value} ${flowData?.amount.currency}`
          }
        ]}
      />
    </div>
  );
}
