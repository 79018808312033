import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { QueueItemProps } from "../types/case-manager/custom-components";
import { TaskProps } from "../types/taskProps";

export function CreditPaymentQueueCard({
  path,
  flow
}: QueueItemProps<TaskProps["flow"]>) {
  const {
    flowId,
    createdAt,
    referenceId,
    referenceName,
    data,
    flowDefinitionId
  } = flow;
  const financialProvider = data?.application?.financialProvider;
  const createdAtDate = format(parseISO(createdAt), "dd.MM.yyyy");
  const createdAtTime = format(parseISO(createdAt), "HH:mm");

  const isActive =
    window?.location?.pathname ===
    `/inbox/${path}/${flowDefinitionId}/${flowId}`;

  return (
    <Link
      aria-current={"page"}
      to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
      className={`flex flex-col px-3 py-2 text-xs  ${
        isActive
          ? "border-l-4 border-primary-800 bg-primary-100"
          : "border-l-4 border-opacity-0 bg-primary-50 shadow"
      }`}
    >
      <div>
        <span>
          {referenceName} - {referenceId}
        </span>
        <p className="m-0 py-2 text-base font-medium">
          {financialProvider ? financialProvider : referenceName}
        </p>
        <span>{flowDefinitionId}</span>
      </div>
      <div>
        {createdAtDate} {createdAtTime}
      </div>
    </Link>
  );
}
