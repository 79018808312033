import { parsePhoneNumber } from "libphonenumber-js";
import type { TypeApplicant } from "../../../types/applicant";
import { Translation } from "../../../types/taskProps";
import { TextMasker } from "../../TextMasker";
import { CaseSummaryItem } from "./CaseSummaryItem";

type ApplicantProps = {
  applicant: TypeApplicant;
  fixedT: Translation;
};

export function Applicant({ applicant, fixedT }: ApplicantProps) {
  return (
    <div>
      <h2 className="font-semibold pl-1 text-lg mb-3">{fixedT("applicant")}</h2>

      <CaseSummaryItem
        items={[
          {
            label: fixedT("name"),
            value: (
              <TextMasker
                maskCount={7}
                text={`${applicant.firstName} ${applicant.lastName}`}
              />
            )
          },
          {
            label: fixedT("phone-number"),
            value: (
              <TextMasker
                text={
                  parsePhoneNumber(
                    applicant?.mobilePhoneNumber
                      ?.normalizedInternationalPhoneNumber
                  )?.formatInternational() ?? ""
                }
              />
            )
          },
          {
            label: fixedT("identity"),
            value: <TextMasker text={applicant.identity} />
          },
          {
            label: fixedT("email"),
            value: <TextMasker text={applicant.email} />
          }
        ]}
      />
    </div>
  );
}
