import { Role, TypeRole } from "../types/role";

export function isAdmin(role: TypeRole) {
  return role === Role.Admin;
}

export function isAdminAny(role: TypeRole) {
  const adminRoles: TypeRole[] = [Role.Admin, Role.AdminB2c, Role.AdminB2b];
  return adminRoles.includes(role);
}

export function isCaseworkerReadOnly(role: TypeRole) {
  return role === Role.CaseworkerReadOnly;
}

// B2c
export function isAdminB2c(role: TypeRole) {
  return role === Role.AdminB2c;
}

export function isCaseWorkerReadOnlyB2c(role: TypeRole) {
  return role === Role.CaseworkerReadOnlyB2C;
}

export function isCaseWorkerB2c(role: TypeRole) {
  const caseWorkerB2cRoles: TypeRole[] = [
    Role.CaseworkerSeB2c,
    Role.CaseworkerNoB2c,
    Role.CaseworkerFiB2c,
    Role.CaseworkerDkB2c,
    Role.CaseworkerReadOnlyB2C
  ];
  return caseWorkerB2cRoles.includes(role);
}

export function isCaseworkerSeB2c(role: TypeRole) {
  return role === Role.CaseworkerSeB2c;
}

export function isCaseworkerFiB2c(role: TypeRole) {
  return role === Role.CaseworkerFiB2c;
}

export function isCaseworkerDkB2c(role: TypeRole) {
  return role === Role.CaseworkerDkB2c;
}

export function isCaseworkerNoB2c(role: TypeRole) {
  return role === Role.CaseworkerNoB2c;
}

// B2b
export function isAdminB2b(role: TypeRole) {
  return role === Role.AdminB2b;
}

export function isCaseWorkerB2b(role: TypeRole) {
  const caseWorkerB2bRoles: TypeRole[] = [
    Role.CaseworkerSeB2b,
    Role.CaseworkerNoB2b,
    Role.CaseworkerFiB2b,
    Role.CaseworkerDkB2b,
    Role.CaseworkerNoSeB2b,
    Role.CaseworkerReadOnlyB2b
  ];
  return caseWorkerB2bRoles.includes(role);
}
export function isCaseWorkerElevatedB2b(role: TypeRole) {
  const caseWorkerB2bRoles: TypeRole[] = [
    Role.CaseworkerElevatedDkB2b,
    Role.CaseworkerElevatedNoB2b,
    Role.CaseworkerElevatedFiB2b,
    Role.CaseworkerElevatedDkB2b
  ];
  return caseWorkerB2bRoles.includes(role);
}

export function isCaseworkerSeB2b(role: TypeRole) {
  return role === Role.CaseworkerSeB2b;
}
export function isCaseworkerFiB2b(role: TypeRole) {
  return role === Role.CaseworkerFiB2b;
}
export function isCaseworkerDkB2b(role: TypeRole) {
  return role === Role.CaseworkerDkB2b;
}
export function isCaseworkerNoB2b(role: TypeRole) {
  return role === Role.CaseworkerNoB2b;
}

export function isCaseworkerNoSeB2b(role: TypeRole) {
  return role === Role.CaseworkerNoSeB2b;
}

export function isCaseWorkerReadOnlyB2b(role: TypeRole) {
  return role === Role.CaseworkerReadOnlyB2b;
}

export function isNordicCFOB2b(role: TypeRole) {
  return role === Role.NordicCFOB2b;
}

export function isNordicComplianceB2b(role: TypeRole) {
  return role === Role.NordicComplianceB2b;
}

export function isNordicElevatedB2b(role: TypeRole) {
  return role === Role.NordicElevatedB2b;
}

export function isCaseworkerElevatedNoB2b(role: TypeRole) {
  return role === Role.CaseworkerElevatedNoB2b;
}
export function isCaseworkerElevatedSeB2b(role: TypeRole) {
  return role === Role.CaseworkerElevatedSeB2b;
}
export function isCaseworkerElevatedFiB2b(role: TypeRole) {
  return role === Role.CaseworkerElevatedFiB2b;
}
export function isCaseworkerElevatedDkB2b(role: TypeRole) {
  return role === Role.CaseworkerElevatedDkB2b;
}
