import { Icons } from "@stacc/flow-ui-components";
import React, { useState } from "react";
import { TypeModifications } from "../../types/modification";
import { Card } from "../Card";

interface EventsCardProps {
  events: TypeModifications["events"];
}

export function EventsCard({ events }: EventsCardProps) {
  const [openStates, setOpenStates] = useState<{ [key: number]: boolean }>({});

  const toggleCollapse = (index: number) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const sortedEvents = events.sort(
    (a, b) =>
      new Date(b.PspTimestamp).getTime() - new Date(a.PspTimestamp).getTime()
  );

  return (
    <div>
      <Card className="mt-4">
        <Card.Header className="flex space-x-3 items-center text-base font-semibold px-5 h-12 rounded-t-md bg-sea-light">
          <span>Events: {events.length}</span>
        </Card.Header>
        {sortedEvents.map((event, index) => (
          <React.Fragment key={`${event.PspPaymentId}-${index}`}>
            <div
              className="flex justify-between items-center border-t px-5 py-2 cursor-pointer"
              onClick={() => toggleCollapse(index)}
            >
              <span className="flex space-x-3 items-center text-base font-semibold">
                {event.Success ? (
                  <>
                    <Icons.SuccessCircle />
                    <span>{`${event.EventType} : ${new Date(
                      event.PspTimestamp
                    ).toLocaleString()}`}</span>
                  </>
                ) : (
                  <>
                    <Icons.ErrorCircle />
                    <span>{`${event.EventType} : ${new Date(
                      event.PspTimestamp
                    ).toLocaleString()}`}</span>
                  </>
                )}
              </span>
              <span>
                {openStates[index] ? (
                  <Icons.ChevronUp />
                ) : (
                  <Icons.ChevronDown />
                )}
              </span>
            </div>
            {openStates[index] && (
              <Card.Content>
                <div className="flex flex-col px-5 py-2">
                  {Object.entries(event).map(([key, value]) => {
                    const val = value ? value.toString() : "N/A";
                    return (
                      val !== "N/A" &&
                      val.trim() !== "" && (
                        <div key={key} className="flex justify-between my-1">
                          <span className="text-lg font-bold text-primary-900">
                            {key}
                          </span>
                          <span className="text-primary-800">
                            {key === "Success" ? (value ? "Yes" : "No") : val}
                          </span>
                        </div>
                      )
                    );
                  })}
                </div>
              </Card.Content>
            )}
          </React.Fragment>
        ))}
      </Card>
    </div>
  );
}
