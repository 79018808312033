import { useState } from "react";
import { classNames } from "../../utils/classNames";
import { FilePreview } from "./PreviewFile";
import { Chevron } from "../icons/Chevron";
import { useFlowContext } from "../../hooks/useFlowContext";
import { SigningRightsPreview } from "./SigningRightsPreview";

type FileRowProps = {
  data: {
    documentType: string;
    owner: string;
    filename?: string;
    fileName?: string;
    updatedAt: Date;
    createdAt: Date;
    attachmentKey: string;
    mimetype: string;
    version: number;
    hasFailed?: boolean;
    isSigningRights?: boolean;
  };
};

export function FileRow({ data }: FileRowProps) {
  const [open, setOpen] = useState(false);
  const { t, flow } = useFlowContext();
  const { flowId } = flow;

  const filename = data.filename || data.fileName;
  if (!filename) {
    return null;
  }
  const isImage = data.mimetype.includes("image");
  const isPdf = data.mimetype.includes("pdf");
  if (!isImage && !isPdf) {
    return null;
  }
  const isSigningRights = data?.isSigningRights;
  return (
    <>
      <tr
        onClick={() => setOpen(!open)}
        className="cursor-pointer border-misc-ui-separator border-t"
      >
        <td className="pl-5 py-3">{t(data.documentType)}</td>
        <td>{t(data.owner)}</td>
        <td>
          {t(filename)}{" "}
          {data.hasFailed && (
            <span className=" text-negative-600">{`(${t(
              "failedToFetch"
            )})`}</span>
          )}
        </td>
        <td className="text-right pr-2">
          {new Date(data.updatedAt ?? data.createdAt).toLocaleDateString(
            "no-NO",
            {
              hour: "numeric",
              minute: "numeric"
            }
          )}
        </td>
        <td className="w-3 text-sea-30 pr-5">
          <Chevron
            className={classNames(
              open ? "rotate-180" : "rotate-0",
              "text-blue-500 transform duration-150 ease-in-out"
            )}
          />
        </td>
      </tr>
      {open &&
        (isSigningRights ? (
          <tr>
            <SigningRightsPreview
              mimeType={data?.mimetype}
              flowId={flowId}
              blobName={filename}
            />
          </tr>
        ) : (
          <tr>
            <FilePreview
              attachmentKey={data?.attachmentKey}
              mimeType={data?.mimetype}
              flowId={flowId}
              version={data?.version}
            />
          </tr>
        ))}
    </>
  );
}
