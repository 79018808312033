import React, { useState, useEffect } from "react";

interface ProgressBarProps {
  count: number;
  complete?: boolean;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  count,
  complete
}) => {
  const [progress, setProgress] = useState(0);
  const [number, setNumber] = useState(0); // New state for the number
  const totalTime = count * 1.5 * 1000; // Convert duration to milliseconds
  const incrementTime = 100; // Update the progress every 100 milliseconds
  const totalIncrements = totalTime / incrementTime;
  const speedUp = complete ? 10000 : 100;
  const progressIncrement = speedUp / totalIncrements;

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + progressIncrement;
        if (nextProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return nextProgress;
      });
    }, incrementTime);

    return () => clearInterval(interval);
  }, [progressIncrement]);

  useEffect(() => {
    const numberInterval = setInterval(() => {
      setNumber((prevNumber) => prevNumber + 1);
    }, 1000);

    return () => clearInterval(numberInterval);
  }, []);

  if (progress === 100) {
    return null;
  }

  return (
    <div>
      <div className="w-full bg-gray-300 border rounded-lg flex flex-col">
        <div
          className="h-3 bg-sea-sea border rounded-lg"
          style={{
            width: `${progress}%`
          }}
        ></div>
      </div>
      <div className="flex flex-row gap-1">
        <div>{`Estimated loading time:`}</div>
        <div className="font-bold">
          {number.toFixed(0)}/{(totalTime / 1000).toFixed(0)} sec
        </div>
      </div>
    </div>
  );
};
