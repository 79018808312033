import { getStatus, getStatusCreditPayment } from "../functions/getStatus";
import { mapBankId } from "../functions/mapBankId";
import { SearchConfiguration, SearchField, SearchResultFields } from "../types/case-manager/search";
import { APPLICATION_STATUS, APPLICATION_TYPE, BUSINESS_STATUS } from "../types/creditProcess/enums";
import { PROCESSES, Processes } from "../types/processes";

const searchFields: SearchField<Array<Processes>>[] = [
  { name: "Flow ID", value: "flowId", type: "text" },
  {
    name: "Scenario",
    value: "sandboxConfig.scenarioId",
    queryOperator: "contains",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Request ID",
    value: "data.requestId",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Bank Payment ID",
    value: "data.referenceId",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Basket ID",
    value: "data.application.order.basketId",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Elkjøp Reference",
    value: "data.session.merchantTransactionReference",
    queryOperator: "contains",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Resurs Order Reference",
    value: "data.init-resursfi.payment.start.order.orderReference",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Source System",
    value: "data.session.sourceSystem",
    type: "select",
    options: [
      { label: "Commerce", value: "COMMERCE" },
      {
        label: "Blueberry",
        value: "BLUEBERRY"
      }
    ],
    process: [PROCESSES.CREDIT_PAYMENT, PROCESSES.CREDIT_PROCESS]
  },
  {
    name: "Bank",
    value: "data.application.financialProvider",
    type: "select",
    options: [
      {
        label: "Santander SE",
        value: "santanderse"
      },
      {
        label: "Resurs Bank FI",
        value: "resursfi"
      }
    ],
    process: [PROCESSES.CREDIT_PAYMENT]
  },
  {
    name: "Order Amount",
    value: "data.application.order.amount.value",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "National Identity",
    value: "data.applicant.applicants.0.identity",
    queryOperator: "contains",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Applicant Email",
    value: "data.applicant.applicants.0.email",
    process: [PROCESSES.CREDIT_PAYMENT, PROCESSES.PRE_APPROVAL],
    type: "text"
  },
  {
    name: "Application Flow ID",
    value: "data.creditPaymentFlowId",
    process: [PROCESSES.NOTIFICATIONS],
    type: "text"
  },
  {
    name: "Application Flow ID",
    value: "data.creditPayment.flowId",
    process: [PROCESSES.CAPTURE, PROCESSES.CANCEL, PROCESSES.REFUND],
    type: "text"
  },
  {
    name: "Elkjøp Reference",
    value: "data.merchantTransactionReference",
    process: [PROCESSES.CAPTURE, PROCESSES.CANCEL, PROCESSES.REFUND],
    type: "text"
  },
  {
    name: "Country",
    value: "data.applicant.country",
    type: "select",
    options: [
      {
        label: "Denmark",
        value: "denmark"
      },
      {
        label: "Finland",
        value: "finland"
      },
      {
        label: "Norway",
        value: "norway"
      },
      {
        label: "Sweden",
        value: "sweden"
      }
    ],
    process: [PROCESSES.CREDIT_PROCESS]
  },
  {
    name: "Customer Type",
    value: "data.applicant.customerType",
    type: "select",
    options: [
      {
        label: "B2B",
        value: "b2b"
      },
      {
        label: "B2C",
        value: "b2c"
      }
    ],
    process: [PROCESSES.CREDIT_PROCESS]
  },
  {
    name: "Application Type",
    value: "data.application.type",
    type: "select",
    options: [
      {
        label: "Credit",
        value: APPLICATION_TYPE.CREDIT
      },
      {
        label: "Elevate to admin",
        value: APPLICATION_TYPE.ELEVATE_TO_ADMIN
      },
      {
        label: "Appeal",
        value: APPLICATION_TYPE.APPEAL
      },
      {
        label: "Manual",
        value: APPLICATION_TYPE.MANUAL
      }
    ],
    process: [PROCESSES.CREDIT_PROCESS]
  },
  {
    name: "Company Registration Number",
    value: "data.applicant.companyApplicant.companyRegistrationNumber",
    process: [PROCESSES.CREDIT_PROCESS],
    type: "text"
  },
  {
    name: "Created from",
    value: "createdAt",
    min: "2023-09-14",
    max: new Date().toISOString().split("T")[0],
    type: "date",
    queryOperator: "gte"
  },
  {
    name: "Created before",
    value: "createdAt",
    type: "date",
    min: "2023-09-14",
    max: new Date().toISOString().split("T")[0],
    queryOperator: "lt"
  },
  {
    name: "Business Status",
    value: "data.businessStatus",
    type: "select",
    options: [
      {
        label: "Application information",
        value: BUSINESS_STATUS.APPLICATION_INFORMATION
      },
      {
        label: "Approve credit decision",
        value: BUSINESS_STATUS.APPROVE_CREDIT_DECISION
      },
      {
        label: "Approve credit decision declined",
        value: BUSINESS_STATUS.APPROVE_CREDIT_DECISION_DECLINED
      },
      {
        label: "Authentication",
        value: BUSINESS_STATUS.AUTHENTICATION
      },
      {
        label: "Authentication completed",
        value: BUSINESS_STATUS.AUTHENTICATION_COMPLETED
      },
      {
        label: "Authentication failed",
        value: BUSINESS_STATUS.AUTHENTICATION_FAILED
      },
      {
        label: "Choose signers",
        value: BUSINESS_STATUS.CHOOSE_SIGNERS
      },
      {
        label: "Compliance check",
        value: BUSINESS_STATUS.COMPLIANCE_CHECK
      },
      {
        label: "Compliance check rejected",
        value: BUSINESS_STATUS.COMPLIANCE_CHECK_REJECTED
      },
      {
        label: "Completed approved",
        value: BUSINESS_STATUS.COMPLETED_APPROVED
      },
      {
        label: "Completed rejected",
        value: BUSINESS_STATUS.COMPLETED_REJECTED
      },
      {
        label: "Created",
        value: BUSINESS_STATUS.CREATED
      },
      {
        label: "Credit score",
        value: BUSINESS_STATUS.CREDIT_SCORE
      },
      {
        label: "Credit score rejected",
        value: BUSINESS_STATUS.CREDIT_SCORE_REJECTED
      },
      {
        label: "Error",
        value: BUSINESS_STATUS.ERROR
      },
      {
        label: "Four eyes verification high",
        value: BUSINESS_STATUS.FOUR_EYES_VERIFICATION_HIGH
      },
      {
        label: "Four eyes verification low",
        value: BUSINESS_STATUS.FOUR_EYES_VERIFICATION_LOW
      },
      {
        label: "Four eyes verification medium",
        value: BUSINESS_STATUS.FOUR_EYES_VERIFICATION_MEDIUM
      },
      {
        label: "Manual handling",
        value: BUSINESS_STATUS.MANUAL_HANDLING
      },
      {
        label: "Manual handling rejected",
        value: BUSINESS_STATUS.MANUAL_HANDLING_REJECTED
      },
      {
        label: "No Dun & Bradstreet records",
        value: BUSINESS_STATUS.NO_DNB_COMPANY_DATA
      },
      {
        label: "No DNB person data",
        value: BUSINESS_STATUS.NO_DNB_PERSON_DATA
      },
      {
        label: "No Roaring records",
        value: BUSINESS_STATUS.NO_ROARING_RECORDS
      },
      {
        label: "Sanction screening",
        value: BUSINESS_STATUS.SANCTION_SCREENING
      },
      {
        label: "Select invoicing option",
        value: BUSINESS_STATUS.SELECT_INVOICING_OPTION
      },
      {
        label: "Sign documents",
        value: BUSINESS_STATUS.SIGN_DOCUMENTS
      },
      {
        label: "Signing",
        value: BUSINESS_STATUS.SIGNING
      },
      {
        label: "Signing completed",
        value: BUSINESS_STATUS.SIGNING_COMPLETED
      },
      {
        label: "Signing failed",
        value: BUSINESS_STATUS.SIGNING_FAILED
      },
      {
        label: "Signing packaging failed",
        value: BUSINESS_STATUS.SIGNING_PACKAGING_FAILED
      },
      {
        label: "Signing reset",
        value: BUSINESS_STATUS.SIGNING_RESET
      },
      {
        label: "Upload signing rights documentation",
        value: BUSINESS_STATUS.UPLOAD_SIGNING_RIGHTS_DOCUMENTATION
      },
      {
        label: "Evaluate Power of Attorney document",
        value: BUSINESS_STATUS.EVALUATE_SIGNING_RIGHTS_DOCUMENTATION
      },
      {
        label: "Evaluate Power of Attorney document approved",
        value: BUSINESS_STATUS.EVALUATE_SIGNING_RIGHTS_DOCUMENTATION_APPROVED
      },
      {
        label: "Evaluate Power of Attorney document rejected",
        value: BUSINESS_STATUS.EVALUATE_SIGNING_RIGHTS_DOCUMENTATION_REJECTED
      }
    ],
    process: [PROCESSES.CREDIT_PROCESS]
  },
  {
    name: "Application Status",
    value: "data.applicationStatus",
    type: "select",
    options: [
      {
        label: "In progress",
        value: APPLICATION_STATUS.IN_PROGRESS
      },
      {
        label: "Approved",
        value: APPLICATION_STATUS.APPROVED
      },
      {
        label: "Awaiting signature",
        value: APPLICATION_STATUS.AWAITING_SIGNATURE
      },
      {
        label: "Declined",
        value: APPLICATION_STATUS.DECLINED
      },
      {
        label: "Manual review",
        value: APPLICATION_STATUS.MANUAL_REVIEW
      },
      {
        label: "Cancelled",
        value: APPLICATION_STATUS.CANCELLED
      },
      {
        label: "Error",
        value: APPLICATION_STATUS.ERROR
      },
      {
        label: "Completed",
        value: APPLICATION_STATUS.COMPLETED
      },
      {
        label: "Timed out",
        value: APPLICATION_STATUS.TIMED_OUT
      }
    ],
    process: [PROCESSES.CREDIT_PROCESS]
  }
];

const searchResultFields: SearchResultFields<Array<Processes>>[] = [
  { name: "Flow ID", value: "flowId" },
  {
    name: "Scenario",
    value: "sandboxConfig.scenarioId",
    process: [PROCESSES.CREDIT_PAYMENT]
  },
  {
    name: "Bank",
    value: "data.application.financialProvider",
    process: [PROCESSES.CREDIT_PAYMENT],
    mapping: (financialProvider) => mapBankId(financialProvider)
  },
  {
    name: "Status",
    value: "data",
    process: [PROCESSES.CREDIT_PAYMENT],
    mapping: (data) => getStatusCreditPayment(data)
  },
  {
    name: "Status",
    value: "data.businessStatus",
    process: [PROCESSES.CREDIT_PROCESS]
  },
  {
    name: "Country",
    value: "data.applicant.country",
    process: [PROCESSES.CREDIT_PROCESS],
    mapping: (country: string) =>
      country.charAt(0).toUpperCase() + country.slice(1)
  },
  {
    name: "Customer Type",
    value: "data.applicant.customerType",
    process: [PROCESSES.CREDIT_PROCESS],
    mapping: (customerType: string) => customerType.toUpperCase()
  },
  {
    name: "Application Type",
    value: "data.application.type",
    process: [PROCESSES.CREDIT_PROCESS],
    mapping: (type: string) => {
      switch (type) {
        case APPLICATION_TYPE.CREDIT:
          return "Credit";
        case APPLICATION_TYPE.ELEVATE_TO_ADMIN:
          return "Elevate to admin";
        case APPLICATION_TYPE.APPEAL:
          return "Appeal";
        default:
          return type;
      }
    }
  },
  {
    name: "Company Name",
    value: "data.applicant.companyApplicant.companyName",
    process: [PROCESSES.CREDIT_PROCESS]
  },
  {
    name: "Source System",
    value: "data.session.sourceSystem",
    process: [PROCESSES.CREDIT_PAYMENT, PROCESSES.CREDIT_PROCESS]
  },
  {
    name: "Application Flow ID",
    value: "data.creditPaymentFlowId",
    process: [PROCESSES.NOTIFICATIONS]
  },
  {
    name: "Event Type",
    value: "data.notification.EventType",
    process: [PROCESSES.NOTIFICATIONS]
  },
  {
    name: "Bank ID",
    value: "data.flowData.bankId",
    process: [PROCESSES.NOTIFICATIONS],
    mapping: (bankId) => mapBankId(bankId)
  },
  {
    name: "Application Flow ID",
    value: "data.creditPayment.flowId",
    process: [PROCESSES.CAPTURE, PROCESSES.CANCEL, PROCESSES.REFUND]
  },
  {
    name: "Amount",
    value: "data.amount",
    process: [PROCESSES.CAPTURE, PROCESSES.CANCEL, PROCESSES.REFUND],
    mapping: (amount) => `${amount.value} ${amount.currency}`
  },
  {
    name: "Bank ID",
    value: "data.creditPayment.bankId",
    process: [PROCESSES.CAPTURE, PROCESSES.CANCEL, PROCESSES.REFUND],
    mapping: (bankId) => mapBankId(bankId)
  },
  {
    name: "Created at",
    value: "createdAt",
    process: [PROCESSES.CREDIT_PAYMENT],
    mapping: (createdAt) => new Date(createdAt).toLocaleString()
  }
];

export const searchConfiguration: SearchConfiguration<Array<Processes>> = {
  search: {
    fields: searchFields
  },
  searchResults: {
    fields: searchResultFields,
    businessStatus: {
      map: (flow: any) => {
        return getStatus(flow);
      }
    }
  },
  searchProcesses: [
    PROCESSES.CREDIT_PAYMENT,
    PROCESSES.RECONCILIATION,
    PROCESSES.NOTIFICATIONS,
    PROCESSES.CLEANUP,
    PROCESSES.CREDIT_PROCESS,
    PROCESSES.CLEANUP,
    PROCESSES.CANCEL,
    PROCESSES.REFUND,
    PROCESSES.CAPTURE,
    PROCESSES.PRE_APPROVAL
  ],
  searchQueryView: "searchView"
};
