import { TypeModifications } from "../../types/modification";
import { Card } from "../Card";

interface OverviewCardProps {
  overview: TypeModifications;
}

export function OverviewCard({ overview }: OverviewCardProps) {
  const {
    status,
    amount,
    currency,
    flowId,
    pspPaymentId,
    merchantId,
    referenceId,
    sellerId,
    bankId
  } = overview;

  return (
    <Card>
      <Card.Header className="flex space-x-3 items-center text-base font-semibold px-5 h-12 rounded-t-md bg-sea-light">
        <Card.HeaderTitle>Overview</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        <div className="flex flex-col">
          <div className="flex justify-between">
            <span className="text-lg font-bold text-primary-900">Status</span>
            <span className="text-primary-800">{status}</span>
          </div>
          <br />
          <div className="flex justify-between">
            <span className="text-lg font-bold text-primary-900">Amount</span>
            <span className="text-primary-800">
              {amount} {currency}
            </span>
          </div>
        </div>
      </Card.Content>
      <Card.Header rounded={false} className="bg-sea-light">
        <Card.HeaderTitle>IDs</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        <div className="flex flex-col">
          <div className="flex justify-between">
            <span className="text-lg font-bold text-primary-900">
              Application ID
            </span>
            <span className="text-primary-800">{flowId}</span>
          </div>
          <br />
          <div className="flex justify-between">
            <span className="text-lg font-bold text-primary-900">
              PSP Payment ID
            </span>
            <span className="text-primary-800">{pspPaymentId}</span>
          </div>
          <br />
          <div className="flex justify-between">
            <span className="text-lg font-bold text-primary-900">
              Merchant ID
            </span>
            <span className="text-primary-800">{merchantId}</span>
          </div>
          <br />
          <div className="flex justify-between">
            <span className="text-lg font-bold text-primary-900">
              Reference ID
            </span>
            <span className="text-primary-800">{referenceId}</span>
          </div>
          <br />
          <div className="flex justify-between">
            <span className="text-lg font-bold text-primary-900">
              Seller ID
            </span>
            <span className="text-primary-800">{sellerId}</span>
          </div>
          <br />
          <div className="flex justify-between">
            <span className="text-lg font-bold text-primary-900">Bank ID</span>
            <span className="text-primary-800">{bankId}</span>
          </div>
        </div>
      </Card.Content>
    </Card>
  );
}
