import { useFlowContext } from "../hooks/useFlowContext";
import { classNames } from "../utils/classNames";

export function DisplayComments({ comment }: { comment: string | undefined }) {
  const { t } = useFlowContext();
  if (!comment) {
    return null;
  }
  return (
    <div>
      <span
        className={classNames(
          `border-2 rounded-lg px-4 py-1 flex flex-col`,
          "bg-blue-100 border-blue-500"
        )}
      >
        <span className={classNames("text-blue-700")}>
          {t("comment-from-seller")}:
        </span>
        <span className={classNames("text-sm", "text-black")}>{comment}</span>
      </span>
    </div>
  );
}
