import { useFlowContext } from "../../hooks/useFlowContext";
import { TableRowDataProps } from "../../types/normalizedData";
import { Table, TableBody, TableCell, TableRow } from "./index";

export function CustomTable({ tableData }: { tableData: TableRowDataProps[] }) {
  const { t } = useFlowContext();
  if (!tableData) {
    return null;
  }
  return (
    <Table className="w-full max-w-full overflow-x-auto">
      <TableBody>
        {tableData.map((data, count) => {
          const translatedKey =
            typeof data.key === "string" ? t(data.key) : data.key;
          const value = data?.value ? data.value : t("N/A");
          const translatedValue = typeof value === "string" ? t(value) : value;

          return (
            <TableRow
              key={`${data.key}-${count}`}
              className="truncate border-0"
            >
              <TableCell className="py-2 font-medium text-gray-700">
                {translatedKey}
              </TableCell>
              <TableCell className="text-right text-gray-600">
                {translatedValue}
              </TableCell>
              {data?.comment ? (
                <TableCell className="pl-2 text-gray-400">
                  {data.comment}
                </TableCell>
              ) : null}
              {data?.warning ? (
                <TableCell className="pl-2 text-right font-semibold text-red-500">
                  {data.warning}
                </TableCell>
              ) : null}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
